import FluxoAquisicaoInter from "./FluxoAquisicaoInter";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getPreenchimentoOperacao } from "../../../../selectors/operacao.selectors";

const FluxoAquisicaoInterConnected = () => {
  const [step, setStep] = useState(0);
  const [comprador, setComprador] = useState(null);

  const {
    operacao: { participantes, detalhe },
    validacao,
  } = useSelector(getPreenchimentoOperacao);

  const [correntista, setCorrentista] = useState(detalhe?.correntista);

  const compradores = useMemo(() => {
    return participantes
      .filter((p) => p.tipoParticipante === "CO")
      .map((c) => ({
        ...c,
        fluxo: validacao?.[
          correntista ? "correntista" : "completa"
        ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
      }))
      .sort((c1, c2) =>
        c1.principal
          ? -1
          : c2.principal
          ? 1
          : (c1.nome || "").localeCompare(c2.nome || "")
      );
  }, [participantes, validacao, correntista]);

  const makeGoTo = useCallback(
    (s) => {
      return () => {
        setStep(s);
      };
    },
    [setStep]
  );

  const handleGoTo0 = useCallback(() => {
    setStep(0);
  }, [setStep]);
  const handleGoTo1 = useCallback(() => {
    setStep(1);
  }, [setStep]);
  const handleGoTo2 = useCallback(() => {
    setStep(2);
  }, [setStep]);
  const handleGoTo3 = useCallback(() => {
    setStep(3);
  }, [setStep]);
  const handleGoTo4 = useCallback(() => {
    setStep(4);
  }, [setStep]);
  const handleGoTo100 = useCallback(() => {
    setStep(100);
  }, [setStep]);
  const handleGoTo999 = useCallback(() => {
    setStep(999);
  }, [setStep]);
  const events = [makeGoTo(1), makeGoTo(2), makeGoTo(3), makeGoTo(4)];
  const eventsImovel = [makeGoTo(100)];

  return (
    <FluxoAquisicaoInter
      step={step}
      setStep={setStep}
      comprador={comprador}
      setComprador={setComprador}
      correntista={correntista}
      setCorrentista={setCorrentista}
      compradores={compradores}
      events={events}
      eventsImovel={eventsImovel}
      makeGoTo={makeGoTo}
      handleGoTo0={handleGoTo0}
      handleGoTo1={handleGoTo1}
      handleGoTo2={handleGoTo2}
      handleGoTo3={handleGoTo3}
      handleGoTo4={handleGoTo4}
      handleGoTo100={handleGoTo100}
      handleGoTo999={handleGoTo999}
    />
  );
};

export default FluxoAquisicaoInterConnected;
