import CommonPage from "../../CommonPage";
import { Grid, Skeleton, styled } from "@mui/material";
import React from "react";
import OperationHeaderConnected from "../../../components/presentation/OperationHeader.connected";
import {
  Button,
  Card,
  Form,
  GoBack,
  MenuItem,
  Title,
} from "../../../components";
import {
  ControlledSwitch,
  ControlledTextField,
  CurrencyTextField,
  IntegerTextField,
} from "../../../components/inputs";

const SkeletonCard = styled(Skeleton)`
  width: 100%;
  height: 239px;
  border-radius: 1rem;
`;

const formatCarencia = (c) => {
  if (c === 0) {
    return "Sem carência";
  }
  if (c === 1) {
    return "1 mês";
  }
  return `${c} meses`;
};

const ConfirmacaoValoresPage = ({
  loading,
  tiposAmortizacao,
  seguradoras,
  carencias,
  data,
  formProps,
  handleGoBack,
  handleSubmit,
  sending,
}) => {
  const isLoading = !!loading || !data;

  return (
    <CommonPage selected={"clientes"}>
      {isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OperationHeaderConnected
              data={data.operacao}
              timeline={true}
              detalhes={true}
            />
          </Grid>
          <Grid item container justifyContent={"center"} spacing={3}>
            <Grid
              item
              container
              spacing={3}
              direction={"column"}
              maxWidth={704}
            >
              <GoBack
                handleClick={handleGoBack}
                text={"Confirme os valores da proposta e envie para análise"}
              />
              <Grid item container justifyContent={"center"}>
                <Grid item>
                  <Card>
                    <Form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Title as={"h5"}>Confirmação de valores</Title>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ControlledTextField
                            name={"valorFinanciamento"}
                            label={
                              data.operacao.tipoProduto === "H"
                                ? "Valor do Empréstimo"
                                : "Valor do Financiamento"
                            }
                            formProps={formProps}
                            InputProps={{
                              inputComponent: CurrencyTextField,
                            }}
                            inputProps={{
                              allowNegative: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ControlledTextField
                            name={"prazoDesejado"}
                            label={"Prazo desejado"}
                            formProps={formProps}
                            inputProps={{
                              maxLength: 3,
                              allowNegative: false,
                            }}
                            InputProps={{
                              inputComponent: IntegerTextField,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <ControlledTextField
                            name={"seguradora"}
                            label={"Seguradora"}
                            select
                            disabled={seguradoras.length <= 1}
                            formProps={formProps}
                          >
                            {seguradoras.map((s) => (
                              <MenuItem key={s.id} value={s.id}>
                                {s.nome}
                              </MenuItem>
                            ))}
                          </ControlledTextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ControlledTextField
                            name={"tipoAmortizacao"}
                            label={"Tipo de Amortização"}
                            select
                            disabled={tiposAmortizacao.length <= 1}
                            formProps={formProps}
                          >
                            {tiposAmortizacao.map((t) => (
                              <MenuItem key={t} value={t}>
                                {t}
                              </MenuItem>
                            ))}
                          </ControlledTextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ControlledTextField
                            name={"prazoCarencia"}
                            label={"Prazo de Carência"}
                            select
                            disabled={carencias.length <= 1}
                            formProps={formProps}
                          >
                            {carencias.map((c) => (
                              <MenuItem key={c} value={c}>
                                {formatCarencia(c)}
                              </MenuItem>
                            ))}
                          </ControlledTextField>
                        </Grid>
                        {data.operacao.tipoProduto !== "H" && (
                          <Grid item xs={12}>
                            <ControlledSwitch
                              name={"financiaCustas"}
                              label={
                                "Deseja financiar as despesas de ITBI e Registro? (5% do valor do imóvel)"
                              }
                              tooltip={
                                "É possível financiar o pagamento do ITBI e Registro do Contrato, até o valor limite correspondente a 5% do Valor do imóvel.\n" +
                                "Após a assinatura do Contrato na agência, será realizado o crédito e o débito deste valor na conta corrente.\n" +
                                "O financiamento destas despesas estará condicionado às regras do produto e normas aplicáveis."
                              }
                              formProps={formProps}
                            />
                          </Grid>
                        )}
                        {data.operacao.tipoProduto === "H" && (
                          <Grid item xs={12}>
                            <ControlledSwitch
                              name={"financiaIof"}
                              label={"Deseja financiar o IOF da operação?"}
                              tooltip={
                                "É possível incluir no valor do crédito os custos de IOF presentes na operação.\n" +
                                "No momento da liberação do crédito, será realizado o crédito e débito deste valor na conta corrente.\n" +
                                "O financiamento do IOF estará condicionado às regras do produto e normas aplicáveis.\n" +
                                "Se não for financiado será descontado do valor total do empréstimo reduzindo do valor solicitado do empréstimo."
                              }
                              formProps={formProps}
                            />
                          </Grid>
                        )}
                        {data.operacao.tipoProduto === "H" && (
                          <Grid item xs={12}>
                            <ControlledSwitch
                              name={"financiaTarifaEmissao"}
                              label={"Deseja financiar a tarifa de Avaliação?"}
                              tooltip={
                                "É possível financiar a Tarifa de Avaliação da Garantia.\n" +
                                "Importante: A tarifa deverá ser paga nas hipóteses de cancelamento ou desistência desta proposta pelo cliente, desde que o serviço tenha sido prestado.\n" +
                                "Após a assinatura do contrato na agência, será realizado o crédito e débito deste valor em sua conta corrente.\n" +
                                "O financiamento destas despesas estará condicionado às regras do produto e normas aplicáveis."
                              }
                              formProps={formProps}
                            />
                          </Grid>
                        )}
                        {data.operacao.tipoProduto === "H" && (
                          <Grid item xs={12}>
                            <ControlledSwitch
                              name={"financiaTarifaRegistro"}
                              label={
                                data.operacao.tipoProduto === "H"
                                  ? "Deseja financiar a Tarifa de Emissão do Contrato? (5% do valor do empréstimo)"
                                  : "Deseja financiar a Tarifa de Emissão do Contrato? (5% do valor do financiamento)"
                              }
                              tooltip={
                                "É possível financiar o pagamento Emissão do Contrato, até o valor limite pode corresponder até 5% do Valor do imóvel, porém este valor na prática pode ser menos 1% e caso não ser totalmente utilizado para esta finalidade de registro poderá ser abatido no saldo devedor do cliente.\n" +
                                "O financiamento destas despesas estará condicionado às regras do produto e normas aplicáveis de cada instituições."
                              }
                              formProps={formProps}
                            />
                          </Grid>
                        )}
                        <Grid item container justifyContent={"center"} mt={1}>
                          <Grid item xs={12} md={8}>
                            <Button fullWidth loading={sending} type={"submit"}>
                              ENVIAR PARA ANÁLISE
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </CommonPage>
  );
};

export default ConfirmacaoValoresPage;
