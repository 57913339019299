import {
  AccordionDetails,
  AccordionSummary,
  Badge,
  Grid,
  styled,
} from "@mui/material";
import React from "react";
import { IconButton, Tooltip } from "../../../../components";
import {
  Check,
  DeleteForever,
  Edit,
  ExpandMore,
  PriorityHigh,
} from "@mui/icons-material";
import { DetailsAccordion } from "../../../../components/presentation";

const IconContainer = styled("div")`
  width: 48px;
  height: 48px;
  font-size: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.primary._100};
  color: ${({ theme }) => theme.colors.primary._700};

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    width: 64px;
  	height: 64px;
  	font-size: 68px;
  }`}
`;
const NomeComprador = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray._500};
  margin: 0.5rem 0 0 1rem;
`;
const CpfCnpjComprador = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray._200};
  margin: 0 0 0 1rem;
`;
const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.gray._100};
  color: ${({ theme }) => theme.colors.gray._700};

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.gray._100};
  }
  & .MuiSvgIcon-root {
    font-size: 1rem;
  }

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    & .MuiSvgIcon-root {
			font-size: 1.5rem;
		}
  }`}
`;
const SmallPriorityHigh = styled(PriorityHigh)`
  font-size: 1rem;
`;

const ItemOperacao = ({
  fluxo,
  icon,
  title,
  isChildren,
  subtitle,
  canRemove,
  titleRemover,
  handleRemover,
  handleEditar,
  details,
  adding,
  deleting,
  refreshing,
  submitting,
}) => {
  return (
    <DetailsAccordion elevation={0} square disableGutters>
      <AccordionSummary
        expandIcon={
          <Tooltip arrow title={"Resumo das informações"}>
            <StyledIconButton>
              <ExpandMore />
            </StyledIconButton>
          </Tooltip>
        }
      >
        <Grid width={"100%"} py={2} pr={1}>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item ml={isChildren ? 2 : 0}>
              <Badge
                badgeContent={
                  fluxo.filter(
                    (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
                  ).length > 0 ? (
                    <SmallPriorityHigh />
                  ) : (
                    <SmallPriorityHigh as={Check} />
                  )
                }
                size="large"
                color={
                  fluxo.filter(
                    (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
                  ).length > 0
                    ? "error"
                    : "success"
                }
              >
                <IconContainer>{icon}</IconContainer>
              </Badge>
            </Grid>
            <Grid
              item
              container
              direction={"column"}
              alignSelf={"flex-start"}
              xs
            >
              <NomeComprador>{title}</NomeComprador>
              <CpfCnpjComprador>{subtitle}</CpfCnpjComprador>
            </Grid>
            {!!canRemove && (
              <Grid item>
                <Tooltip arrow title={titleRemover}>
                  <StyledIconButton
                    disabled={submitting || deleting || refreshing || adding}
                    loading={deleting || refreshing}
                    loaderColor={"gray"}
                    onClick={handleRemover}
                  >
                    <DeleteForever />
                  </StyledIconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <Tooltip arrow title={"Editar informações"}>
                <StyledIconButton
                  loading={submitting}
                  loaderColor={"gray"}
                  disabled={submitting || deleting || refreshing || adding}
                  onClick={handleEditar}
                >
                  <Edit />
                </StyledIconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </DetailsAccordion>
  );
};

export default ItemOperacao;
