import React from "react";
import { Grid, IconButton, styled } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const GoBackButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.gray._300};
  & .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.fonts.size.large};
  }

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    & .MuiSvgIcon-root {
			font-size: ${theme.fonts.size.xlarge};
		}
  }`}
`;
const TitleText = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.gray._300};
  font-weight: 700;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    font-size: ${theme.fonts.size.large};
  }`}
`;
const GoBack = ({ handleClick, text, ...props }) => (
  <Grid item container alignItems={"center"} {...props}>
    <Grid item mr={1}>
      <GoBackButton onClick={handleClick}>
        <ArrowBack />
      </GoBackButton>
    </Grid>
    <Grid item flex={1}>
      <TitleText>{text}</TitleText>
    </Grid>
  </Grid>
);

export default GoBack;
