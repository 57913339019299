import { Grid, IconButton, styled } from "@mui/material";
import React from "react";
import { Button, Card, Title } from "../../components";
import { TIPO_PRAZO } from "./ParametrosSimulacaoForm";
import formatters from "../../utils/formatters";
import { parseDateUsingFormat } from "../../utils/basic";
import useResponsive from "../../hooks/useResponsive";
import { logos, TIPO_AMORTIZACAO } from "../../constants/generic.constants";
import { ArrowBack } from "@mui/icons-material";

const Logo = styled("img")`
  width: auto;
  height: 64px;
  border-radius: 1rem;
  display: block;
  margin: 0 auto 2rem;
  user-select: none;
`;
const SmallLogo = styled("img")`
  width: 100%;
  height: auto;
  border-radius: 8px;
  display: block;
  margin: 0;
  user-select: none;
`;
const GoBackButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.gray._300};
  & .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.fonts.size.large};
  }

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    & .MuiSvgIcon-root {
			font-size: ${theme.fonts.size.xlarge};
		}
  }`}
`;
const TitleText = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.gray._300};
  font-weight: 700;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    font-size: ${theme.fonts.size.large};
  }`}
`;
const FieldName = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray._200};
  margin-bottom: ${({ _small_ }) => (_small_ ? "4px" : "0")};
  user-select: none;
`;
const FieldValue = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme, _small_ }) =>
    _small_ ? theme.fonts.size.default : theme.fonts.size.large};
  font-weight: 700;
  user-select: none;
`;
const BlurOverlay = styled("div")`
  position: absolute;
  inset: 0;
  background-color: #ffffff30;
  backdrop-filter: blur(6px);
`;
const SameHeightCard = styled(Card)`
  height: calc(100% - 3rem);
`;
const SpacedButton = styled(Button)`
  margin-top: 1rem;
`;

const LOGOS = {
  H: [logos.itau, logos.bari, logos.santander],
  I: [logos.santander, logos.itau, logos.bradesco],
  C: [logos.itau, logos.santander, logos.inter],
};

const Field = ({ name, value, small = false }) => {
  return (
    <Grid display={"flex"} flexDirection={"column"}>
      <FieldName _small_={small}>{name}</FieldName>
      <FieldValue _small_={small}>{value}</FieldValue>
    </Grid>
  );
};

const SmallCard = ({ logo, primeiraParcela }) => {
  return (
    <Grid item xs={12}>
      <Card>
        <Grid container spacing={3} alignItems={"center"}>
          <Grid item width={116}>
            <SmallLogo src={logo} />
          </Grid>
          <Grid item xs>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12}>
                <Field
                  name={"Primeira parcela"}
                  small={true}
                  value={`R$ ${formatters.numbers.currency(primeiraParcela)}`}
                />
              </Grid>
              <BlurOverlay />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

const ValueCard = ({
  nome,
  produto,
  logo,
  primeiraParcela,
  ultimaParcela,
  rendaMinima,
  taxaJurosAnual,
  taxaJurosMensal,
  taxaAdministracao,
  cetAnual,
  handleContinue,
  actions,
}) => {
  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <SameHeightCard>
        {!!nome && (
          <Title as={"h3"} _margin_={"0 0 1rem 0"}>
            {nome}
          </Title>
        )}
        {!!logo && <Logo src={logo} />}
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12}>
            <Field
              name={"Primeira parcela"}
              value={`R$ ${formatters.numbers.currency(primeiraParcela)}`}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name={"Última parcela"}
              small={true}
              value={`R$ ${formatters.numbers.currency(ultimaParcela)}`}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name={"Renda mínima"}
              value={`R$ ${formatters.numbers.currency(rendaMinima)}`}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name={
                produto.tipo === "C"
                  ? "Taxa de Administração (a partir de)"
                  : "Taxa de Juros (a partir de)"
              }
              small={true}
              value={
                produto.tipo === "C"
                  ? `${formatters.numbers.currency(taxaAdministracao)}%`
                  : `${formatters.numbers.currency(taxaJurosAnual)}% a.a.`
              }
            />
            {produto.tipo === "H" && (
              <FieldValue _small_={true}>{`${formatters.numbers.currency(
                taxaJurosMensal
              )}% a.m.`}</FieldValue>
            )}
          </Grid>
          <Grid item xs={12}>
            <Field
              name={"CET (Custo Efetivo Total)"}
              small={true}
              value={`${formatters.numbers.currency(cetAnual)}% a.a.`}
            />
          </Grid>
          {actions && (
            <>
              <Grid item xs={12}>
                <SpacedButton onClick={handleContinue} fullWidth>
                  CONHECER TODAS AS OFERTAS
                </SpacedButton>
              </Grid>
            </>
          )}
          {!actions && <BlurOverlay />}
        </Grid>
      </SameHeightCard>
    </Grid>
  );
};

const ResultadoSimulacaoSection = ({
  data,
  produto,
  condicao,
  handleGoBack,
  handleNext,
}) => {
  const currentSize = useResponsive();
  const largeCards = currentSize === "xl" ? 3 : currentSize === "lg" ? 2 : 0;
  const smallCards = currentSize === "xl" ? 0 : currentSize === "lg" ? 0 : 3;

  const largeImages = Array.apply(null, Array(largeCards)).map(
    (v, i) => LOGOS[produto.tipo][i]
  );
  const smallImages = Array.apply(null, Array(smallCards)).map(
    (v, i) => LOGOS[produto.tipo][i]
  );

  return (
    <Grid container spacing={3} direction={"column"}>
      <Grid item container alignItems={"center"}>
        <Grid item mr={1}>
          <GoBackButton onClick={handleGoBack}>
            <ArrowBack />
          </GoBackButton>
        </Grid>
        <Grid item flex={1}>
          <TitleText>Encontramos as melhores condições</TitleText>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <Card>
            <Title as={"h3"} _margin_={"0 0 1rem 0"}>
              Valores de entrada
            </Title>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={true}>
                <Field
                  name={"Valor do Imóvel"}
                  value={`R$ ${formatters.numbers.currency(
                    data.entrada.valorImovel
                  )}`}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={true}>
                <Field
                  name={
                    produto.tipo === "H"
                      ? "Valor do Empréstimo"
                      : "Valor do Financiamento"
                  }
                  value={`R$ ${formatters.numbers.currency(
                    data.entrada.valorTotal
                  )}`}
                />
              </Grid>
              {produto.tipo === "I" && (
                <Grid item xs={12} md={4} lg={true}>
                  <Field
                    name={"Valor da Entrada"}
                    value={`R$ ${formatters.numbers.currency(
                      data.entrada.valorImovel - data.entrada.valorFinanciamento
                    )}`}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={4} lg={true}>
                <Field
                  name={`Prazo`}
                  value={`${data.entrada.prazoDesejado} ${
                    TIPO_PRAZO[condicao.tipoPrazo]
                  }`}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={true}>
                <Field
                  name={`Tipo de Amortização`}
                  value={`${TIPO_AMORTIZACAO[data.tipoAmortizacao]}`}
                />
              </Grid>
              {condicao.tipoPessoa === "F" && (
                <Grid item xs={12} md={4} lg={true}>
                  <Field
                    name={"Data de Nascimento"}
                    value={formatters.dates.simple(
                      parseDateUsingFormat(
                        data.entrada.dataNascimento,
                        "yyyy-MM-dd"
                      ),
                      "dd/MM/yyyy"
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        {largeImages.map((image) => (
          <ValueCard key={image} logo={image} produto={produto} {...data} />
        ))}
        {smallImages.length > 0 && (
          <Grid item xs={12} md={6}>
            <Grid item container spacing={3} direction={"column"}>
              {smallImages.map((image) => (
                <SmallCard key={image} logo={image} {...data} />
              ))}
            </Grid>
          </Grid>
        )}
        <ValueCard
          nome={produto.nome}
          produto={produto}
          {...data}
          handleContinue={handleNext}
          actions={true}
        />
      </Grid>
    </Grid>
  );
};

export default ResultadoSimulacaoSection;
