import React, { useState } from "react";
import { Grid, styled } from "@mui/material";
import { transientOptions } from "../../../styles/theme";
import { Close, Done, Remove } from "@mui/icons-material";
import useResponsive from "../../../hooks/useResponsive";

const StepperDesktopContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const StepperMobileContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: ${({ _align_ = "flex-start" }) => _align_};
`;
const EtapaDesktopContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: ${({ _align_ }) => _align_};
  width: ${({ _align_ }) =>
    _align_ === "flex-start" || _align_ === "flex-end" ? "80px" : "100px"};
  position: relative;
  cursor: ${({ onClick }) => (!!onClick ? "pointer" : "default")};
`;
const EtapaMobileContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: ${({ _align_ }) => _align_};
  position: relative;
  cursor: ${({ onClick }) => (!!onClick ? "pointer" : "default")};
`;
const Circle = styled("div", transientOptions)`
  width: 32px;
  height: 32px;
  display: flex;
  box-shadow: 0 0 0 1px
      ${({ theme, _active_, _color_, _situacao_ }) =>
        !!_color_
          ? _color_
          : _active_
          ? theme.colors.info._500
          : _situacao_ === "COMPLETA"
          ? theme.colors.success._600
          : _situacao_ === "INDIFERENTE"
          ? theme.colors.warning._600
          : theme.colors.gray._300},
    0 0 0 7px ${({ theme, _bg_ }) => _bg_ ?? theme.colors.fixed.offWhite};
  align-items: center;
  z-index: 1;
  justify-content: center;
  border-radius: 100px;

  background-color: ${({ theme, _bg_, _color_, _active_, _situacao_ }) =>
    !!_color_
      ? _color_
      : _active_
      ? theme.colors.info._500
      : _situacao_ === "COMPLETA"
      ? theme.colors.success._600
      : _situacao_ === "INDIFERENTE"
      ? theme.colors.warning._600
      : theme.colors.gray._300};

  & .MuiSvgIcon-root {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.fixed.white};
  }
`;
const MobileCircle = styled("div", transientOptions)`
  width: 24px;
  height: 24px;
  display: flex;
  box-shadow: 0 0 0 1px
      ${({ theme, _active_, _color_, _situacao_ }) =>
        !!_color_
          ? _color_
          : _active_
          ? theme.colors.info._500
          : _situacao_ === "COMPLETA"
          ? theme.colors.success._600
          : _situacao_ === "INDIFERENTE"
          ? theme.colors.warning._600
          : theme.colors.gray._300},
    0 0 0 7px ${({ theme, _bg_ }) => _bg_ ?? theme.colors.fixed.offWhite};
  align-items: center;
  z-index: 1;
  justify-content: center;
  border-radius: 100px;

  background-color: ${({ theme, _active_, _color_, _situacao_ }) =>
    !!_color_
      ? _color_
      : _active_
      ? theme.colors.info._500
      : _situacao_ === "COMPLETA"
      ? theme.colors.success._600
      : _situacao_ === "INDIFERENTE"
      ? theme.colors.warning._600
      : theme.colors.gray._300};

  & .MuiSvgIcon-root {
    font-size: 0.7rem;
    color: ${({ theme }) => theme.colors.fixed.white};
  }
`;
const EtapaDescription = styled("p", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.tiny};
  font-weight: 500;
  width: 80px;
  color: ${({ theme, _active_, _color_, _situacao_ }) =>
    !!_color_
      ? _color_
      : _active_
      ? theme.colors.info._500
      : _situacao_ === "COMPLETA"
      ? theme.colors.success._600
      : _situacao_ === "INDIFERENTE"
      ? theme.colors.warning._600
      : theme.colors.gray._300};
  margin: 8px 0 0 0;
  text-align: center;
`;
const EtapaDescriptionMobile = styled("p", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 500;
  color: ${({ theme, _active_, _color_, _situacao_ }) =>
    !!_color_
      ? _color_
      : _active_
      ? theme.colors.info._500
      : _situacao_ === "COMPLETA"
      ? theme.colors.success._600
      : _situacao_ === "INDIFERENTE"
      ? theme.colors.warning._600
      : theme.colors.gray._300};
  margin: 0.6rem 0 0.6rem 0.5rem;
  text-align: left;
`;
const CircleText = styled("p", transientOptions)`
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.fixed.white};
`;
const CircleTextMobile = styled("p", transientOptions)`
  margin: 0;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.fixed.white};
`;
const Line = styled("span")`
  position: absolute;
  ${({ _start_ }) => (!!_start_ ? "left: 0" : "right: 0")};
  top: 16px;
  width: 50%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray._200};
  z-index: 0;
`;
const SeeMorePill = styled("div")`
  cursor: pointer;
  margin: 0.5rem auto 0;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.gray._100};
  font-size: ${({ theme }) => theme.fonts.size.cliente.card.cpfCnpj};
  font-weight: 300;
  text-align: ${({ _align_ }) => _align_ ?? "left"};
  color: ${({ theme }) => theme.colors.gray._800};
  padding: 0.2rem 1rem;
  width: fit-content;
`;
const StepperDesktop = ({
  fluxo,
  events,
  ultimo,
  cor,
  situacao,
  current,
  backgroundColor,
}) => {
  return fluxo.map((d, i) => {
    return (
      <EtapaDesktopContainer
        key={d.nome + d.situacao}
        onClick={events[i]}
        _align_={
          i === 0
            ? "flex-start"
            : i === fluxo.length - 1
            ? "flex-end"
            : "center"
        }
      >
        {i > 0 && <Line _start_={true} />}
        {i < fluxo.length - 1 && <Line _start_={false} />}
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
        >
          <Circle
            _situacao_={d.situacao}
            _active_={current === i}
            _color_={d.tipo === ultimo?.tipo ? cor : null}
            _bg_={backgroundColor}
          >
            {d.tipo === ultimo?.tipo && situacao === "C" ? (
              <Close />
            ) : d.situacao === "COMPLETA" ? (
              <Done />
            ) : d.situacao === "INDIFERENTE" ? (
              <Remove />
            ) : (
              <CircleText _situacao_={d.situacao} _active_={current === i}>
                {i + 1}
              </CircleText>
            )}
          </Circle>
          <EtapaDescription
            _situacao_={d.situacao}
            _active_={current === i}
            _color_={d.tipo === ultimo?.tipo ? cor : null}
          >
            {d.nome}
          </EtapaDescription>
        </Grid>
      </EtapaDesktopContainer>
    );
  });
};
const StepperMobile = ({
  fluxo,
  events,
  current,
  ultimo,
  situacao,
  cor,
  supress,
  backgroundColor,
}) => {
  return fluxo.map((d, i) => {
    if (!!supress && ultimo?.tipo !== d.tipo) {
      return null;
    }
    return (
      <EtapaMobileContainer
        key={d.nome + d.situacao}
        onClick={events[i]}
        _align_={"center"}
      >
        <Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <MobileCircle
            _situacao_={d.situacao}
            _active_={current === i}
            _color_={d.tipo === ultimo?.tipo ? cor : null}
            _bg_={backgroundColor}
          >
            {d.situacao === "COMPLETA" ? (
              d.tipo === ultimo?.tipo && situacao === "C" ? (
                <Close />
              ) : (
                <Done />
              )
            ) : d.situacao === "INDIFERENTE" ? (
              <Remove />
            ) : (
              <CircleTextMobile
                _situacao_={d.situacao}
                _active_={current === i}
              >
                {i + 1}
              </CircleTextMobile>
            )}
          </MobileCircle>
          <EtapaDescriptionMobile
            _situacao_={d.situacao}
            _active_={current === i}
            _color_={d.tipo === ultimo?.tipo ? cor : null}
          >
            {d.nome}
          </EtapaDescriptionMobile>
        </Grid>
      </EtapaMobileContainer>
    );
  });
};
const Stepper = ({
  fluxo,
  current,
  events = [],
  backgroundColor,
  cor,
  situacao,
  align,
  shouldSupress = false,
}) => {
  const [supress, setSupress] = useState(true);
  const currentSize = useResponsive();
  const isMobile = currentSize === "xs" || currentSize === "sm";
  const ultimo =
    current >= 0
      ? fluxo[current]
      : fluxo.filter((f) => f.situacao !== "PENDENTE").reverse()[0] ?? fluxo[0];

  return (
    <>
      {!!isMobile && (
        <StepperMobileContainer _align_={align}>
          <span>
            <StepperMobile
              fluxo={fluxo}
              current={current}
              cor={cor}
              situacao={situacao}
              ultimo={ultimo}
              supress={shouldSupress && supress}
              events={events}
              backgroundColor={backgroundColor}
            />
          </span>
          {!!shouldSupress && (
            <SeeMorePill onClick={() => setSupress(!supress)}>
              {!!supress ? "Ver Mais" : "Esconder"}
            </SeeMorePill>
          )}
        </StepperMobileContainer>
      )}
      {!isMobile && (
        <StepperDesktopContainer>
          <StepperDesktop
            fluxo={fluxo}
            current={current}
            ultimo={ultimo}
            situacao={situacao}
            events={events}
            cor={cor}
            backgroundColor={backgroundColor}
          />
        </StepperDesktopContainer>
      )}
    </>
  );
};

export default Stepper;
