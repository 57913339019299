import SoonPage from "../SoonPage";
import CommonPage from "../CommonPage";
import { Grid, IconButton, Skeleton } from "@mui/material";
import { Card, Text, Title, Tooltip } from "../../components";
import { Fragment } from "react";
import { OpenInNew } from "@mui/icons-material";

const MaterialApoioPage = ({ loading, grupos, handleMaterialClick }) => {
  return (
    <CommonPage selected={"materiais-apoio"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Title>Materiais de Apoio</Title>
        </Grid>
        {!!loading && (
          <>
            <Grid item xs={12}>
              <Skeleton variant={"text"} width={120} height={40} />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Skeleton variant={"rounded"} width={"100%"} height={88} />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Skeleton variant={"rounded"} width={"100%"} height={88} />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Skeleton variant={"rounded"} width={"100%"} height={88} />
              </Grid>
            </Grid>
          </>
        )}
        {!loading &&
          grupos.map((grupo) => (
            <Fragment key={grupo.id}>
              <Grid item xs={12}>
                <Title as={"h2"} _color_={"gray"}>
                  {grupo.nome}
                </Title>
                {!!grupo.descricao && (
                  <Text as={"small"}>{grupo.descricao}</Text>
                )}
              </Grid>
              <Grid item container spacing={2}>
                {grupo.materiais.map((material) => (
                  <Grid item xs={12} md={6} lg={4} xl={3} key={material.id}>
                    <Card>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={true}>
                          <Title as={"h4"}>{material.nome}</Title>
                        </Grid>
                        <Grid item>
                          <Tooltip arrow title={"Abrir em outra aba"}>
                            <IconButton
                              onClick={() => handleMaterialClick(material)}
                            >
                              <OpenInNew />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Fragment>
          ))}
      </Grid>
    </CommonPage>
  );
};

export default MaterialApoioPage;
