import {
  AccordionDetails,
  AccordionSummary,
  Badge,
  Grid,
  styled,
} from "@mui/material";
import React, { Fragment } from "react";
import {
  Button,
  Card,
  GoBack,
  IconButton,
  Title,
  Tooltip,
} from "../../../../components";
import {
  Apartment,
  AttachMoney,
  Check,
  DeleteForever,
  Edit,
  ExpandMore,
  Home,
  Person,
  PriorityHigh,
} from "@mui/icons-material";
import formatters from "../../../../utils/formatters";
import {
  DetailsAccordion,
  Field as OtherField,
} from "../../../../components/presentation";
import { transientOptions } from "../../../../styles/theme";
import ItemOperacao from "./ItemOperacao";

const MAX_VENDEDORES = 5;

const CompradorContainer = styled("div")`
  width: calc(100% - 1rem);
  padding: 1rem 0.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gray._50};
`;
const IconContainer = styled("div")`
  width: 48px;
  height: 48px;
  font-size: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.primary._100};
  color: ${({ theme }) => theme.colors.primary._700};

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    width: 64px;
  	height: 64px;
  	font-size: 68px;
  }`}
`;
const Label = styled("span", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray._300};
`;
const Disclaimer = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  text-align: center;
  display: block;
  color: ${({ theme }) => theme.colors.gray._500};
`;
const NomeComprador = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray._500};
  margin: 0.5rem 0 0 1rem;
`;
const ContasLiberacao = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray._500};
  margin-left: 1rem;
`;
const CpfCnpjComprador = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray._200};
  margin: 0 0 0 1rem;
`;
const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.gray._100};
  color: ${({ theme }) => theme.colors.gray._700};

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.gray._100};
  }
  & .MuiSvgIcon-root {
    font-size: 1rem;
  }

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    & .MuiSvgIcon-root {
			font-size: 1.5rem;
		}
  }`}
`;
const SmallPriorityHigh = styled(PriorityHigh)`
  font-size: 1rem;
`;

const ResumoComprador = ({ data, dominios }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Dados Pessoais
            </Title>
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField
              label={"Data de Nascimento"}
              value={data.dataNascimento}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField
              label={"Gênero"}
              type={"lookup"}
              value={data.genero?.id}
              domain={{
                data: dominios.genero,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OtherField
              label={"Nacionalidade"}
              type={"lookup"}
              value={data.nacionalidade?.id}
              domain={{
                data: dominios.nacionalidade,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Documento
            </Title>
          </Grid>
          <Grid item xs={5} md={2}>
            <OtherField
              label={"Documento"}
              type={"lookup"}
              value={data.documentoPessoal?.id}
              domain={{
                data: dominios.documentoPessoal,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          <Grid item xs={7} md={3}>
            <OtherField
              label={"Número do Documento"}
              value={data.numeroDocumentoPessoal}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <OtherField
              label={"Órgão Expedidor"}
              value={data.orgaoDocumentoPessoal}
            />
          </Grid>
          <Grid item xs={3} md={1}>
            <OtherField label={"Estado"} value={data.ufOrgaoDocumentoPessoal} />
          </Grid>
          <Grid item xs={5} md={3}>
            <OtherField
              label={"Data de Expedição"}
              value={data.dataExpedicaoDocumentoPessoal}
            />
          </Grid>
          <Grid item xs={12}>
            <OtherField label={"Nome da Mãe"} value={data.nomeMae} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Informações de Contato
            </Title>
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField label={"Celular"} value={data.telefone} />
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField
              label={"Telefone Residencial"}
              value={data.telefoneResidencial}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OtherField label={"E-mail"} value={data.email} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Endereço Residencial
            </Title>
          </Grid>
          <Grid item xs={4} md={2}>
            <OtherField
              label={"CEP"}
              type={"zipcode"}
              value={data.endereco?.cep}
            />
          </Grid>
          <Grid item xs={8} md={4}>
            <OtherField label={"Endereço"} value={data.endereco?.logradouro} />
          </Grid>
          <Grid item xs={3} md={1}>
            <OtherField label={"Número"} value={data.endereco?.numero} />
          </Grid>
          {data.endereco?.complemento && (
            <Grid item xs={4} md={2}>
              <OtherField
                label={"Complemento"}
                value={data.endereco?.complemento}
              />
            </Grid>
          )}
          <Grid item xs={6} md={2}>
            <OtherField label={"Município"} value={data.endereco?.municipio} />
          </Grid>
          <Grid item xs={3} md={1}>
            <OtherField label={"UF"} value={data.endereco?.uf} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Dados Profissionais
            </Title>
          </Grid>
          <Grid item xs={5} md={4}>
            <OtherField
              label={"Perfil de Renda"}
              type={"lookup"}
              value={data.perfilRenda?.id}
              domain={{
                data: dominios.perfilRenda,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          <Grid item xs={7} md={8}>
            <OtherField
              label={"Profissão"}
              type={"lookup"}
              value={data.dadosProfissionais?.profissao?.id}
              domain={{
                data: dominios.profissao,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <OtherField
              label={"Nome da Empresa"}
              value={data.dadosProfissionais?.nomeEmpresa}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <OtherField
              label={"Data de Início"}
              value={data.dadosProfissionais?.dataAdmissao}
            />
          </Grid>
          <Grid item xs={7} md={4}>
            <OtherField
              label={"Cargo"}
              value={data.dadosProfissionais?.cargo}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Estado Civil
            </Title>
          </Grid>
          <Grid item xs={7} md={3}>
            <OtherField
              label={"Estado Civil"}
              type={"lookup"}
              value={data.estadoCivil?.id}
              domain={{
                data: dominios.estadoCivil,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          {dominios.estadoCivil.find((x) => x.id === data.estadoCivil?.id)
            ?.exibeUniaoEstavel && (
            <Grid item xs={5} md={3}>
              <OtherField
                label={"União Estável"}
                type={"boolean"}
                value={data.uniaoEstavel}
              />
            </Grid>
          )}
          {data.dataCasamento && (
            <Grid item xs={5} md={3}>
              <OtherField
                label={"Data do Casamento"}
                value={data.dataCasamento}
              />
            </Grid>
          )}
          {data.regimeComunhao?.id && (
            <Grid item xs={5} md={6}>
              <OtherField
                label={"Regime de Comunhão"}
                type={"lookup"}
                value={data.regimeComunhao?.id}
                domain={{
                  data: dominios.regimeComunhao,
                  filter: (x, v) => x.id === v,
                  map: (x) => x?.descricao,
                }}
              />
            </Grid>
          )}
          {data.conjuge && (
            <>
              <Grid item xs={12}>
                <Title as={"h5"} _color_={"primary"}>
                  Cônjuge
                </Title>
              </Grid>
              <Grid item xs={5} md={4}>
                <OtherField
                  label={"Nome Completo"}
                  value={data.conjuge?.nome}
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <OtherField
                  label={"CPF"}
                  type={"cpf"}
                  value={data.conjuge?.cpfCnpj}
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <OtherField
                  label={"Data de Nascimento"}
                  value={data.conjuge?.dataNascimento}
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <OtherField
                  label={"Gênero"}
                  type={"lookup"}
                  value={data.conjuge?.genero?.id}
                  domain={{
                    data: dominios.genero,
                    filter: (x, v) => x.id === v,
                    map: (x) => x?.descricao,
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const ResumoContasDeLiberacao = ({ data, dominios }) => {
  return (
    <Grid container spacing={2}>
      {data.map((vendedor, index) => {
        return (
          <Grid item xs={12} key={index}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Title as={"h5"} _color_={"primary"}>
                  {vendedor.nome}
                </Title>
              </Grid>
              {vendedor?.contaLiberacao ? (
                <>
                  <Grid item xs={6} md={3}>
                    <OtherField
                      label={"Banco"}
                      value={
                        dominios.banco.find(
                          (banco) =>
                            banco.id == vendedor?.contaLiberacao?._bancoConta
                        ).nome
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <OtherField
                      label={"Agência"}
                      value={vendedor?.contaLiberacao?.agenciaConta}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <OtherField
                      label={"Conta"}
                      value={vendedor?.contaLiberacao?.numeroConta}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <OtherField
                      label={"Tipo"}
                      value={
                        dominios.tipoConta.find(
                          (tipoConta) =>
                            vendedor?.contaLiberacao?._tipoConta == tipoConta.id
                        ).descricao
                      }
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} md={12}>
                  <Label>A conta de liberação não foi cadastrada.</Label>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

const HubDefault = ({
  imovel,
  compradores,
  vendedores,
  handleGoBack,
  handleAcessarParticipante,
  handleAcessarImovel,
  handleAcessarContasLiberacao,
  handleAdicionarParticipante,
  handleRemoverParticipante,
  adding,
  deleting,
  refreshing,
  submitting,
  handleProximaTela,
  sending,
  possuiPendencias,
  operacao,
  dominios,
}) => {
  const operacoesComContaCadastrada = vendedores.filter(
    (vendedor) => vendedor?.contaLiberacao
  );

  return (
    <Grid item container justifyContent={"center"} spacing={3}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <GoBack
          handleClick={handleGoBack}
          text={
            operacao.tipoProduto === "I" && !operacao.detalhe?.portabilidade
              ? "Preencha todas as informações sobre o imóvel, compradores e vendedores para gerar o check-list inteligente"
              : "Preencha todas as informações sobre o imóvel e compradores para gerar o check-list inteligente"
          }
        />
        <Grid item container>
          <Grid item xs={12}>
            <Title as={"small"} _color_={"gray"}>
              Logo esta etapa irá preencher os formulários dos bancos
              automaticamente com as informações preenchidas
            </Title>
          </Grid>
        </Grid>
        <Grid item container justifyContent={"center"}>
          <Grid item>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Title as={"h5"}>Imóvel</Title>
                </Grid>
                <Grid item xs={12}>
                  <DetailsAccordion elevation={0} square disableGutters>
                    <AccordionSummary
                      expandIcon={
                        <Tooltip arrow title={"Resumo das informações"}>
                          <StyledIconButton>
                            <ExpandMore />
                          </StyledIconButton>
                        </Tooltip>
                      }
                    >
                      <Grid width={"100%"} py={2} pr={1}>
                        <Grid container spacing={1} alignItems={"center"}>
                          <Grid item>
                            <Badge
                              badgeContent={
                                imovel.fluxo.filter(
                                  (f) =>
                                    f.situacao === "PARCIAL" ||
                                    f.situacao === "PENDENTE"
                                ).length > 0 ? (
                                  <SmallPriorityHigh />
                                ) : (
                                  <SmallPriorityHigh as={Check} />
                                )
                              }
                              size="large"
                              color={
                                imovel.fluxo.filter(
                                  (f) =>
                                    f.situacao === "PARCIAL" ||
                                    f.situacao === "PENDENTE"
                                ).length > 0
                                  ? "error"
                                  : "success"
                              }
                            >
                              <IconContainer>
                                <Home />
                              </IconContainer>
                            </Badge>
                          </Grid>
                          <Grid
                            item
                            container
                            direction={"column"}
                            alignSelf={"flex-start"}
                            xs
                          >
                            <NomeComprador>
                              {formatters.strings.address(imovel.endereco) ??
                                "(Endereço não informado)"}
                            </NomeComprador>
                          </Grid>
                          <Grid item>
                            <Tooltip arrow title={"Editar informações"}>
                              <StyledIconButton
                                loading={submitting}
                                loaderColor={"gray"}
                                disabled={
                                  submitting || deleting || refreshing || adding
                                }
                                onClick={handleAcessarImovel}
                              >
                                <Edit />
                              </StyledIconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails></AccordionDetails>
                  </DetailsAccordion>
                </Grid>
                <Grid item xs={12}>
                  <Title as={"h5"}>
                    {compradores.length > 1 ? "Compradores" : "Comprador"}
                  </Title>
                </Grid>
                <Grid item xs={12}>
                  {compradores.map((c) => {
                    return (
                      <DetailsAccordion
                        elevation={0}
                        key={c.id}
                        square
                        disableGutters
                      >
                        <AccordionSummary
                          expandIcon={
                            <Tooltip arrow title={"Resumo das informações"}>
                              <StyledIconButton>
                                <ExpandMore />
                              </StyledIconButton>
                            </Tooltip>
                          }
                        >
                          <Grid width={"100%"} py={2} pr={1}>
                            <Grid container spacing={1} alignItems={"center"}>
                              <Grid item>
                                <Badge
                                  badgeContent={
                                    c.fluxo.filter(
                                      (f) =>
                                        f.situacao === "PARCIAL" ||
                                        f.situacao === "PENDENTE"
                                    ).length > 0 ? (
                                      <SmallPriorityHigh />
                                    ) : (
                                      <SmallPriorityHigh as={Check} />
                                    )
                                  }
                                  size="large"
                                  color={
                                    c.fluxo.filter(
                                      (f) =>
                                        f.situacao === "PARCIAL" ||
                                        f.situacao === "PENDENTE"
                                    ).length > 0
                                      ? "error"
                                      : "success"
                                  }
                                >
                                  <IconContainer>
                                    <Person />
                                  </IconContainer>
                                </Badge>
                              </Grid>
                              <Grid
                                item
                                container
                                direction={"column"}
                                alignSelf={"flex-start"}
                                xs
                              >
                                <NomeComprador>
                                  {c.nome ?? "(Nome não informado)"}
                                </NomeComprador>
                                <CpfCnpjComprador>
                                  {formatters.strings.cpfCnpj(
                                    c.cpfCnpj,
                                    c.tipoPessoa
                                  )}
                                </CpfCnpjComprador>
                              </Grid>
                              <Grid item>
                                <Tooltip arrow title={"Editar informações"}>
                                  <StyledIconButton
                                    loading={submitting}
                                    loaderColor={"gray"}
                                    disabled={
                                      submitting ||
                                      deleting ||
                                      refreshing ||
                                      adding
                                    }
                                    onClick={(e) =>
                                      handleAcessarParticipante(e, c)
                                    }
                                  >
                                    <Edit />
                                  </StyledIconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ResumoComprador data={c} dominios={dominios} />
                        </AccordionDetails>
                      </DetailsAccordion>
                    );
                  })}
                </Grid>
                {operacao.tipoProduto === "I" &&
                  !operacao.detalhe?.portabilidade && (
                    <>
                      {vendedores.length > 0 && (
                        <Grid item xs={12}>
                          <Title as={"h5"}>
                            {vendedores.length > 1 ? "Vendedores" : "Vendedor"}
                          </Title>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                      {vendedores.map((v) => {
                          return (
                            <Fragment key={v.id}>
                              <ItemOperacao
                                key={v.id}
                                fluxo={v.fluxo}
                                submitting={submitting}
                                refreshing={refreshing}
                                deleting={deleting}
                                adding={adding}
                                icon={v.tipoPessoa === "F" ? <Person /> : <Apartment />}
                                title={v.nome ?? "(Nome não informado)"}
                                subtitle={formatters.strings.cpfCnpj(
                                  v.cpfCnpj,
                                  v.tipoPessoa
                                )}
                                isChildren={!!v.participanteOrigem}
                                titleRemover={"Remover proponente"}
                                canRemove={true}
                                handleRemover={(e) =>
                                  handleRemoverParticipante(e, v.id)
                                }
                                handleEditar={(e) => handleAcessarParticipante(e, v)}
                                details={
                                  <ResumoComprador data={v} dominios={dominios} />
                                }
                              />
                              {v.representantes.length > 0 && v.representantes.map(r => {
                                return (
                                  <ItemOperacao
                                    key={r.id}
                                    fluxo={r.fluxo}
                                    submitting={submitting}
                                    refreshing={refreshing}
                                    deleting={deleting}
                                    adding={adding}
                                    icon={r.tipoPessoa === "F" ? <Person /> : <Apartment />}
                                    title={r.nome ?? "(Nome não informado)"}
                                    subtitle={formatters.strings.cpfCnpj(
                                      r.cpfCnpj,
                                      r.tipoPessoa
                                    )}
                                    isChildren={true}
                                    titleRemover={"Remover proponente"}
                                    canRemove={true}
                                    handleRemover={(e) =>
                                      handleRemoverParticipante(e, r.id)
                                    }
                                    handleEditar={(e) => handleAcessarParticipante(e, r)}
                                    details={
                                      <ResumoComprador data={r} dominios={dominios} />
                                    }
                                  />
                                )
                              })}
                            </Fragment>
                          );
                        })}
                        {vendedores.length < MAX_VENDEDORES && (
                          <CompradorContainer>
                            <Grid
                              container
                              spacing={2}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <Grid item xs={12} sm={"auto"}>
                                <Disclaimer>
                                  {vendedores.length === 0
                                    ? "Adicione um vendedor do imóvel desejado"
                                    : "Gostaria de adicionar outro vendedor?"}
                                </Disclaimer>
                              </Grid>
                              <Grid item>
                                <Tooltip arrow title={"Adicionar vendedor PF"}>
                                  <Button
                                    variant={"outlined"}
                                    size={"small"}
                                    loading={adding || refreshing}
                                    loaderColor={"brand"}
                                    onClick={() =>
                                      handleAdicionarParticipante("VD", "F")
                                    }
                                  >
                                    PF
                                  </Button>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                <Tooltip arrow title={"Adicionar vendedor PJ"}>
                                  <Button
                                    variant={"outlined"}
                                    size={"small"}
                                    loading={adding || refreshing}
                                    loaderColor={"brand"}
                                    onClick={() =>
                                      handleAdicionarParticipante("VD", "J")
                                    }
                                  >
                                    PJ
                                  </Button>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </CompradorContainer>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Title as={"h5"}>Contas de Liberação</Title>
                      </Grid>
                      <Grid item xs={12}>
                        <DetailsAccordion elevation={0} square disableGutters>
                          <AccordionSummary
                            expandIcon={
                              <Tooltip arrow title={"Resumo das informações"}>
                                <StyledIconButton>
                                  <ExpandMore />
                                </StyledIconButton>
                              </Tooltip>
                            }
                          >
                            <Grid width={"100%"} py={2} pr={1}>
                              <Grid container spacing={1} alignItems={"center"}>
                                <Grid item>
                                  <Badge
                                    badgeContent={
                                      vendedores.filter(
                                        (f) => f?.contaLiberacao
                                      ).length > 0 ? (
                                        <SmallPriorityHigh as={Check} />
                                      ) : (
                                        <SmallPriorityHigh />
                                      )
                                    }
                                    size="large"
                                    color={
                                      vendedores.filter(
                                        (f) => f?.contaLiberacao
                                      ).length > 0
                                        ? "success"
                                        : "error"
                                    }
                                  >
                                    <IconContainer>
                                      <AttachMoney />
                                    </IconContainer>
                                  </Badge>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  direction={"column"}
                                  alignSelf={"flex-center"}
                                  xs
                                >
                                  <NomeComprador>
                                    Contas de Liberação
                                  </NomeComprador>
                                  <CpfCnpjComprador>
                                    {operacoesComContaCadastrada.length == 0 &&
                                      "0 contas cadastradas"}
                                    {operacoesComContaCadastrada.length == 1 &&
                                      `
                                    ${
                                      dominios.banco.find(
                                        (banco) =>
                                          banco.id ==
                                          operacoesComContaCadastrada[0]
                                            .contaLiberacao._bancoConta
                                      )?.nome
                                    } -
                                    ${
                                      operacoesComContaCadastrada[0]
                                        .contaLiberacao.agenciaConta
                                    } -
                                    ${
                                      operacoesComContaCadastrada[0]
                                        .contaLiberacao.numeroConta
                                    }
                                  `}
                                    {operacoesComContaCadastrada.length > 1 &&
                                      `${operacoesComContaCadastrada.length} contas cadastradas`}
                                  </CpfCnpjComprador>
                                </Grid>
                                <Grid item>
                                  <Tooltip arrow title={"Editar informações"}>
                                    <StyledIconButton
                                      loading={submitting}
                                      loaderColor={"gray"}
                                      disabled={
                                        submitting ||
                                        deleting ||
                                        refreshing ||
                                        adding
                                      }
                                      onClick={() =>
                                        handleAcessarContasLiberacao()
                                      }
                                    >
                                      <Edit />
                                    </StyledIconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ResumoContasDeLiberacao
                              data={vendedores}
                              dominios={dominios}
                            />
                          </AccordionDetails>
                        </DetailsAccordion>
                      </Grid>
                    </>
                  )}
                <Grid item container justifyContent={"center"} mt={1}>
                  <Grid item xs={12} md={6}>
                    <Button
                      disabled={possuiPendencias}
                      fullWidth
                      loading={submitting || sending}
                      onClick={handleProximaTela}
                    >
                      {"CONTINUAR"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HubDefault;
