import React from "react";
import { CloseSharp } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  TableContainer as MTableContainer,
  styled,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { Title } from "../../components";
import { BodyTableRow, SizedTableCell, Table } from "../../components/tables";
import formatters from "../../utils/formatters";
import { logos } from "../../constants/generic.constants";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
const TableContainer = styled(MTableContainer)`
  box-shadow: none;
  max-height: 50vh;
`;
const NormalText = styled("span")`
  font-weight: 300;
`;

const Logo = styled("img")`
  width: 48px;
  height: 48px;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.gray._100};
  object-fit: contain;
  display: block;
`;

const ComissionamentoParceiroDialog = ({ handleClose, open, data }) => {
  const agrupadosPorProduto =
    data?.comissionamentos?.reduce((acc, comissionamento) => {
      const produto = comissionamento.produto.nome;
      const instituicao = comissionamento.instituicao.codigo;

      if (!acc[produto]) {
        acc[produto] = {
          produto,
          instituicoes: {},
        };
      }

      if (!acc[produto].instituicoes[instituicao]) {
        acc[produto].instituicoes[instituicao] = {
          faixasDeValor: [],
        };
      }

      acc[produto].instituicoes[instituicao].faixasDeValor.push({
        valorInicio: comissionamento.valorInicio,
        valorFim: comissionamento.valorFim,
        instituicao: comissionamento.instituicao.nomeFantasia,
        comissao: comissionamento.comissao,
      });

      return acc;
    }, {}) ?? {};

  return (
    <StyledDialog
      onClose={() => handleClose()}
      disableEscapeKeyDown
      maxWidth="md"
      open={open}
    >
      <DialogContent>
        <CloseIconButton onClick={handleClose}>
          <CloseSharp />
        </CloseIconButton>
        <Title as={"h2"} _margin_={"0 0 1rem 0"}>
          Comissionamento - {data.nomeFantasia}
        </Title>
        <TableContainer component={Paper}>
          <Table sx={{ margin: 0 }} stickyHeader>
            <TableHead>
              <TableRow>
                <SizedTableCell style={{ minWidth: 100 }}>
                  Produto
                </SizedTableCell>
                <SizedTableCell width={52} />
                <SizedTableCell style={{ minWidth: 150 }}>
                  Faixa de Valor
                </SizedTableCell>
                <SizedTableCell width={72}>Comissão</SizedTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(agrupadosPorProduto).map((produtoKey) => {
                const agrupamentoProduto = agrupadosPorProduto[produtoKey];
                const tamanhoTotal = Object.keys(
                  agrupamentoProduto.instituicoes
                ).reduce(
                  (total, item) =>
                    total +
                    agrupamentoProduto.instituicoes[item].faixasDeValor.length,
                  0
                );

                return (
                  <React.Fragment key={produtoKey}>
                    {Object.keys(agrupamentoProduto.instituicoes).map(
                      (instituicaoKey, j) => {
                        const instituicaoObject =
                          agrupamentoProduto.instituicoes[instituicaoKey];

                        return (
                          <React.Fragment key={instituicaoKey}>
                            {instituicaoObject.faixasDeValor.map(
                              (faixa, index) => (
                                <BodyTableRow
                                  key={produtoKey + instituicaoKey + index}
                                >
                                  {j === 0 && index === 0 && (
                                    <SizedTableCell
                                      rowSpan={tamanhoTotal}
                                      style={{ minWidth: 60 }}
                                    >
                                      <NormalText>
                                        <b>{produtoKey}</b>
                                      </NormalText>
                                    </SizedTableCell>
                                  )}
                                  {index === 0 && (
                                    <SizedTableCell
                                      rowSpan={
                                        instituicaoObject.faixasDeValor.length
                                      }
                                      width={52}
                                    >
                                      <Logo src={logos[instituicaoKey]} />
                                    </SizedTableCell>
                                  )}
                                  <SizedTableCell style={{ minWidth: 60 }}>
                                    <NormalText>
                                      {faixa.valorInicio === 0 ? (
                                        <>
                                          Até{" "}
                                          <b>
                                            R$
                                            {formatters.numbers.currency(
                                              faixa.valorFim
                                            )}
                                          </b>
                                        </>
                                      ) : !!faixa.valorFim ? (
                                        <>
                                          De{" "}
                                          <b>
                                            R$
                                            {formatters.numbers.currency(
                                              faixa.valorInicio
                                            )}
                                          </b>{" "}
                                          até{" "}
                                          <b>
                                            R$
                                            {formatters.numbers.currency(
                                              faixa.valorFim
                                            )}
                                          </b>
                                        </>
                                      ) : (
                                        <>
                                          Acima de{" "}
                                          <b>
                                            R$
                                            {formatters.numbers.currency(
                                              faixa.valorInicio
                                            )}
                                          </b>
                                        </>
                                      )}
                                    </NormalText>
                                  </SizedTableCell>
                                  <SizedTableCell style={{ minWidth: 60 }}>
                                    <NormalText>
                                      <b>
                                        {formatters.numbers.currency(
                                          faixa.comissao
                                        )}
                                      </b>
                                      %
                                    </NormalText>
                                  </SizedTableCell>
                                </BodyTableRow>
                              )
                            )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </StyledDialog>
  );
};

export default ComissionamentoParceiroDialog;
