import {
  AccordionDetails,
  AccordionSummary,
  Badge,
  Grid,
  styled,
} from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  GoBack,
  IconButton,
  Title,
  Tooltip,
} from "../../../../components";
import {
  DetailsAccordion,
  Field as OtherField,
} from "../../../../components/presentation";
import {
  Add,
  Check,
  DeleteForever,
  Edit,
  ExpandMore,
  Person,
  PriorityHigh,
} from "@mui/icons-material";
import formatters from "../../../../utils/formatters";

const MAX_COMPRADORES = 2;

const CompradorContainer = styled("div")`
  width: 100%;
  padding: 1rem 0.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gray._50};
`;
const IconContainer = styled("div")`
  width: 48px;
  height: 48px;
  font-size: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.primary._100};
  color: ${({ theme }) => theme.colors.primary._700};

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    width: 64px;
  	height: 64px;
  	font-size: 68px;
  }`}
`;
const Disclaimer = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  text-align: center;
  display: block;
  color: ${({ theme }) => theme.colors.gray._500};
`;
const NomeComprador = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray._500};
  margin: 0.5rem 0 0 1rem;
`;
const CpfCnpjComprador = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray._200};
  margin: 0 0 0 1rem;
`;
const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.gray._100};
  color: ${({ theme }) => theme.colors.gray._700};

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.gray._100};
  }
  & .MuiSvgIcon-root {
    font-size: 1rem;
  }

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    & .MuiSvgIcon-root {
			font-size: 1.5rem;
		}
  }`}
`;
const SmallPriorityHigh = styled(PriorityHigh)`
  font-size: 0.7rem;
  ${({ theme }) => `${theme.breakpoints.up("md")} {
    font-size: 1rem;
  }`}
`;

const ResumoComprador = ({ data, dominios }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Dados Pessoais
            </Title>
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField
              label={"Data de Nascimento"}
              value={data.dataNascimento}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField
              label={"Gênero"}
              type={"lookup"}
              value={data.genero?.id}
              domain={{
                data: dominios.genero,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OtherField
              label={"Nacionalidade"}
              type={"lookup"}
              value={data.nacionalidade?.id}
              domain={{
                data: dominios.nacionalidade,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Documento
            </Title>
          </Grid>
          <Grid item xs={5} md={2}>
            <OtherField
              label={"Documento"}
              type={"lookup"}
              value={data.documentoPessoal?.id}
              domain={{
                data: dominios.documentoPessoal,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          <Grid item xs={7} md={3}>
            <OtherField
              label={"Número do Documento"}
              value={data.numeroDocumentoPessoal}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <OtherField
              label={"Órgão Expedidor"}
              value={data.orgaoDocumentoPessoal}
            />
          </Grid>
          <Grid item xs={3} md={1}>
            <OtherField label={"Estado"} value={data.ufOrgaoDocumentoPessoal} />
          </Grid>
          <Grid item xs={5} md={3}>
            <OtherField
              label={"Data de Expedição"}
              value={data.dataExpedicaoDocumentoPessoal}
            />
          </Grid>
          <Grid item xs={12}>
            <OtherField label={"Nome da Mãe"} value={data.nomeMae} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Informações de Contato
            </Title>
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField label={"Celular"} value={data.telefone} />
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField
              label={"Telefone Residencial"}
              value={data.telefoneResidencial}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OtherField label={"E-mail"} value={data.email} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Endereço Residencial
            </Title>
          </Grid>
          <Grid item xs={4} md={2}>
            <OtherField
              label={"CEP"}
              type={"zipcode"}
              value={data.endereco?.cep}
            />
          </Grid>
          <Grid item xs={8} md={4}>
            <OtherField label={"Endereço"} value={data.endereco?.logradouro} />
          </Grid>
          <Grid item xs={3} md={1}>
            <OtherField label={"Número"} value={data.endereco?.numero} />
          </Grid>
          {data.endereco?.complemento && (
            <Grid item xs={4} md={2}>
              <OtherField
                label={"Complemento"}
                value={data.endereco?.complemento}
              />
            </Grid>
          )}
          <Grid item xs={6} md={2}>
            <OtherField label={"Município"} value={data.endereco?.municipio} />
          </Grid>
          <Grid item xs={3} md={1}>
            <OtherField label={"UF"} value={data.endereco?.uf} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Dados Profissionais
            </Title>
          </Grid>
          <Grid item xs={5} md={4}>
            <OtherField
              label={"Perfil de Renda"}
              type={"lookup"}
              value={data.perfilRenda?.id}
              domain={{
                data: dominios.perfilRenda,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          <Grid item xs={7} md={8}>
            <OtherField
              label={"Profissão"}
              type={"lookup"}
              value={data.dadosProfissionais?.profissao?.id}
              domain={{
                data: dominios.profissao,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <OtherField
              label={"Nome da Empresa"}
              value={data.dadosProfissionais?.nomeEmpresa}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <OtherField
              label={"Data de Início"}
              value={data.dadosProfissionais?.dataAdmissao}
            />
          </Grid>
          <Grid item xs={7} md={4}>
            <OtherField
              label={"Cargo"}
              value={data.dadosProfissionais?.cargo}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Estado Civil
            </Title>
          </Grid>
          <Grid item xs={7} md={3}>
            <OtherField
              label={"Estado Civil"}
              type={"lookup"}
              value={data.estadoCivil?.id}
              domain={{
                data: dominios.estadoCivil,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          {dominios.estadoCivil.find((x) => x.id === data.estadoCivil?.id)
            ?.exibeUniaoEstavel && (
            <Grid item xs={5} md={3}>
              <OtherField
                label={"União Estável"}
                type={"boolean"}
                value={data.uniaoEstavel}
              />
            </Grid>
          )}
          {data.dataCasamento && (
            <Grid item xs={5} md={3}>
              <OtherField
                label={"Data do Casamento"}
                value={data.dataCasamento}
              />
            </Grid>
          )}
          {data.regimeComunhao?.id && (
            <Grid item xs={5} md={6}>
              <OtherField
                label={"Regime de Comunhão"}
                type={"lookup"}
                value={data.regimeComunhao?.id}
                domain={{
                  data: dominios.regimeComunhao,
                  filter: (x, v) => x.id === v,
                  map: (x) => x?.descricao,
                }}
              />
            </Grid>
          )}
          {data.conjuge && (
            <>
              <Grid item xs={12}>
                <Title as={"h5"} _color_={"primary"}>
                  Cônjuge
                </Title>
              </Grid>
              <Grid item xs={5} md={4}>
                <OtherField
                  label={"Nome Completo"}
                  value={data.conjuge?.nome}
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <OtherField
                  label={"CPF"}
                  type={"cpf"}
                  value={data.conjuge?.cpfCnpj}
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <OtherField
                  label={"Data de Nascimento"}
                  value={data.conjuge?.dataNascimento}
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <OtherField
                  label={"Gênero"}
                  type={"lookup"}
                  value={data.conjuge?.genero?.id}
                  domain={{
                    data: dominios.genero,
                    filter: (x, v) => x.id === v,
                    map: (x) => x?.descricao,
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const EscolhaParticipanteBradescoForm = ({
  compradores,
  handleAcessarParticipante,
  handleEnviarAnaliseCredito,
  handleAdicionarParticipante,
  adding,
  handleRemoverParticipante,
  deleting,
  refreshing,
  sending,
  possuiPendencias,
  submitting,
  dominios,
  handleGoBack,
}) => {
  return (
    <Grid item container justifyContent={"center"} spacing={3}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <GoBack
          handleClick={handleGoBack}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container justifyContent={"center"}>
          <Grid item>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Title as={"h5"}>
                    {compradores.length > 1 ? "Compradores" : "Comprador"}
                  </Title>
                </Grid>
                <Grid item xs={12}>
                  {compradores.map((c) => {
                    return (
                      <DetailsAccordion
                        elevation={0}
                        key={c.id}
                        square
                        disableGutters
                      >
                        <AccordionSummary
                          expandIcon={
                            <Tooltip arrow title={"Resumo das informações"}>
                              <StyledIconButton>
                                <ExpandMore />
                              </StyledIconButton>
                            </Tooltip>
                          }
                        >
                          <Grid width={"100%"} py={2} pr={1}>
                            <Grid container spacing={1} alignItems={"center"}>
                              <Grid item>
                                <Badge
                                  badgeContent={
                                    c.fluxo.filter(
                                      (f) =>
                                        f.situacao === "PARCIAL" ||
                                        f.situacao === "PENDENTE"
                                    ).length > 0 ? (
                                      <SmallPriorityHigh />
                                    ) : (
                                      <SmallPriorityHigh as={Check} />
                                    )
                                  }
                                  size="large"
                                  color={
                                    c.fluxo.filter(
                                      (f) =>
                                        f.situacao === "PARCIAL" ||
                                        f.situacao === "PENDENTE"
                                    ).length > 0
                                      ? "error"
                                      : "success"
                                  }
                                >
                                  <IconContainer>
                                    <Person />
                                  </IconContainer>
                                </Badge>
                              </Grid>
                              <Grid
                                item
                                container
                                direction={"column"}
                                alignSelf={"flex-start"}
                                xs
                              >
                                <NomeComprador>
                                  {c.nome ?? "(Nome não informado)"}
                                </NomeComprador>
                                <CpfCnpjComprador>
                                  {formatters.strings.cpfCnpj(
                                    c.cpfCnpj,
                                    c.tipoPessoa
                                  )}
                                </CpfCnpjComprador>
                              </Grid>
                              <Grid item>
                                {!c.principal && (
                                  <Tooltip arrow title={"Remover comprador"}>
                                    <StyledIconButton
                                      disabled={
                                        submitting ||
                                        deleting ||
                                        refreshing ||
                                        adding
                                      }
                                      loading={deleting || refreshing}
                                      loaderColor={"gray"}
                                      onClick={(e) =>
                                        handleRemoverParticipante(e, c.id)
                                      }
                                    >
                                      <DeleteForever />
                                    </StyledIconButton>
                                  </Tooltip>
                                )}
                              </Grid>
                              <Grid item>
                                <Tooltip arrow title={"Editar informações"}>
                                  <StyledIconButton
                                    loading={submitting}
                                    loaderColor={"gray"}
                                    disabled={
                                      submitting ||
                                      deleting ||
                                      refreshing ||
                                      adding
                                    }
                                    onClick={(e) =>
                                      handleAcessarParticipante(e, c)
                                    }
                                  >
                                    <Edit />
                                  </StyledIconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ResumoComprador data={c} dominios={dominios} />
                        </AccordionDetails>
                      </DetailsAccordion>
                    );
                  })}
                  {compradores.length < MAX_COMPRADORES && (
                    <CompradorContainer>
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Grid item>
                          <Disclaimer>
                            Gostaria de adicionar outro comprador pra compor
                            renda?
                          </Disclaimer>
                        </Grid>
                        <Grid item width={56}>
                          <Tooltip arrow title={"Adicionar comprador"}>
                            <StyledIconButton
                              disabled={
                                submitting || deleting || refreshing || adding
                              }
                              loading={adding || refreshing}
                              loaderColor={"gray"}
                              onClick={handleAdicionarParticipante}
                            >
                              <Add />
                            </StyledIconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CompradorContainer>
                  )}
                </Grid>
                <Grid item container justifyContent={"center"} mt={1}>
                  <Grid item xs={12} md={8}>
                    <Button
                      disabled={
                        possuiPendencias ||
                        submitting ||
                        adding ||
                        refreshing ||
                        deleting
                      }
                      loading={sending}
                      fullWidth
                      onClick={handleEnviarAnaliseCredito}
                    >
                      ENVIAR PARA ANÁLISE DE CRÉDITO
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EscolhaParticipanteBradescoForm;
