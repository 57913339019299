import CommonPage from "../CommonPage";
import { Button, Card, Title } from "../../components";
import {
  Autocomplete,
  Grid,
  InputAdornment,
  MenuItem as MMenuItem,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  BodyTableRow,
  SizedTableCell,
  Table,
  TablePagination,
} from "../../components/tables";
import { TextField } from "../../components/inputs";
import { Search } from "@mui/icons-material";
import React, { useState } from "react";
import { HorizontalLoader } from "../../components/loaders";
import { PERFIL, SITUACAO } from "../../constants/usuario.constants";

const CardHeader = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
const SituacaoUsuarioContainer = styled("span")`
  padding: 0.5rem 0.75rem;
  display: inline-block;
  font-weight: 700;
  color: ${({ theme, _situacao_ }) =>
    _situacao_ === "A"
      ? theme.colors.success._600
      : _situacao_ === "C"
      ? theme.colors.error._600
      : theme.colors.secondary._600};
  background-color: ${({ theme, _situacao_ }) =>
    _situacao_ === "A"
      ? theme.colors.success._100
      : _situacao_ === "C"
      ? theme.colors.error._100
      : theme.colors.secondary._100};
  border-radius: 6px;
`;
const ActionButton = styled("span")`
  padding: 0.5rem 0.75rem;
  display: inline-block;
  font-weight: 700;
  color: ${({ theme, _situacao_ }) => theme.colors.gray._300};
  background-color: ${({ theme, _situacao_ }) => theme.colors.gray._100};
  border-radius: 6px;
  cursor: pointer;
`;

const AddButton = styled(Button)`
  padding: 6px 0.75rem;
  display: inline-block;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary._500};
  border-radius: 0.75rem;
  cursor: pointer;
`;

const MenuItem = styled(MMenuItem)`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
`;

const StyledTextField = styled(TextField)`
  min-width: 240px;
`;

const ListaUsuariosPage = ({
  isOperacao,
  loading,
  filters,
  setFilters,
  filtered,
  page,
  total,
  handlePaginationClick,
  handleClick,
  handleNewUser,
  parceiros,
}) => {
  const [parceiro, setParceiro] = useState(null);

  return (
    <CommonPage selected={"usuarios"}>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader>
              <Title as={"h2"}>Usuários</Title>
              <Grid display={"flex"} alignItems={"center"} gap={"0.5rem"}>
                {isOperacao && (
                  <Grid item xs={12} md={"auto"}>
                    <Autocomplete
                      options={parceiros || []}
                      getOptionLabel={(option) =>
                        option.razaoSocial ??
                        parceiros.find((d) => d.codigo === option?.codigo)
                          ?.nome ??
                        ""
                      }
                      isOptionEqualToValue={(option, v) =>
                        option?.codigo === v || option?.codigo === v?.codigo
                      }
                      value={parceiro}
                      onChange={(e, v) => {
                        setParceiro(v);
                        if (filters.parceiro?.codigo !== v?.codigo) {
                          setFilters({
                            ...filters,
                            parceiro: v,
                            page: 0,
                            dirty: true,
                          });
                        }
                      }}
                      renderOption={(params, o) => (
                        <MenuItem {...params}>{o.nome}</MenuItem>
                      )}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          size={"small"}
                          placeholder={"Todos os parceiros"}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item>
                  <TextField
                    label={null}
                    size={"small"}
                    select
                    margin={"none"}
                    value={filters.perfil}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        perfil: e.target.value,
                        page: 0,
                        dirty: true,
                      })
                    }
                  >
                    <MenuItem value={"X"}>Todas os perfis</MenuItem>
                    <MenuItem value={"CADASTRO_MASTER"}>
                      {PERFIL.CADASTRO_MASTER}
                    </MenuItem>
                    <MenuItem value={"PARCEIRO_MASTER"}>
                      {PERFIL.PARCEIRO_MASTER}
                    </MenuItem>
                    <MenuItem value={"LIDER"}>{PERFIL.LIDER}</MenuItem>
                    <MenuItem value={"PROMOTOR"}>{PERFIL.PROMOTOR}</MenuItem>
                    <MenuItem value={"OPERACIONAL"}>
                      {PERFIL.OPERACIONAL}
                    </MenuItem>
                    <MenuItem value={"FINANCEIRO"}>
                      {PERFIL.FINANCEIRO}
                    </MenuItem>
                    {isOperacao && (
                      <MenuItem value={"OPERACAO"}>{PERFIL.OPERACAO}</MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item>
                  <TextField
                    label={null}
                    size={"small"}
                    select
                    margin={"none"}
                    value={filters.situacao}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        situacao: e.target.value,
                        page: 0,
                        dirty: true,
                      })
                    }
                  >
                    <MenuItem value={"X"}>Todas as situações</MenuItem>
                    <MenuItem value={"A"}>{SITUACAO.A}</MenuItem>
                    <MenuItem value={"B"}>{SITUACAO.B}</MenuItem>
                    <MenuItem value={"C"}>{SITUACAO.C}</MenuItem>
                  </TextField>
                </Grid>
                <Grid item>
                  <TextField
                    label={null}
                    size={"small"}
                    placeholder={"Pesquisar"}
                    margin={"none"}
                    value={filters.pesquisar}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        pesquisar: e.target.value,
                        page: 0,
                        dirty: true,
                      })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <AddButton onClick={handleNewUser}>NOVO USUÁRIO</AddButton>
                </Grid>
              </Grid>
            </CardHeader>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <SizedTableCell width={"240px"}>E-mail</SizedTableCell>
                    <SizedTableCell width={"300px"}>Perfil</SizedTableCell>
                    {isOperacao && <TableCell>Parceiro</TableCell>}
                    <SizedTableCell width={"240px"}>Situação</SizedTableCell>
                    <SizedTableCell width={"100px"}>Detalhes</SizedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!loading && (
                    <BodyTableRow index={0}>
                      <td>
                        <HorizontalLoader color={"brand"} size={40} />
                      </td>
                    </BodyTableRow>
                  )}
                  {!loading &&
                    filtered.map((usuario, index) => {
                      return (
                        <BodyTableRow key={usuario.id} index={index}>
                          <TableCell>{usuario.nome}</TableCell>
                          <SizedTableCell width={"240px"}>
                            {usuario.email}
                          </SizedTableCell>
                          <SizedTableCell width={"300px"}>
                            {PERFIL[usuario.perfil]}
                          </SizedTableCell>
                          {isOperacao && (
                            <TableCell>
                              {
                                parceiros.find(
                                  (p) => p.codigo === usuario.parceiro
                                )?.nome
                              }
                            </TableCell>
                          )}
                          <SizedTableCell width={"240px"}>
                            <SituacaoUsuarioContainer
                              _situacao_={usuario.situacao}
                            >
                              {SITUACAO[usuario.situacao]}
                            </SituacaoUsuarioContainer>
                          </SizedTableCell>
                          <SizedTableCell width={"100px"}>
                            <ActionButton onClick={() => handleClick(usuario)}>
                              Acessar
                            </ActionButton>
                          </SizedTableCell>
                        </BodyTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              loading={loading}
              page={page}
              rowsPerPage={10}
              handlePaginationClick={handlePaginationClick}
              total={total.length}
            />
          </Card>
        </Grid>
      </Grid>
    </CommonPage>
  );
};

export default ListaUsuariosPage;
