import React, { useState } from "react";
import { Dialog, DialogContent, Grid, IconButton, styled } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import ocr from "../../assets/ocr.gif";
import { Button, Title } from "../../components";
import { TypeAnimation } from "react-type-animation";
import { transientOptions } from "../../styles/theme";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
const GifOcr = styled("img")`
  width: 100%;
  height: 200px;
  object-fit: contain;
`;

const AnimatedTitle = styled(TypeAnimation, transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-weight: 700;
  display: ${({ _display_ = "block" }) => _display_};
  text-align: ${({ _align_ = "left" }) => _align_};
  color: ${({ theme, _color_ = "secondary" }) => theme.colors[_color_]._500};
  margin: ${({ _margin_ = "0" }) => _margin_};
`;
const AnimatedResult = styled(TypeAnimation, transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 300;
  color: ${({ theme, _color_ = "gray" }) => theme.colors[_color_]._400};
  white-space: pre-line;
  margin: ${({ _margin_ = "0" }) => _margin_};
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray._50};
  border-radius: 1rem;
  min-height: 150px;
`;

const ResultadoAnalise = ({ handleContinuar, result, submitting }) => {
  const [doneTyping, setDoneTyping] = useState(false);

  return (
    <>
      {!!result?.text && (
        <>
          <Grid item xs={12}>
            <AnimatedResult
              wrapper={"div"}
              _margin_={"0 0 1rem 0"}
              _color_={result.success ? "gray" : "error"}
              splitter={(str) => str.split(/(?= )/)}
              sequence={[result.text, 300, () => setDoneTyping(true)]}
            />
          </Grid>
        </>
      )}
      <Grid item container justifyContent={"center"}>
        <Grid item xs={12} md={8}>
          <Button
            loading={submitting}
            disabled={!result || !result?.success || !doneTyping}
            onClick={handleContinuar}
            fullWidth
          >
            CONTINUAR
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const InteligenciaArtificialDocumentoDialog = ({
  handleClose,
  open,
  ...props
}) => {
  return (
    <StyledDialog
      onClose={() => {}}
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
    >
      <DialogContent>
        <CloseIconButton onClick={handleClose}>
          <CloseSharp />
        </CloseIconButton>
        <Grid container spacing={1}>
          {!!props.validating && (
            <>
              <Grid item xs={12}>
                <AnimatedTitle
                  wrapper={"h2"}
                  cursor={false}
                  preRenderFirstString
                  repeat={Infinity}
                  _margin_={"0 0 1rem 0"}
                  sequence={[
                    "Analisando Documento",
                    300,
                    "Analisando Documento.",
                    300,
                    "Analisando Documento..",
                    300,
                    "Analisando Documento...",
                    300,
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <GifOcr src={ocr} />
              </Grid>
            </>
          )}
          {!props.validating && (
            <>
              <Grid item xs={12}>
                <Title as={"h2"} _margin_={"0 0 1rem 0"}>
                  Resultado da Análise
                </Title>
              </Grid>
              <Grid item xs={12}>
                <ResultadoAnalise {...props} />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

export default InteligenciaArtificialDocumentoDialog;
