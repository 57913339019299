import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  RESUMO: "@@operacao/RESUMO",
  LISTAR: "@@operacao/LISTAR",
  TROCAR_PAGINA_OPERACOES: "@@operacao/TROCAR_PAGINA_OPERACOES",
  OBTER: "@@operacao/OBTER",
  OBTER_FILTROS_OPERACOES: "@@operacao/OBTER_FILTROS_OPERACOES",
  OBTER_DOMINIOS: "@@operacao/OBTER_DOMINIOS",
  ATUALIZAR_RESPONSAVEL: "@@operacao/ATUALIZAR_RESPONSAVEL",
  COMPARTILHAR_OPERACAO: "@@operacao/COMPARTILHAR_OPERACAO",
  EXIBIR_DIALOG_COMPARTILHAMENTO: "@@operacao/EXIBIR_DIALOG_COMPARTILHAMENTO",
  EXIBIR_DIALOG_ATRIBUIR_RESPONSAVEL:
    "@@operacao/EXIBIR_DIALOG_ATRIBUIR_RESPONSAVEL",

  OBTER_CONJUGE: "@@operacao/OBTER_CONJUGE",

  OBTER_PREENCHIMENTO: "@@operacao/OBTER_PREENCHIMENTO",
  GRAVAR_PREENCHIMENTO: "@@operacao/GRAVAR_PREENCHIMENTO",
  ENVIAR_PREENCHIMENTO: "@@operacao/ENVIAR_PREENCHIMENTO",
  ADICIONAR_PARTICIPANTE_PREENCHIMENTO:
    "@@operacao/ADICIONAR_PARTICIPANTE_PREENCHIMENTO",
  ATUALIZAR_PARTICIPANTE_PREENCHIMENTO:
    "@@operacao/ATUALIZAR_PARTICIPANTE_PREENCHIMENTO",
  REMOVER_PARTICIPANTE_PREENCHIMENTO:
    "@@operacao/REMOVER_PARTICIPANTE_PREENCHIMENTO",
  REFRESH_PREENCHIMENTO: "@@operacao/REFRESH_PREENCHIMENTO",
  INICIAR_RELACIONADA: "@@operacao/INICIAR_RELACIONADA",

  OBTER_DADOS_BANCARIOS: "@@operacao/OBTER_DADOS_BANCARIOS",
  ENVIAR_DADOS_BANCARIOS: "@@operacao/ENVIAR_DADOS_BANCARIOS",

  OBTER_COMPLEMENTO: "@@operacao/OBTER_COMPLEMENTO",
  GRAVAR_COMPLEMENTO: "@@operacao/GRAVAR_COMPLEMENTO",
  ENVIAR_COMPLEMENTO: "@@operacao/ENVIAR_COMPLEMENTO",
  ADICIONAR_PARTICIPANTE_COMPLEMENTO:
    "@@operacao/ADICIONAR_PARTICIPANTE_COMPLEMENTO",
  ATUALIZAR_PARTICIPANTE_COMPLEMENTO:
    "@@operacao/ATUALIZAR_PARTICIPANTE_COMPLEMENTO",
  REMOVER_PARTICIPANTE_COMPLEMENTO:
    "@@operacao/REMOVER_PARTICIPANTE_COMPLEMENTO",
  REFRESH_COMPLEMENTO: "@@operacao/REFRESH_COMPLEMENTO",

  OBTER_CONFIRMACAO_VALORES: "@@operacao/OBTER_CONFIRMACAO_VALORES",
  ENVIAR_CONFIRMACAO_VALORES: "@@operacao/ENVIAR_CONFIRMACAO_VALORES",

  OBTER_DOCUMENTOS: "@@operacao/OBTER_DOCUMENTOS",
  ATUALIZAR_DOCUMENTOS: "@@operacao/ATUALIZAR_DOCUMENTOS",
  PREPARAR_UPLOAD_DOCUMENTO: "@@operacao/PREPARAR_UPLOAD_DOCUMENTO",
  REALIZAR_UPLOAD_DOCUMENTO: "@@operacao/REALIZAR_UPLOAD_DOCUMENTO",
  FINALIZAR_UPLOAD_DOCUMENTO: "@@operacao/FINALIZAR_UPLOAD_DOCUMENTO",
  DOWNLOAD_DOCUMENTO: "@@operacao/DOWNLOAD_DOCUMENTO",
  DOWNLOAD_TEMPLATE: "@@operacao/DOWNLOAD_TEMPLATE",
  REMOVER_ARQUIVO: "@@operacao/REMOVER_ARQUIVO",
  OBTER_OPERACAO_APROVACAO_MENOR: "@@operacao/OBTER_OPERACAO_APROVACAO_MENOR",
  ATUALIZAR_OPERACAO_APROVACAO_MENOR:
    "@@operacao/ATUALIZAR_OPERACAO_APROVACAO_MENOR",
  DOWNLOAD_TODOS_DOCUMENTOS: "@@operacao/DOWNLOAD_TODOS_DOCUMENTOS",
  ATUALIZAR_PREVISAO_PAGAMENTO: "@@operacao/ATUALIZAR_PREVISAO_PAGAMENTO",
  ATUALIZAR_IMOVEL_DEFINIDO: "@@operacao/ATUALIZAR_IMOVEL_DEFINIDO",
  OBTER_CONTATO_IQ: "@@operacao/OBTER_CONTATO_IQ",
  EXIBIR_ULT_COMENTARIO: "@@operacao/EXIBIR_ULT_COMENTARIO",
  ATUALIZAR_PERMITE_AUTOMACAO: "@@operacao/ATUALIZAR_PERMITE_AUTOMACAO",
  REFRESH: "@@operacao/REFRESH",
};

export const actions = {
  listarOperacoes: createAsyncAction(types.LISTAR),
  obterFiltrosOperacoes: createAsyncAction(types.OBTER_FILTROS_OPERACOES),
  carregarResumo: createAsyncAction(types.RESUMO),
  obterOperacao: createAsyncAction(types.OBTER),
  obterDominiosOperacao: createAsyncAction(types.OBTER_DOMINIOS),
  atualizarResponsavel: createAsyncAction(types.ATUALIZAR_RESPONSAVEL),

  compartilharOperacao: createAsyncAction(types.COMPARTILHAR_OPERACAO),
  exibirDialogCompartilhamento: (show, data) =>
    createSyncAction(types.EXIBIR_DIALOG_COMPARTILHAMENTO, { show, data }),
  exibirDialogAtribuirResponsavel: (show) =>
    createSyncAction(types.EXIBIR_DIALOG_ATRIBUIR_RESPONSAVEL, { show }),

  obterConjuge: createAsyncAction(types.OBTER_CONJUGE),

  obterPreenchimento: createAsyncAction(types.OBTER_PREENCHIMENTO),
  gravarPreenchimento: createAsyncAction(types.GRAVAR_PREENCHIMENTO),
  enviarPreenchimento: createAsyncAction(types.ENVIAR_PREENCHIMENTO),
  adicionarParticipantePreenchimento: createAsyncAction(
    types.ADICIONAR_PARTICIPANTE_PREENCHIMENTO
  ),
  atualizarParticipantePreenchimento: createAsyncAction(
    types.ATUALIZAR_PARTICIPANTE_PREENCHIMENTO
  ),
  removerParticipantePreenchimento: createAsyncAction(
    types.REMOVER_PARTICIPANTE_PREENCHIMENTO
  ),
  refreshPreenchimento: createAsyncAction(types.REFRESH_PREENCHIMENTO),
  iniciarRelacionada: createAsyncAction(types.INICIAR_RELACIONADA),

  obterDadosBancarios: createAsyncAction(types.OBTER_DADOS_BANCARIOS),
  enviarDadosBancarios: createAsyncAction(types.ENVIAR_DADOS_BANCARIOS),

  obterComplemento: createAsyncAction(types.OBTER_COMPLEMENTO),
  gravarComplemento: createAsyncAction(types.GRAVAR_COMPLEMENTO),
  enviarComplemento: createAsyncAction(types.ENVIAR_COMPLEMENTO),
  adicionarParticipanteComplemento: createAsyncAction(
    types.ADICIONAR_PARTICIPANTE_COMPLEMENTO
  ),
  atualizarParticipanteComplemento: createAsyncAction(
    types.ATUALIZAR_PARTICIPANTE_COMPLEMENTO
  ),
  removerParticipanteComplemento: createAsyncAction(
    types.REMOVER_PARTICIPANTE_COMPLEMENTO
  ),
  refreshComplemento: createAsyncAction(types.REFRESH_COMPLEMENTO),

  obterConfirmacaoValores: createAsyncAction(types.OBTER_CONFIRMACAO_VALORES),
  enviarConfirmacaoValores: createAsyncAction(types.ENVIAR_CONFIRMACAO_VALORES),

  obterDocumentos: createAsyncAction(types.OBTER_DOCUMENTOS),
  atualizarDocumentos: createAsyncAction(types.ATUALIZAR_DOCUMENTOS),
  prepararUploadDocumento: createAsyncAction(types.PREPARAR_UPLOAD_DOCUMENTO),
  realizarUploadDocumento: createAsyncAction(types.REALIZAR_UPLOAD_DOCUMENTO),
  finalizarUploadDocumento: createAsyncAction(types.FINALIZAR_UPLOAD_DOCUMENTO),
  downloadDocumento: createAsyncAction(types.DOWNLOAD_DOCUMENTO),
  downloadTodosDocumentos: createAsyncAction(types.DOWNLOAD_TODOS_DOCUMENTOS),
  downloadTemplate: createAsyncAction(types.DOWNLOAD_TEMPLATE),
  removerArquivo: createAsyncAction(types.REMOVER_ARQUIVO),
  obterOperacaoAprovacaoMenor: createAsyncAction(
    types.OBTER_OPERACAO_APROVACAO_MENOR
  ),
  atualizarOperacaoAprovacaoMenor: createAsyncAction(
    types.ATUALIZAR_OPERACAO_APROVACAO_MENOR
  ),
  atualizarPrevisaoPagamento: createAsyncAction(
    types.ATUALIZAR_PREVISAO_PAGAMENTO
  ),
  atualizarImovelDefinido: createAsyncAction(types.ATUALIZAR_IMOVEL_DEFINIDO),
  obterContatoIq: createAsyncAction(types.OBTER_CONTATO_IQ),

  atualizarPermiteAutomacao: createAsyncAction(
    types.ATUALIZAR_PERMITE_AUTOMACAO
  ),

  refresh: createAsyncAction(types.REFRESH),

  exibirUltComentario: (show, data) =>
    createSyncAction(types.EXIBIR_ULT_COMENTARIO, { show, data }),
  trocarPaginaOperacoes: (paginaAtual) =>
    createSyncAction(types.TROCAR_PAGINA_OPERACOES, { paginaAtual }),
};
