import ConfirmacaoValoresPage from "./ConfirmacaoValoresPage";
import { useDispatch, useSelector } from "react-redux";
import {
  getConfirmacaoValores,
  isCarregandoConfirmacaoValores,
  isEnviandoConfirmacaoValores,
} from "../../../selectors/operacao.selectors";
import { useCallback, useEffect } from "react";
import {
  actions as routeActions,
  types as routes,
} from "../../../actions/rotas.actions";
import useCompleteForm from "../../../hooks/useCompleteForm";
import validators from "../../../utils/validators";
import { actions } from "../../../actions/operacao.actions";

const ConfirmacaoValoresPageConnected = () => {
  const dispatch = useDispatch();
  const loading = useSelector(isCarregandoConfirmacaoValores);
  const sending = useSelector(isEnviandoConfirmacaoValores);
  const data = useSelector(getConfirmacaoValores);

  const handleGoBack = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.DETALHES_OPERACAO, {
        id: data?.operacao?.id,
      })
    );
  }, [dispatch, data]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      valorFinanciamento: validators.number({ required: true }),
      prazoDesejado: validators.number({ required: true }),
      seguradora: validators.string({ required: true }),
      prazoCarencia: validators.string({ required: true }),
      tipoAmortizacao: validators.string({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        valorFinanciamento: "",
        prazoDesejado: "",
        seguradora: "",
        prazoCarencia: "",
        tipoAmortizacao: "",
        financiaCustas: false,
        financiaIof: false,
        financiaTarifaEmissao: false,
        financiaTarifaRegistro: false,
        ...data?.operacao?.confirmacaoValores,
      }),
      [data]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.enviarConfirmacaoValores.request({
            instituicao: data.operacao.codigoInstituicao,
            passo: "confirmacao",
            operacao: {
              id: data.operacao.id,
              confirmacaoValores: {
                valorFinanciamento: values.valorFinanciamento,
                financiaCustas: values.financiaCustas,
                financiaIof: values.financiaIof,
                financiaTarifaEmissao: values.financiaTarifaEmissao,
                financiaTarifaRegistro: values.financiaTarifaRegistro,
                prazoDesejado: values.prazoDesejado,
                tipoAmortizacao: values.tipoAmortizacao,
                idSeguradora: values.seguradora,
                prazoCarencia: values.prazoCarencia,
              },
            },
          })
        );
      },
      [dispatch, data]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      valorFinanciamento: "",
      prazoDesejado: "",
      seguradora: "",
      prazoCarencia: "",
      tipoAmortizacao: "",
      financiaCustas: false,
      financiaIof: false,
      financiaTarifaEmissao: false,
      financiaTarifaRegistro: false,
      ...data?.operacao?.confirmacaoValores,
    });
  }, [data, reset]);

  return (
    <ConfirmacaoValoresPage
      loading={loading}
      data={data}
      formProps={formProps}
      handleSubmit={handleSubmit}
      sending={sending}
      tiposAmortizacao={data?.dominios?.tipoAmortizacao}
      seguradoras={data?.dominios?.seguradora}
      carencias={data?.dominios?.carencia}
      handleGoBack={handleGoBack}
    />
  );
};

export default ConfirmacaoValoresPageConnected;
