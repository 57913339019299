import React from "react";
import DadosBasicosDefaultFormConnected from "./DadosBasicosDefaultForm.connected";
import EscolhaParticipanteDefaultFormConnected from "./EscolhaParticipanteDefaultForm.connected";
import DadosProfissionaisDefaultFormConnected from "./DadosProfissionaisDefaultForm.connected";
import DadosEstadoCivilDefaultFormConnected from "./DadosEstadoCivilDefaultForm.connected";

const FluxoDefault = (props) => {
  return (
    <>
      {props.step === 0 && (
        <EscolhaParticipanteDefaultFormConnected {...props} />
      )}
      {props.step === 1 && <DadosBasicosDefaultFormConnected {...props} />}
      {props.step === 2 && (
        <DadosProfissionaisDefaultFormConnected {...props} />
      )}
      {props.step === 3 && <DadosEstadoCivilDefaultFormConnected {...props} />}
    </>
  );
};

export default FluxoDefault;
