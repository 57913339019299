import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  styled,
  Switch as MSwitch,
} from "@mui/material";
import { Tooltip } from "../index";
import { HelpOutline } from "@mui/icons-material";

const StyledFormControl = styled(FormControl)`
  margin-top: 0.25rem;
  margin-bottom: 0;
  text-align: left;

  & .MuiFormLabel-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme, size = "normal" }) =>
      theme.fonts.size.inputs[size].label};
    font-weight: 400;
  }
  & .MuiFormControlLabel-label {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme, size = "normal" }) =>
      theme.fonts.size.inputs[size].label};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray._300};
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.gray._800};
  }
  & .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error._500};
  }
  & .MuiInputBase-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme, size = "normal" }) =>
      theme.fonts.size.inputs[size].content};
    font-weight: 400;
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.error._500};
  }
  & .MuiFormControlLabel-root {
    margin-right: 4px;
    margin-left: 0;
  }
  & .MuiSwitch-root {
    height: 24px;
    padding: 0;
    border-radius: 12px;
    width: 44px;
  }
  & .MuiFormControlLabel-labelPlacementTop .MuiFormControlLabel-label {
    margin-left: 0.25rem;
  }
  & .MuiFormControlLabel-labelPlacementTop .MuiSwitch-root {
    margin-top: 0.25rem;
  }
  & .MuiFormControlLabel-labelPlacementEnd .MuiSwitch-root {
    margin-right: 0.5rem;
  }
  & .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementTop {
    align-items: start;
  }
  & .MuiSwitch-switchBase {
    padding: 2px;
  }
`;
const HelpButton = styled(IconButton)`
  padding: 0;
  margin: -3px 0 0 4px;
  color: ${({ theme }) => theme.colors.gray._300};
  & .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.fonts.size.default};
  }
`;

const Switch = ({
  value,
  label,
  disabled,
  helperText,
  tooltip,
  fullWidth = true,
  error,
  labelPlacement = "top",
  ...otherProps
}) => {
  if (!!tooltip) {
    label = (
      <>
        {label}
        <Tooltip arrow title={tooltip}>
          <HelpButton>
            <HelpOutline />
          </HelpButton>
        </Tooltip>
      </>
    );
  }

  return (
    <StyledFormControl fullWidth={fullWidth}>
      <FormControlLabel
        control={
          <MSwitch
            {...otherProps}
            value={true}
            checked={value}
            disabled={disabled}
          />
        }
        labelPlacement={labelPlacement}
        label={label}
      />
      {Boolean(error) && Boolean(helperText) && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </StyledFormControl>
  );
};

export default Switch;
