import React, { useState } from "react";
import { IconButton, Tooltip } from "../../../components";
import { actions } from "../../../actions/workflow.actions";
import { Edit } from "@mui/icons-material";
import { Grid, styled } from "@mui/material";
import Field from "../../../components/presentation/Field";
import formatters from "../../../utils/formatters";
import { transformFromDate } from "../../../utils/basic";
import { FORMA_CORRECAO } from "../../../constants/generic.constants";
import useResponsive from "../../../hooks/useResponsive";

const SeeMorePill = styled("div")`
  cursor: pointer;
  margin: 1rem auto 0;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.gray._100};
  font-size: ${({ theme }) => theme.fonts.size.cliente.card.cpfCnpj};
  font-weight: 300;
  text-align: ${({ _align_ }) => _align_ ?? "left"};
  color: ${({ theme }) => theme.colors.gray._800};
  padding: 0.2rem 1rem;
  width: fit-content;
`;
const EditIconButton = styled(IconButton)`
  margin-left: 0.5rem;
  padding: 0;
  margin-top: -0.25rem;
  color: ${({ theme }) => theme.colors.secondary._500};

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.gray._100};
  }
  & .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;

const DetalhesOperacaoBlock = ({
  data,
  dominios,
  perfil,
  handleClickEditValues,
}) => {
  const currentSize = useResponsive();

  const [supress, setSupress] = useState(true);
  const isMobile = currentSize === "xs" || currentSize === "sm";

  const editValues = null;
  // perfil === "OPERACAO" && !data.dataHoraEncerramento ? (
  //   <Tooltip arrow title={"Alterar valores da proposta"}>
  //     <EditIconButton
  //       loading={false}
  //       size={"small"}
  //       loaderColor={"gray"}
  //       onClick={() =>
  //         handleClickEditValues({
  //           action: actions.atualizarNumeroProposta.request,
  //           contexto: {
  //             id_operacao: data.id,
  //           },
  //           callback: () => {
  //             window.location.reload();
  //           },
  //         })
  //       }
  //     >
  //       <Edit />
  //     </EditIconButton>
  //   </Tooltip>
  // ) : null;

  if (supress) {
    if (isMobile) {
      return (
        <>
          <Grid id={"details"} container spacing={2} alignItems={"center"}>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={
                  data.tipoProduto === "H"
                    ? "Valor do Empréstimo"
                    : "Valor do Financiamento"
                }
                value={data.detalhe.valorFinanciamento}
                type={"currency"}
                addon={editValues}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={"Valor do Imóvel"}
                value={data.detalhe.valorImovel}
                type={"currency"}
              />
            </Grid>
          </Grid>
          <SeeMorePill id={"verMais"} onClick={() => setSupress(!supress)}>
            Ver Mais
          </SeeMorePill>
        </>
      );
    } else {
      return (
        <>
          <Grid id={"details"} container spacing={2} alignItems={"center"}>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={
                  data.tipoProduto === "H"
                    ? "Valor do Empréstimo"
                    : "Valor do Financiamento"
                }
                value={data.detalhe.valorFinanciamento}
                type={"currency"}
                addon={editValues}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={"Valor do Imóvel"}
                value={data.detalhe.valorImovel}
                type={"currency"}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={"Portabilidade?"}
                value={data.detalhe.portabilidade}
                type={"boolean"}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={"Prazo Solicitado"}
                value={data.detalhe.prazoDesejado}
                suffix={" meses"}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={"Tipo de Amortização"}
                value={data.detalhe.tipoAmortizacao}
                type={"lookup"}
                domain={{
                  data: [
                    { id: "S", descricao: "SAC" },
                    { id: "P", descricao: "PRICE" },
                  ],
                  filter: (x, v) => x.id === v,
                  map: (x) => x.descricao,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={"Taxa de Juros"}
                value={
                  data.tipoProduto === "H"
                    ? `${formatters.numbers.currency(
                        data.detalhe.taxaJuros
                      )}% a.a. (${formatters.numbers.currency(
                        data.detalhe.taxaJurosMensal
                      )}% a.m.)`
                    : `${formatters.numbers.currency(
                        data.detalhe.taxaJuros
                      )}% a.a.`
                }
              />
            </Grid>
          </Grid>
          <SeeMorePill id={"verMais"} onClick={() => setSupress(!supress)}>
            Ver Mais
          </SeeMorePill>
        </>
      );
    }
  }

  return (
    <>
      <Grid id={"details"} container spacing={2} alignItems={"center"}>
        <Grid item xs={12} md={4} lg={2}>
          <Field
            label={
              data.tipoProduto === "H"
                ? "Valor do Empréstimo"
                : "Valor do Financiamento"
            }
            value={data.detalhe.valorFinanciamento}
            type={"currency"}
            addon={editValues}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field
            label={"Valor do Imóvel"}
            value={data.detalhe.valorImovel}
            type={"currency"}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field
            label={"Portabilidade?"}
            value={data.detalhe.portabilidade}
            type={"boolean"}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field
            label={"Prazo Solicitado"}
            value={data.detalhe.prazoDesejado}
            suffix={" meses"}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field
            label={"Tipo de Amortização"}
            value={data.detalhe.tipoAmortizacao}
            type={"lookup"}
            domain={{
              data: [
                { id: "S", descricao: "SAC" },
                { id: "P", descricao: "PRICE" },
              ],
              filter: (x, v) => x.id === v,
              map: (x) => x.descricao,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field
            label={"Taxa de Juros"}
            value={
              data.tipoProduto === "H"
                ? `${formatters.numbers.currency(
                    data.detalhe.taxaJuros
                  )}% a.a. (${formatters.numbers.currency(
                    data.detalhe.taxaJurosMensal
                  )}% a.m.)`
                : `${formatters.numbers.currency(data.detalhe.taxaJuros)}% a.a.`
            }
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field
            label={"Validade do Crédito"}
            value={
              data.detalhe.validadeCredito
                ? transformFromDate(data.detalhe.validadeCredito)
                : "-"
            }
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field
            label={"Situação do Crédito"}
            value={data.detalhe.situacaoCredito}
            type={"lookup"}
            domain={{
              data: [
                { id: "N", descricao: "Não Aprovado" },
                { id: "V", descricao: "Vencido" },
                { id: "G", descricao: "Vigente" },
              ],
              filter: (x, v) => x.id === v,
              map: (x) => x.descricao,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field
            label={"Correção do Contrato"}
            value={FORMA_CORRECAO[data.detalhe.tipoRegra]}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field label={"Oferta"} value={data.detalhe.oferta} />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field label={"Seguradora"} value={data.detalhe.seguradora} />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field
            label={"Prazo de Carência"}
            value={data.detalhe.prazoCarencia}
            type={"decode"}
            decode={(c) => {
              if (c === 0) {
                return "Sem carência";
              }
              if (c === 1) {
                return "1 mês";
              }
              return `${c} meses`;
            }}
          />
        </Grid>
        {data.tipoProduto !== "H" && (
          <>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={"Financia Despesas de ITBI e Registro?"}
                value={data.detalhe.financiaCustas}
                type={"boolean"}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={"Despesas de ITBI e Registro"}
                value={data.detalhe.valorCustas}
                type={"currency"}
              />
            </Grid>
          </>
        )}
        {data.detalhe.calculaIof && (
          <>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={"Financia IOF?"}
                value={data.detalhe.financiaIof}
                type={"boolean"}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={"Valor IOF"}
                value={data.detalhe.valorIof}
                type={"currency"}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={4} lg={2}>
          <Field
            label={"Financia Tarifa de Avaliação?"}
            value={data.detalhe.financiaTarifaEmissao}
            type={"boolean"}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field
            label={"Valor Tarifa de Avaliação"}
            value={data.detalhe.valorTarifaEmissao}
            type={"currency"}
          />
        </Grid>
        {data.tipoProduto === "H" && (
          <>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={"Financia Tarifa de Emissão do Contrato?"}
                value={data.detalhe.financiaTarifaRegistro}
                type={"boolean"}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Field
                label={"Valor Tarifa de Emissão"}
                value={data.detalhe.valorTarifaRegistro}
                type={"currency"}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={4} lg={2}>
          <Field
            label={
              data.tipoProduto === "H"
                ? "Valor do Empréstimo Líquido"
                : "Valor do Financiamento Líquido"
            }
            value={data.detalhe.valorBase}
            type={"currency"}
          />
        </Grid>
        {data.participantes.filter((p) => p.utilizaFgts).length > 0 && (
          <Grid item xs={12} md={4} lg={2}>
            <Field
              label={"Valor de FGTS"}
              value={data.detalhe.valorFgts}
              type={"currency"}
            />
          </Grid>
        )}
        {data.tipoProduto === "I" && (
          <Grid item xs={12} md={4} lg={2}>
            <Field
              label={"Valor da entrada"}
              value={
                data.detalhe.valorImovel -
                data.detalhe.valorFgts -
                data.detalhe.valorBase
              }
              type={"currency"}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4} lg={2}>
          <Field
            force
            label={"Utilização do Imóvel"}
            type={"lookup"}
            value={data.imovel?.tipoImovel}
            domain={{
              data: dominios?.tipoImovel,
              filter: (x, v) => x.id === v,
              map: (x) => x?.nome,
            }}
          />
        </Grid>
      </Grid>
      <SeeMorePill id={"verMais"} onClick={() => setSupress(!supress)}>
        Esconder
      </SeeMorePill>
    </>
  );
};

export default DetalhesOperacaoBlock;
