import CommonPage from "../CommonPage";
import { Card, Title } from "../../components";
import {
  Alert,
  Grid,
  IconButton,
  styled,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {
  BodyTableRow,
  SizedTableCell,
  Table,
  TablePagination,
} from "../../components/tables";
import formatters from "../../utils/formatters";
import React from "react";
import { logos } from "../../constants/generic.constants";
import { HorizontalLoader } from "../../components/loaders";
import { Home, AttachMoney, Chat, FilterAlt, InfoOutlined, Error, ErrorOutline } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import FiltrosOperacoes from "./FiltrosOperacoes";

const CardHeader = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
const Logo = styled("img")`
  width: 50px;
  height: 50px;
  border-radius: 0.5rem;
  object-fit: contain;
  display: block;
  margin-right: 1rem;
`;
const PagingText = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 300;
`;
const ActionButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.primary._75};
  color: ${({ theme, active }) => active ? theme.colors.primary._500 : theme.colors.gray._200};
  border-radius: 0.5rem;
  margin-left: auto;
  transition: 0.1s;
  &:hover {
    transition: 0.1s;
    color: ${({ theme, active }) => active ? theme.colors.primary._600 : theme.colors.gray._300};
    background-color: ${({ theme }) => theme.colors.primary._100};
  }
`;
const NormalText = styled("span")`
  font-weight: 300;
`
const BoldText = styled("span")`
  font-weight: 700;
`
const AlertText = styled("span")`
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 3px;
  color: ${({ theme }) => theme.colors.error._600};
`
const Icon = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 100%;
  margin-right: 5px;
  background-color: ${({ theme }) => theme.colors.gray._100};
  &:hover{
    background-color: ${({ theme, onClick }) => onClick ? theme.colors.gray._200 : theme.colors.gray._100};
    transition: 0.1s;
  }
`
const StyledIcon = styled("span")`
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.secondary._700};
  ${({ theme, size }) => `${theme.breakpoints.up("md")} {
    font-size: ${size};
  }`}
`;

const FlexDiv = styled("div")`
  display: flex;
  align-items: center;
  margin: 5px 0px 5px 0px;
`

const ListaOperacoesPage = ({
  loading,
  data,
  showFilters,
  setShowFilters,
  filtros,
  exibeResponsavel,
  formProps,
  handleOperacaoClick,
  handleOpenUltComentarioDialog,
  handleFetchData,
  handleSubmit,
  hasMore,
  totalElementos,
  orderBy,
  order,
  handleSort
}) => {
  return (
    <CommonPage selected={"operacoes"} responsive={true}>
      <Grid>
        <Card>
          <FiltrosOperacoes
            formProps={formProps}
            open={showFilters}
            filtros={filtros}
            exibeResponsavel={exibeResponsavel}
            onClose={() => {
              setShowFilters(false)
              formProps.reset()
            }}
            handleFetchData={handleFetchData}
            handleSubmit={handleSubmit}
          />
          <CardHeader>
            <Title as={"h2"}>Operações</Title>
            <Grid>
              <ActionButton active="active" onClick={() => setShowFilters(true)}>
                <FilterAlt />
              </ActionButton>
            </Grid>
          </CardHeader>
          <TableContainer sx={{ height: "calc(100vh - 230px)" }} id="tableBody">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <SizedTableCell style={{ minWidth: 60 }}>Banco</SizedTableCell>
                  <SizedTableCell style={{ minWidth: 110 }} >
                    <TableSortLabel
                      active={orderBy === "id_operacao"}
                      direction={orderBy === "id_operacao" ? order : 'asc'}
                      onClick={() => handleSort("id_operacao")}
                    >
                      Produto
                    </TableSortLabel>
                  </SizedTableCell>
                  <SizedTableCell style={{ minWidth: 100 }} >
                    <TableSortLabel
                      active={orderBy === "nome_cliente"}
                      direction={orderBy === "nome_cliente" ? order : 'asc'}
                      onClick={() => handleSort("nome_cliente")}
                    >
                      Cliente
                    </TableSortLabel>
                  </SizedTableCell>
                  <SizedTableCell style={{ minWidth: 180 }} >
                    <TableSortLabel
                      active={orderBy === "valor_financiamento"}
                      direction={orderBy === "valor_financiamento" ? order : 'asc'}
                      onClick={() => handleSort("valor_financiamento")}
                    >
                      Valores
                    </TableSortLabel>
                  </SizedTableCell>
                  <SizedTableCell style={{ minWidth: 140 }} >
                    <TableSortLabel
                      active={orderBy === "situacao"}
                      direction={orderBy === "situacao" ? order : 'asc'}
                      onClick={() => handleSort("situacao")}
                    >
                      Situação
                    </TableSortLabel>
                  </SizedTableCell>
                  <SizedTableCell style={{ minWidth: 190 }} >
                    <TableSortLabel
                      active={orderBy === "qtd_dias_sem_atualizacao"}
                      direction={orderBy === "qtd_dias_sem_atualizacao" ? order : 'asc'}
                      onClick={() => handleSort("qtd_dias_sem_atualizacao")}
                    >
                      Ult. Atualização
                    </TableSortLabel>
                  </SizedTableCell>
                  <SizedTableCell style={{ minWidth: 110 }} >
                    <TableSortLabel
                      active={orderBy === "responsavel"}
                      direction={orderBy === "responsavel" ? order : 'asc'}
                      onClick={() => handleSort("responsavel")}
                    >
                      Responsável
                    </TableSortLabel>
                  </SizedTableCell>
                  <SizedTableCell style={{ minWidth: 70 }}></SizedTableCell>
                </TableRow >
              </TableHead >
              <TableBody>
                {data.map((operacao, index) => {
                  return (
                    <BodyTableRow key={index} index={index}
                      onClick={() => handleOperacaoClick(operacao.idOperacao)}
                    >
                      <SizedTableCell>
                        <Logo src={logos[operacao.codigoInstituicao]} />
                      </SizedTableCell>
                      <SizedTableCell>
                        <BoldText>N° {operacao.idOperacao}</BoldText><br />
                        <NormalText>{operacao.produto}</NormalText>
                      </SizedTableCell>
                      <SizedTableCell>
                        <BoldText>{operacao.nomeCliente}</BoldText><br />
                        <NormalText>{formatters.strings.cpfCnpj(operacao.cpfCnpj)}</NormalText>
                      </SizedTableCell>
                      <SizedTableCell>
                        <FlexDiv>
                          <Icon width={"1.2rem"} height={"1.2rem"}>
                            <StyledIcon as={Home} size="1.1rem" />
                          </Icon>
                          <BoldText> R$ {formatters.numbers.currency(operacao.valorImovel)}<br /></BoldText>
                        </FlexDiv>
                        <FlexDiv>
                          <Icon width={"1.2rem"} height={"1.2rem"}>
                            <StyledIcon as={AttachMoney} size="1.1rem" />
                          </Icon>
                          <BoldText> R$ {formatters.numbers.currency(operacao.valorFinanciamento)}</BoldText>
                        </FlexDiv>
                      </SizedTableCell>
                      <SizedTableCell>
                        <BoldText>{operacao.situacao}<br /></BoldText>
                        <NormalText>{operacao.nomeFase}<br /></NormalText>
                        {operacao.pendencia &&
                          <AlertText>
                            <ErrorOutline sx={{fontSize: 15}}/>
                            Possui pendência
                          </AlertText>
                        }
                      </SizedTableCell>
                      <SizedTableCell>
                        <BoldText>
                          {operacao.dataHoraAtualizacao && formatters.dates.simple(
                            new Date(operacao.dataHoraAtualizacao),
                            "dd/MM/yyyy - HH:mm:ss z"
                          )}<br />
                        </BoldText>
                        <NormalText>{operacao.qtdDiasSemAtualizacao} dias sem atualização</NormalText>
                      </SizedTableCell>
                      <SizedTableCell>
                        <BoldText>{operacao.nomeParceiro}<br /></BoldText>
                        <NormalText>{operacao.responsavel}</NormalText>
                      </SizedTableCell>
                      <SizedTableCell>
                        <ActionButton
                          active={operacao?.dsUltComentario}
                          onClick={(e) => handleOpenUltComentarioDialog(e, operacao)}
                        >
                          <Chat />
                        </ActionButton>
                      </SizedTableCell>
                    </BodyTableRow>
                  );
                })}
                {loading &&
                  <BodyTableRow index={0}>
                    <td>
                      <HorizontalLoader color={"brand"} size={40} />
                    </td>
                  </BodyTableRow>
                }
              </TableBody>
            </Table >
          </TableContainer>
          <InfiniteScroll
            dataLength={data.length}
            next={handleFetchData}
            hasMore={!!data.length && hasMore}
            scrollableTarget="tableBody"
          />
          <Grid item marginTop={1}>
            <PagingText>Exibindo {data.length} de {totalElementos} registro(s)</PagingText>
          </Grid>
        </Card >
      </Grid >
    </CommonPage >
  );
};

export default ListaOperacoesPage;
