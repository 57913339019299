import DetalhesOperacaoBlock from "./DetalhesOperacaoBlock";
import { useSelector } from "react-redux";
import {
  getDetalhesOperacao,
  getDominiosOperacao,
} from "../../../selectors/operacao.selectors";
import { getPerfil } from "../../../selectors/usuario.selectors";

const DetalhesOperacaoBlockConnected = () => {
  const data = useSelector(getDetalhesOperacao);
  const dominios = useSelector(getDominiosOperacao);
  const perfil = useSelector(getPerfil);

  return (
    <DetalhesOperacaoBlock data={data} perfil={perfil} dominios={dominios} />
  );
};

export default DetalhesOperacaoBlockConnected;
