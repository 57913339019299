import { Accordion as MAccordion, styled } from "@mui/material";
import { transientOptions } from "../../styles/theme";

const DetailsAccordion = styled(MAccordion, transientOptions)`
  & .MuiAccordionSummary-root {
    padding: 0;
  }
  & .MuiAccordionSummary-content {
    margin: 0;
  }
  & .MuiAccordionDetails-root {
    padding: 0.5rem 0 1rem;
  }
  &:not(:first-of-type):before {
    background-color: ${({ theme }) => theme.colors.gray._50};
  }
`;

export default DetailsAccordion;
