import React from "react";
import DadosBasicosCompradorLibraFormConnected from "./comprador/DadosBasicosCompradorLibraForm.connected";
import HubLibraConnected from "./HubLibra.connected";
import DadosEnderecoImovelLibraFormConnected from "./imovel/DadosEnderecoImovelLibraForm.connected";
import OutrasInformacoesImovelLibraFormConnected from "./imovel/OutrasInformacoesImovelLibraForm.connected";
import DadosProfissionaisLibraFormConnected from "./comprador/DadosProfissionaisLibraForm.connected";
import DadosEstadoCivilLibraFormConnected from "./comprador/DadosEstadoCivilLibraForm.connected";

const FluxoLibra = (props) => {
  return (
    <>
      {props.step === 0 && <HubLibraConnected {...props} />}
      {props.step === 1 && (
        <DadosBasicosCompradorLibraFormConnected {...props} />
      )}
      {props.step === 2 && <DadosProfissionaisLibraFormConnected {...props} />}
      {props.step === 3 && <DadosEstadoCivilLibraFormConnected {...props} />}

      {props.step === 100 && (
        <DadosEnderecoImovelLibraFormConnected {...props} />
      )}
      {props.step === 101 && (
        <OutrasInformacoesImovelLibraFormConnected {...props} />
      )}
    </>
  );
};

export default FluxoLibra;
