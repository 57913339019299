import React, { useCallback, useEffect, useMemo } from "react";
import DadosEnderecoImovelInterForm from "./DadosEnderecoImovelInterForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";

const DadosEnderecoImovelInterFormConnected = ({
  makeGoTo,
  eventsImovel,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacao, validacao } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const imovel = useMemo(
    () => ({
      ...operacao.imovel,
      fluxo: validacao.completa.imovel,
    }),
    [operacao, validacao]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      endereco: {
        municipio: validators.string({ required: true, maxLength: 60 }),
        uf: validators.string({ required: true }),
      },
    }),
    initialValues: useCallback(
      () => ({
        ...imovel,
        endereco: {
          municipio: "",
          uf: "",
          ...imovel.endereco,
        },
      }),
      [imovel]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback: makeGoTo(0),
            instituicao: operacao.codigoInstituicao,
            passo: "dados-endereco-imovel",
            operacao: {
              id: operacao.id,
              imovel: {
                endereco: {
                  municipio: values.endereco.municipio,
                  uf: values.endereco.uf,
                },
              },
            },
          })
        );
      },
      [dispatch, operacao, makeGoTo]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      ...imovel,
      endereco: {
        municipio: "",
        uf: "",
        ...imovel.endereco,
      },
    });
  }, [imovel, reset]);

  return (
    <DadosEnderecoImovelInterForm
      {...otherProps}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={imovel.fluxo}
      makeGoTo={makeGoTo}
      events={eventsImovel}
    />
  );
};

export default DadosEnderecoImovelInterFormConnected;
