import CadastroParceiroForm from "./CadastroParceiroForm";
import { Grid, styled } from "@mui/material";
import { Card, Text, Skeleton } from "../../components";
import SplitPanePage from "../SplitPanePage";
import logo from "../../assets/logo.png";

const ScaledLogo = styled("img")`
  max-height: 64px;
  width: auto;
  max-width: 240px;
  object-fit: contain;
`;
const PoweredBy = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray._200};
  text-align: right;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
`;

const CadastroParceiroPage = (props) => {
  return (
    <SplitPanePage
      xl={4}
      photo={`https://cdn.imobiliario.desenvolvimento.taya.com.br/home_v3.jpg`}
    >
      <Grid container direction={"column"} justifyContent={"center"} px={3}>
        <Grid item>
          <Card>
            <Grid
              direction={"column"}
              alignItems="center"
              justifyContent="center"
              container
            >
              <Grid item container direction={"column"} alignItems={"center"}>
                {!!props.loading && (
                  <Skeleton variant={"rounded"} width={200} height={64} />
                )}
                {!props.loading && (
                  <Grid flexDirection={"column"}>
                    <ScaledLogo src={props.urlLogoParceiro ?? logo} />
                    {!!props.urlLogoParceiro && (
                      <PoweredBy>powered by Taya</PoweredBy>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                {!!props.loading && (
                  <Skeleton
                    variant={"text"}
                    width={260}
                    height={30}
                    _margin_={"1rem 0"}
                  />
                )}
                {!props.loading && (
                  <Text _margin_={"1rem 0 1rem 0"}>
                    Cadastre-se para ser nosso parceiro.
                  </Text>
                )}
              </Grid>
              <CadastroParceiroForm {...props} />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </SplitPanePage>
  );
};

export default CadastroParceiroPage;
