import React from "react";
import { IconButton as MIconButton } from "@mui/material";
import { HorizontalLoader } from "../loaders";

const IconButton = React.forwardRef(
  (
    {
      children,
      loading,
      disabled,
      loaderColor = "gray",
      loaderSize = 24,
      ...otherProps
    },
    ref
  ) => (
    <MIconButton {...otherProps} ref={ref} disabled={disabled || !!loading}>
      {!!loading ? (
        <HorizontalLoader color={loaderColor} size={loaderSize} />
      ) : (
        children
      )}
    </MIconButton>
  )
);

export default IconButton;
