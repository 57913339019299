import React from "react";
import { Dialog, DialogContent, Grid, IconButton, styled } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { Title } from "../../components";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const NOME_GRUPO = {
  P: "Parceiro  ",
  O: "Operação",
  C: "Compradores",
  V: "Vendedores",
  I: "Imóvel",
};

const DocumentosContainer = styled("div")``;
const ItemContainer = styled(Grid)`
  border: 1px solid ${({ theme }) => theme.colors.gray._100};
  border-radius: 1rem;
  padding: 1rem;
`;
const DocumentoContainer = styled("div")`
  &:not(:first-of-type) {
    padding-top: 1rem;
  }
  &:not(:last-of-type) {
    padding-bottom: 1rem;
  }

  & + & {
    border-top: 1px dashed ${({ theme }) => theme.colors.gray._100};
  }
`;
const NomeDocumento = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 800;
  color: ${({ theme }) => theme.colors.secondary._700};
  margin-bottom: 0.5rem;
`;
const Descricao = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray._500};
  margin-bottom: 0.5rem;
`;

const Documento = ({ data }) => {
  return (
    <DocumentoContainer>
      <Grid
        container
        mb={1}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item>
          <Grid container columnSpacing={1}>
            <Grid item>
              <NomeDocumento>{data.nome}</NomeDocumento>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Descricao>{data.descricao}</Descricao>
    </DocumentoContainer>
  );
};

const Item = ({ data, ...other }) => {
  return (
    <Grid item xs={12}>
      <ItemContainer>
        <Title as={"h5"} _color_={"gray"} _margin_={"0 0 0.25rem 0"}>
          {data.nome}
        </Title>
        <DocumentosContainer>
          {data.documentos.map((documento) => (
            <Documento key={documento.id} data={documento} {...other} />
          ))}
        </DocumentosContainer>
      </ItemContainer>
    </Grid>
  );
};

const Grupos = ({ data, ...other }) => {
  return data.map((grupo) => (
    <Grid item xs={12} key={grupo.tipo}>
      <Title as={"h4"} _margin_={"0 0 1rem 0"}>
        {NOME_GRUPO[grupo.tipo]}
      </Title>
      <Grid container spacing={3}>
        {grupo.itens.map((item) => (
          <Item key={item.id + item.tipo} data={item} {...other} />
        ))}
      </Grid>
    </Grid>
  ));
};

const DocumentosPendentesDialog = ({ handleClose, data, open }) => {
  return (
    <StyledDialog
      onClose={() => {}}
      disableEscapeKeyDown
      maxWidth="sm"
      open={open}
    >
      <DialogContent>
        <CloseIconButton onClick={handleClose}>
          <CloseSharp />
        </CloseIconButton>
        <Title as={"h2"} _margin_={"0 0 1rem 0"}>
          Pendências para Encerramento da etapa
        </Title>
        <Grid container spacing={2}>
          <Grupos data={data} />
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

export default DocumentosPendentesDialog;
