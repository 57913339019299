import React from "react";
import {
  IconButton,
  styled,
  RadioGroup as MRadioGroup,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import { transientOptions } from "../../styles/theme";
import { HelpOutline } from "@mui/icons-material";
import { Tooltip } from "../index";

const StyledRadioGroupContainer = styled(FormControl, transientOptions)`
  margin-top: ${({ label }) => (!!label ? "2rem" : "0")};

  & legend {
    width: 0;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  & .MuiFormGroup-root {
    gap: 1rem;
  }

  & .MuiFormLabel-root {
    margin: -1rem 0 -4px 4px;
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme, size = "normal" }) =>
      theme.fonts.size.inputs[size].label};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray._300};
    transform: translateY(-0.75rem) scale(1);
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.gray._800};
  }
  & .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error._500};
  }

  & .MuiFormHelperText-root {
    margin: 4px 0 0 4px;
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.error._500};
  }

  & .MuiInputAdornment-root .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.gray._900};
  }
  & .MuiInputBase-root.Mui-error .MuiInputAdornment-root .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.error._500};
  }
`;

const HelpButton = styled(IconButton)`
  padding: 0;
  margin: -3px 0 0 4px;
  color: ${({ theme }) => theme.colors.gray._300};
  & .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.fonts.size.default};
  }
`;

const RadioGroup = ({
  shrinked = true,
  fullWidth = true,
  label,
  tooltip,
  helperText,
  error,
  children,
  ...other
}) => {
  if (!!tooltip) {
    label = (
      <>
        {label}
        <Tooltip arrow title={tooltip} tabIndex={-1}>
          <HelpButton>
            <HelpOutline />
          </HelpButton>
        </Tooltip>
      </>
    );
  }

  return (
    <StyledRadioGroupContainer
      fullWidth={fullWidth}
      label={label}
      error={error}
    >
      <FormLabel>{label}</FormLabel>
      <MRadioGroup {...other}>{children}</MRadioGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </StyledRadioGroupContainer>
  );
};

export default RadioGroup;
