import { Grid, IconButton, styled } from "@mui/material";
import React from "react";
import { Button, Card, Title, Tooltip } from "../../components";
import theme, { transientOptions } from "../../styles/theme";
import { ArrowBack, Info } from "@mui/icons-material";
import Switch from "../../components/inputs/Switch";
import Stepper from "../operacao/preenchimento/Stepper";
import { logos } from "../../constants/generic.constants";
import { minutesToHoursOrDays } from "../../utils/basic";

const Logo = styled("img")`
  width: 64px;
  height: 64px;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray._100};
`;
const GoBackButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.gray._300};
  & .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.fonts.size.large};
  }

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    & .MuiSvgIcon-root {
			font-size: ${theme.fonts.size.xlarge};
		}
  }`}
`;
const TitleText = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.gray._300};
  font-weight: 700;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    font-size: ${theme.fonts.size.large};
  }`}
`;
const NomeInstituicao = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray._700};
`;
const NomeCondicao = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secondary._700};
`;
const RegraContainer = styled("div")`
  &:not(:first-of-type) {
    padding-top: 1rem;
  }
  &:not(:last-of-type) {
    padding-bottom: 1rem;
  }

  & + & {
    border-top: dashed 1px ${({ theme }) => theme.colors.gray._100};
  }
`;
const DisclaimerContainer = styled("div", transientOptions)`
  border-radius: ${({ theme }) => theme.borderRadiuses.disclaimer};
  border: 1px dashed
    ${({ theme, _color_ = "secondary" }) => theme.colors[_color_]._700};
  background-color: ${({ theme, _color_ = "primary" }) =>
    theme.colors[_color_]._100};
  color: ${({ theme, _color_ = "secondary" }) => theme.colors[_color_]._600};
  padding: 1rem 1.5rem;
  margin: ${({ _margin_ = "0.75rem 0 0" }) => _margin_};
`;
const Disclaimer = styled("p", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme, _size_ = "small" }) => theme.fonts.size[_size_]};
  font-weight: 500;
  color: inherit;
  margin: 0;
  white-space: pre-wrap;
`;
const Text = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.gray._400};
  text-align: ${({ _align_ = "left" }) => _align_};
  margin: ${({ _margin_ = "0" }) => _margin_};
`;

const RegraSelecionada = ({
  index,
  correntista,
  handleChangeCorrentista,
  handleStart,
  submitting,
  ...data
}) => {
  return (
    <RegraContainer>
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2} alignItems={"center"}>
          <Grid
            item
            container
            xs={12}
            md={4}
            lg={3}
            spacing={2}
            alignItems={"center"}
          >
            <Grid item>
              <Logo src={logos[data.codigoInstituicao]} />
            </Grid>
            <Grid item display={"flex"} flexDirection={"column"}>
              <NomeInstituicao>{data.nomeInstituicao}</NomeInstituicao>
              <NomeCondicao>{data.nomeCondicao}</NomeCondicao>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            lg={7}
            spacing={2}
            alignItems={"center"}
          >
            <Grid
              item
              xs={12}
              maxWidth={"100%"}
              display={"flex"}
              flexDirection={"column"}
            >
              {!data.habilitada && (
                <DisclaimerContainer _color_={"error"}>
                  <Disclaimer>
                    Você já possui uma proposta em andamento nesta instituição.
                  </Disclaimer>
                </DisclaimerContainer>
              )}
              {!!data.habilitada && !!data.possuiPropostas && (
                <DisclaimerContainer
                  _color_={"gray"}
                  _margin_={"0.5rem 0 0.75rem"}
                >
                  <Disclaimer>
                    Você já possui uma proposta em andamento nesta
                    instituição.&nbsp;
                    <b>Deseja continuar?</b>
                  </Disclaimer>
                </DisclaimerContainer>
              )}
              {!!data.fluxoSimplificado && !!data.habilitada && (
                <Switch
                  label={`É correntista ${data.nomeInstituicao}?`}
                  labelPlacement={"end"}
                  value={correntista[index]}
                  onChange={() => handleChangeCorrentista(index)}
                />
              )}
              {!data.fluxoSimplificado && !!data.habilitada && (
                <Title as={"small"} _color_={"gray"}>
                  Esta instituição não possui fluxo simplificado de contratação
                </Title>
              )}
              {!!data.aceitaApenasCorrentistas &&
                !!data.habilitada &&
                !correntista[index] && (
                  <DisclaimerContainer _color_={"error"}>
                    <Disclaimer>
                      No momento, esta instituição não está aceitando clientes
                      que não sejam correntistas. Caso queira continuar o
                      processo com esta instituição, abra uma conta e depois
                      retorne aqui para continuar.
                    </Disclaimer>
                  </DisclaimerContainer>
                )}
              {!!data.habilitada &&
                (!data.aceitaApenasCorrentistas || correntista[index]) && (
                  <DisclaimerContainer>
                    <Title
                      as={"small"}
                      _margin_={"0 0 1rem 0"}
                      _display_={"block"}
                    >
                      Etapas necessárias para envio para crédito
                    </Title>
                    <Stepper
                      current={-1}
                      backgroundColor={theme.colors.primary._100}
                      fluxo={
                        !!correntista[index]
                          ? data.fluxo.correntista
                          : data.fluxo.completo
                      }
                    />
                    <Text
                      _margin_={"1rem 0 0 0"}
                      _display_={"block"}
                      _color_={"gray"}
                    >
                      Tempo estimado de resposta:{" "}
                      <b>{minutesToHoursOrDays(data.tempoResposta)}</b>
                    </Text>
                  </DisclaimerContainer>
                )}
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {!!data.habilitada &&
              (!data.aceitaApenasCorrentistas || correntista[index]) && (
                <Button
                  loading={submitting}
                  onClick={() => handleStart(data.id)}
                >
                  COMEÇAR
                </Button>
              )}
          </Grid>
        </Grid>
      </Grid>
    </RegraContainer>
  );
};

const ValidarRegrasSection = ({ selecionadas, handleGoBack, ...other }) => {
  return (
    <Grid container justifyContent={"center"}>
      <Grid container spacing={3} direction={"column"} maxWidth={1008}>
        <Grid item container alignItems={"center"}>
          <Grid item mr={1}>
            <GoBackButton onClick={handleGoBack}>
              <ArrowBack />
            </GoBackButton>
          </Grid>
          <Grid item flex={1}>
            <TitleText>Escolha por qual banco você quer começar</TitleText>
          </Grid>
        </Grid>
        <Grid item container justifyContent={"center"}>
          <Grid item xs={12}>
            <Disclaimer _size_={"default"}>
              {"Envio de Proposta multibanco: Preencha poucas informações para enviar a proposta ao crédito.\n" +
                "As informações fornecidas serão reaproveitadas otimizando o envio para cada instituição.\n"}
            </Disclaimer>
          </Grid>
        </Grid>
        <Grid item container justifyContent={"center"}>
          <Grid item xs={12}>
            <Card>
              {selecionadas.map((regra, i) => {
                return (
                  <RegraSelecionada
                    key={regra.id}
                    {...regra}
                    index={i}
                    {...other}
                  />
                );
              })}
            </Card>
          </Grid>
        </Grid>
        <Grid item container justifyContent={"center"}>
          <Grid item xs={12}>
            <Disclaimer _size_={"default"}>
              {
                "Lembre-se de confirmar se o cliente é Correntista ou não da Instituição se solicitado. Isso pode facilitar analise de Crédito."
              }
            </Disclaimer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ValidarRegrasSection;
