import DocumentosTab from "./DocumentosTab";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetalhesOperacao,
  getDocumentos,
  getQuantidadeDocumentosPendentes,
  isBaixandoArquivos,
  isBaixandoTemplates,
  isBaixandoTodosArquivos,
  isCarregandoDocumentos,
  isRemovendoArquivos,
  isSubindoArquivos,
} from "../../selectors/operacao.selectors";
import { useCallback } from "react";
import { actions } from "../../actions/operacao.actions";
import { actions as workflowActions } from "../../actions/workflow.actions";
import { actions as documentoActions } from "../../actions/documentos.actions";
import { toast } from "react-toastify";
import { getUsuarioLogado } from "../../selectors/usuario.selectors";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";

const DocumentosTabConnected = () => {
  const dispatch = useDispatch();
  const operacao = useSelector(getDetalhesOperacao);
  const data = useSelector(getDocumentos);
  const uploading = useSelector(isSubindoArquivos);
  const downloading = useSelector(isBaixandoArquivos);
  const templating = useSelector(isBaixandoTemplates);
  const deleting = useSelector(isRemovendoArquivos);
  const loading = useSelector(isCarregandoDocumentos);
  const downloadingAll = useSelector(isBaixandoTodosArquivos);
  const usuario = useSelector(getUsuarioLogado);

  const dataComPendencias = data.map((c) => ({
    ...c,
    pendentes: getQuantidadeDocumentosPendentes([c]),
  }));

  const handleUpload = useCallback(
    ({ id, padrao, arquivos }, ok, nok) => {
      if (nok.length > 0) {
        toast.error("");
      } else {
        switch (padrao) {
          case "VC":
            if (arquivos.length > 0) {
              dispatch(
                actions.prepararUploadDocumento.request({ id, files: ok })
              );
            } else {
              dispatch(
                documentoActions.exibirValidadeCredito(true, {
                  id,
                  files: ok,
                  operacao,
                })
              );
            }
            break;
          case "LA":
            if (arquivos.length > 0) {
              dispatch(
                actions.prepararUploadDocumento.request({ id, files: ok })
              );
            } else {
              dispatch(
                documentoActions.exibirLaudoAvaliacao(true, {
                  id,
                  files: ok,
                  operacao,
                })
              );
            }
            break;
          case "ES":
            if (arquivos.length > 0) {
              dispatch(
                actions.prepararUploadDocumento.request({ id, files: ok })
              );
            } else {
              dispatch(
                documentoActions.exibirCondicoesComerciais(true, {
                  id,
                  files: ok,
                  operacao,
                })
              );
            }
            break;
          case "IA":
            dispatch(
              documentoActions.exibirIA(true, {
                id,
                files: ok,
              })
            );
            break;
          default:
            dispatch(
              actions.prepararUploadDocumento.request({ id, files: ok })
            );
            break;
        }
      }
    },
    [dispatch, operacao]
  );
  const handleDownload = useCallback(
    ({ tipo, id }) => {
      dispatch(actions.downloadDocumento.request({ tipo, id }));
    },
    [dispatch]
  );
  const handleTemplate = useCallback(
    ({ id, preencherAutomaticamente = false }) => {
      dispatch(
        actions.downloadTemplate.request({ id, preencherAutomaticamente })
      );
    },
    [dispatch]
  );
  const handleDelete = useCallback(
    ({ id }, documento) => {
      dispatch(actions.removerArquivo.request({ id, documento }));
    },
    [dispatch]
  );

  const toggleEnvioDocumentosGrupo = useCallback(
    (e, checklist, aberturaManual) => {
      e.stopPropagation();
      dispatch(
        documentoActions.permiteEnviarDocumentos.request({
          id: operacao.id,
          checklist,
          aberturaManual,
        })
      );
    },
    [dispatch, operacao]
  );

  const toggleEnvioDocumento = useCallback(
    (e, id, aberturaManual) => {
      e.stopPropagation();
      dispatch(
        documentoActions.permiteEnviarDocumento.request({
          id,
          idOperacao: operacao.id,
          aberturaManual,
        })
      );
    },
    [dispatch, operacao]
  );

  const handleExcluirDocumento = useCallback(
    (e, id) => {
      e.stopPropagation();
      dispatch(
        documentoActions.excluirDocumento.request({
          id: id,
          idOperacao: operacao.id,
        })
      );
    },
    [dispatch, operacao]
  );

  const handleAddDocument = useCallback(
    (e, chk, aberturaManual) => {
      e.stopPropagation();
      dispatch(
        workflowActions.exibirDocumentoManual({
          show: true,
          id: operacao.id,
          tipo: "operacao",
          checklist: chk,
          aberturaManual,
        })
      );
    },
    [dispatch, operacao]
  );
  const handleDownloadAll = useCallback(() => {
    dispatch(
      actions.downloadTodosDocumentos.request({
        id: operacao.id,
      })
    );
  }, [dispatch, operacao]);
  const documentosIndexados = data.reduce((t1, c) => {
    return [
      ...t1,
      ...c.grupos.reduce((t2, g) => {
        return [
          ...t2,
          ...g.itens.reduce((t3, i) => {
            return [...t3, ...i.documentos.filter((d) => d.situacao === "IN")];
          }, []),
        ];
      }, []),
    ];
  }, []);

  const handleValidarDocumento = useCallback(
    (e, item) => {
      dispatch(
        routeActions.redirectTo(routes.VALIDACAO_DOCUMENTO, {
          documento: item.id,
          operacao: operacao.id,
        })
      );
    },
    [dispatch, operacao]
  );

  return (
    <DocumentosTab
      data={dataComPendencias}
      loading={loading}
      handleUpload={handleUpload}
      handleDownload={handleDownload}
      handleDelete={handleDelete}
      handleTemplate={handleTemplate}
      handleAddDocument={handleAddDocument}
      handleValidarDocumento={handleValidarDocumento}
      uploading={uploading}
      downloading={downloading}
      deleting={deleting}
      templating={templating}
      possuiDocumentosIndexados={documentosIndexados.length > 0}
      toggleEnvioDocumentosGrupo={toggleEnvioDocumentosGrupo}
      toggleEnvioDocumento={toggleEnvioDocumento}
      handleExcluirDocumento={handleExcluirDocumento}
      downloadingAll={downloadingAll}
      handleDownloadAll={handleDownloadAll}
      perfil={usuario.perfil}
    />
  );
};

export default DocumentosTabConnected;
