import React, { useState } from "react";
import { Button, Form, Link } from "../../components";
import { ControlledTextField } from "../../components/inputs";
import { Grid, IconButton, InputAdornment, styled } from "@mui/material";
import {
  LockOutlined,
  PersonOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { transientOptions } from "../../styles/theme";

const ForgotPasswordLink = styled(Link, transientOptions)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray._500};
  font-size: ${({ theme }) => theme.fonts.size.small};
  text-align: ${({ _float_ }) => _float_};
  &:hover {
    text-decoration: underline;
  }
`;
const Separator = styled("div")`
  margin: 0.5rem 0;
  border-top: solid 2px ${({ theme }) => theme.colors.gray._50};
`;

const LoginForm = ({
  submitting,
  formProps,
  handleSubmit,
  handleClickForgotPassword,
  handleNewAccount,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid item xs={12} md={12}>
        <ControlledTextField
          label="E-mail"
          name={"email"}
          formProps={formProps}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutline />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <ControlledTextField
          label="Senha"
          name="senha"
          formProps={formProps}
          type={showPassword ? "text" : "password"}
          margin={"none"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <IconButton onClick={() => setShowPassword(false)}>
                    <VisibilityOff />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setShowPassword(true)}>
                    <Visibility />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <ForgotPasswordLink
          as={"div"}
          underline="hover"
          _float_="right"
          onClick={handleClickForgotPassword}
        >
          Esqueci minha senha
        </ForgotPasswordLink>
      </Grid>
      <Grid item xs={12} md={12}>
        <Button loading={submitting} type={"submit"} fullWidth>
          ENTRAR
        </Button>
      </Grid>
      {/*<Grid item xs={12}>*/}
      {/*  <Separator />*/}
      {/*  <ForgotPasswordLink*/}
      {/*    underline="hover"*/}
      {/*    as={"div"}*/}
      {/*    _float_="center"*/}
      {/*    onClick={handleNewAccount}*/}
      {/*  >*/}
      {/*    Ainda não é parceiro? Cria sua conta agora*/}
      {/*  </ForgotPasswordLink>*/}
      {/*</Grid>*/}
    </Form>
  );
};

export default LoginForm;
