import React from "react";
import { ReactComponent as LoaderBrand } from "../../assets/loader-horizontal.svg";
import { ReactComponent as LoaderWhite } from "../../assets/loader-horizontal-white.svg";
import { ReactComponent as LoaderGray } from "../../assets/loader-horizontal-gray.svg";
const Ticks = ({
  color = "brand",
  size = "auto",
  style = {},
  ...otherProps
}) => {
  const Loader =
    color === "brand"
      ? LoaderBrand
      : color === "gray"
      ? LoaderGray
      : LoaderWhite;

  style.width = size;
  style.height = size;

  return <Loader fill={color} style={style} {...otherProps} />;
};

export default Ticks;
