import { types, actions } from "../actions/workflow.actions";
import { actions as operacaoActions } from "../actions/operacao.actions";
import { actions as parceiroActions } from "../actions/parceiro.actions";

const initialState = {
  documentos: {
    data: [],
  },
  documentoManual: {
    show: false,
    entrada: null,
    loading: false,
    dominios: {
      referencias: [],
      documentos: [],
    },
    default:{
      referencia:null,
      documento:null
    },
    aberturaManual: false,
    submitting: false,
    novo: false,
  },
  fluxoTrabalho: {
    data: null,
    acting: false,
  },
  comentarios: {
    id: null,
    loading: false,
    data: [],
    submitting: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.carregarComentarios.REQUEST:
    case actions.carregarComentarios.SUCCESS:
    case actions.carregarComentarios.FAILURE:
      return {
        ...state,
        comentarios: {
          ...state.comentarios,
          id:
            action.type === actions.carregarComentarios.SUCCESS
              ? action.payload.values.id
              : null,
          loading: action.type === actions.carregarComentarios.REQUEST,
          data:
            action.type === actions.carregarComentarios.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.gravarComentario.REQUEST:
    case actions.gravarComentario.SUCCESS:
    case actions.gravarComentario.FAILURE:
      return {
        ...state,
        comentarios: {
          ...state.comentarios,
          submitting: action.type === actions.gravarComentario.REQUEST,
          data:
            action.type === actions.gravarComentario.SUCCESS
              ? [
                  {
                    ...action.payload.response.data,
                  },
                  ...state.comentarios.data,
                ]
              : state.comentarios.data,
        },
      };
    case actions.carregarDominiosDocumentoManual.REQUEST:
    case actions.carregarDominiosDocumentoManual.SUCCESS:
    case actions.carregarDominiosDocumentoManual.FAILURE:
      return {
        ...state,
        documentoManual: {
          ...state.documentoManual,
          novo: false,
          loading:
            action.type === actions.carregarDominiosDocumentoManual.REQUEST,
          dominios:
            action.type === actions.carregarDominiosDocumentoManual.SUCCESS
              ? {
                  ...state.documentoManual.dominios,
                  ...action.payload.response.data,
                }
              : state.documentoManual.dominios,
        },
      };
    case actions.gerarDocumentoOperacaoManual.REQUEST:
    case actions.gerarDocumentoOperacaoManual.SUCCESS:
    case actions.gerarDocumentoOperacaoManual.FAILURE:
      return {
        ...state,
        documentoManual: {
          ...state.documentoManual,
          submitting:
            action.type === actions.gerarDocumentoOperacaoManual.REQUEST,
          novo:
            state.documentoManual.novo ||
            action.type === actions.gerarDocumentoOperacaoManual.SUCCESS,
        },
      };
    case actions.gerarDocumentoParceiroManual.REQUEST:
    case actions.gerarDocumentoParceiroManual.SUCCESS:
    case actions.gerarDocumentoParceiroManual.FAILURE:
      return {
        ...state,
        documentoManual: {
          ...state.documentoManual,
          submitting:
            action.type === actions.gerarDocumentoParceiroManual.REQUEST,
          novo:
            state.documentoManual.novo ||
            action.type === actions.gerarDocumentoParceiroManual.SUCCESS,
        },
      };
    case parceiroActions.obterParceiro.REQUEST:
    case parceiroActions.obterParceiro.SUCCESS:
    case parceiroActions.obterParceiro.FAILURE:
      return {
        ...state,
        fluxoTrabalho: {
          ...state.fluxoTrabalho,
          data:
            action.type === parceiroActions.obterParceiro.SUCCESS
              ? action.payload.response.data.fluxoTrabalho
              : null,
        },
      };
    case parceiroActions.refresh.REQUEST:
    case parceiroActions.refresh.SUCCESS:
    case parceiroActions.refresh.FAILURE:
      return {
        ...state,
        fluxoTrabalho: {
          ...state.fluxoTrabalho,
          acting: action.type === parceiroActions.refresh.REQUEST,
          data:
            action.type === parceiroActions.refresh.SUCCESS
              ? action.payload.response.data.fluxoTrabalho
              : state.fluxoTrabalho.data,
        },
      };
    case operacaoActions.obterOperacao.REQUEST:
    case operacaoActions.obterOperacao.SUCCESS:
    case operacaoActions.obterOperacao.FAILURE:
      return {
        ...state,
        fluxoTrabalho: {
          ...state.fluxoTrabalho,
          data:
            action.type === operacaoActions.obterOperacao.SUCCESS
              ? action.payload.response.data.fluxoTrabalho
              : null,
        },
      };
    case operacaoActions.refresh.REQUEST:
    case operacaoActions.refresh.SUCCESS:
    case operacaoActions.refresh.FAILURE:
      return {
        ...state,
        fluxoTrabalho: {
          ...state.fluxoTrabalho,
          acting: action.type === operacaoActions.refresh.REQUEST,
          data:
            action.type === operacaoActions.refresh.SUCCESS
              ? action.payload.response.data.fluxoTrabalho
              : state.fluxoTrabalho.data,
        },
      };
    case actions.atuarFluxoTrabalho.REQUEST:
    case actions.atuarFluxoTrabalho.SUCCESS:
    case actions.atuarFluxoTrabalho.FAILURE:
      return {
        ...state,
        fluxoTrabalho: {
          ...state.fluxoTrabalho,
          acting: action.type !== actions.atuarFluxoTrabalho.FAILURE,
        },
      };
    case actions.cancelarFluxoTrabalho.REQUEST:
    case actions.cancelarFluxoTrabalho.SUCCESS:
    case actions.cancelarFluxoTrabalho.FAILURE:
      return {
        ...state,
        fluxoTrabalho: {
          ...state.fluxoTrabalho,
          acting: action.type !== actions.cancelarFluxoTrabalho.FAILURE,
        },
      };
    case actions.cancelarParceiro.REQUEST:
    case actions.cancelarParceiro.SUCCESS:
    case actions.cancelarParceiro.FAILURE:
      return {
        ...state,
        fluxoTrabalho: {
          ...state.fluxoTrabalho,
          acting: action.type === actions.cancelarParceiro.REQUEST,
        },
      };
    case actions.suspenderParceiro.REQUEST:
    case actions.suspenderParceiro.SUCCESS:
    case actions.suspenderParceiro.FAILURE:
      return {
        ...state,
        fluxoTrabalho: {
          ...state.fluxoTrabalho,
          acting: action.type === actions.suspenderParceiro.REQUEST,
        },
      };
    case actions.reativarParceiro.REQUEST:
    case actions.reativarParceiro.SUCCESS:
    case actions.reativarParceiro.FAILURE:
      return {
        ...state,
        fluxoTrabalho: {
          ...state.fluxoTrabalho,
          acting: action.type === actions.reativarParceiro.REQUEST,
        },
      };
    case types.EXIBIR_DOCUMENTO_MANUAL:
      return {
        ...state,
        documentoManual: {
          ...state.documentoManual,
          show: action.payload.show,
          loading: !!action.payload.show,
          aberturaManual: !!action.payload.aberturaManual,
          entrada: {
            ...action.payload,
            show: undefined,
          },
          default: {
            documento:action.payload.documento,
            referencia: action.payload.referencia
          }
        },
      };
    case types.DOCUMENTOS_PENDENTES:
      return {
        ...state,
        documentos: {
          ...state.documentos,
          data: action.payload,
        },
      };
    case actions.atualizarNumeroProposta.REQUEST:
    case actions.atualizarNumeroProposta.SUCCESS:
    case actions.atualizarNumeroProposta.FAILURE:
    case actions.atualizarNumeroContrato.REQUEST:
    case actions.atualizarNumeroContrato.SUCCESS:
    case actions.atualizarNumeroContrato.FAILURE:
    case actions.atualizarDataEmissao.REQUEST:
    case actions.atualizarDataEmissao.SUCCESS:
    case actions.atualizarDataEmissao.FAILURE:
      return {
        ...state,
        fluxoTrabalho: {
          ...state.fluxoTrabalho,
          acting:
            action.type === actions.atualizarNumeroProposta.REQUEST ||
            action.type === actions.atualizarNumeroContrato.REQUEST ||
            action.type === actions.atualizarDataEmissao.REQUEST,
        },
      };
    default:
      return state;
  }
};

export default reducer;
