import React from "react";
import { FormControlLabel, Radio as MRadio, styled } from "@mui/material";
import { transientOptions } from "../../styles/theme";

const StyledControlLabel = styled(FormControlLabel, transientOptions)`
  background-color: ${({ theme }) => theme.colors.gray._50};
  border: 1px solid ${({ theme }) => theme.colors.gray._50};
  border-radius: ${({ theme }) => theme.borderRadiuses.inputs};
  padding: 15px 1rem;
  margin-right: 0;
  margin-left: 0;

  & .MuiFormControlLabel-label {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme, size = "normal" }) =>
      theme.fonts.size.inputs[size].content};
    color: ${({ theme }) => theme.colors.gray._900};
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  .Mui-error & {
    background-color: ${({ theme }) => theme.colors.error._50};
    border: 1px solid ${({ theme }) => theme.colors.error._50};
    & .MuiFormControlLabel-label {
      color: ${({ theme }) => theme.colors.error._500};
    }
  }
  & .MuiRadio-root {
    display: none;
  }
  &:has(.Mui-checked) {
    border: 1px solid ${({ theme }) => theme.colors.secondary._500};

    & .MuiFormControlLabel-label {
      color: ${({ theme }) => theme.colors.secondary._500};
      font-weight: 600;
    }
  }
`;

const Radio = (props) => {
  return <StyledControlLabel {...props} control={<MRadio />} />;
};

export default Radio;
