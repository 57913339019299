import FluxoAquisicaoSantander from "./FluxoAquisicaoSantander";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getComplementoOperacao } from "../../../../selectors/operacao.selectors";

const FluxoAquisicaoSantanderConnected = () => {
  const [step, setStep] = useState(0);
  const [participante, setParticipante] = useState(null);
  const [events, setEvents] = useState(null);

  const {
    operacao: { participantes },
    validacao,
  } = useSelector(getComplementoOperacao);

  const compradores = useMemo(
    () =>
      participantes
        .filter((p) => p.tipoParticipante === "CO")
        .map((c) => ({
          ...c,
          fluxo: validacao.completa.participantes.find((f) => f.id === c?.id)
            ?.etapas,
        }))
        .sort((c1, c2) =>
          c1.principal
            ? -1
            : c2.principal
            ? 1
            : (c1.nome ?? "").localeCompare(c2.nome ?? "")
        ),
    [participantes, validacao]
  );

  const vendedores = useMemo(
    () =>
      participantes
        .filter((p) => p.tipoParticipante === "VD")
        .map((c) => ({
          ...c,
          representantes: c.representantes?.map((r) => ({
            ...r,
            fluxo: validacao.completa.participantes.find((f) => f.id === r?.id)
              ?.etapas,
          })),
          fluxo: validacao.completa.participantes.find((f) => f.id === c?.id)
            ?.etapas,
        }))
        .sort((c1, c2) =>
          c1.principal
            ? -1
            : c2.principal
            ? 1
            : (c1.nome ?? "").localeCompare(c2.nome ?? "")
        ),
    [participantes, validacao]
  );

  const socios = useMemo(
    () =>
      participantes
        .filter((p) => p.tipoParticipante === "RL")
        .map((c) => ({
          ...c,
          fluxo: validacao.completa.participantes.find((f) => f.id === c?.id)
            ?.etapas,
        }))
        .sort((a, b) => a - b),
    [participantes, validacao]
  );

  const makeGoTo = useCallback(
    (s) => {
      return () => {
        setStep(s);
      };
    },
    [setStep]
  );
  const handleGoTo0 = useCallback(() => {
    setStep(0);
  }, [setStep]);
  const handleGoTo1 = useCallback(() => {
    setStep(1);
  }, [setStep]);
  const handleGoTo2 = useCallback(() => {
    setStep(2);
  }, [setStep]);
  const handleGoTo3 = useCallback(() => {
    setStep(3);
  }, [setStep]);
  const handleGoTo4 = useCallback(() => {
    setStep(4);
  }, [setStep]);
  const handleGoTo5 = useCallback(() => {
    setStep(5);
  }, [setStep]);
  const handleGoTo7 = useCallback(() => {
    setStep(7);
  }, [setStep]);
  const handleGoTo9 = useCallback(() => {
    setStep(9);
  }, [setStep]);
  const handleGoTo10 = useCallback(() => {
    setStep(10);
  }, [setStep]);
  const handleGoTo11 = useCallback(() => {
    setStep(11);
  }, [setStep]);
  const handleGoTo12 = useCallback(() => {
    setStep(12);
  }, [setStep]);
  const handleGoTo20 = useCallback(() => {
    setStep(20);
  }, [setStep]);
  const handleGoTo21 = useCallback(() => {
    setStep(21);
  }, [setStep]);
  const handleGoTo22 = useCallback(() => {
    setStep(22);
  }, [setStep]);
  const handleGoTo60 = useCallback(() => {
    setStep(60);
  }, [setStep]);
  const handleGoTo61 = useCallback(() => {
    setStep(61);
  }, [setStep]);
  const handleGoTo62 = useCallback(() => {
    setStep(62);
  }, [setStep]);
  const handleGoTo100 = useCallback(() => {
    setStep(100);
  }, [setStep]);
  const handleGoTo101 = useCallback(() => {
    setStep(101);
  }, [setStep]);
  const handleGoTo102 = useCallback(() => {
    setStep(102);
  }, [setStep]);
  const handleGoTo999 = useCallback(() => {
    setStep(999);
  }, [setStep]);
  const handleGoTo998 = useCallback(() => {
    setStep(998);
  }, [setStep]);
  const eventsComprador = [
    handleGoTo1,
    handleGoTo2,
    handleGoTo3,
    handleGoTo4,
    handleGoTo7,
  ];
  const eventsCompradorDps = [
    handleGoTo1,
    handleGoTo2,
    handleGoTo3,
    handleGoTo4,
    handleGoTo9,
    handleGoTo7,
  ];
  const eventsCompradorPrincipal = [
    handleGoTo1,
    handleGoTo2,
    handleGoTo3,
    handleGoTo4,
    handleGoTo5,
    handleGoTo7,
  ];
  const eventsCompradorPrincipalDps = [
    handleGoTo1,
    handleGoTo2,
    handleGoTo3,
    handleGoTo4,
    handleGoTo5,
    handleGoTo9,
    handleGoTo7,
  ];
  const eventsVendedorPf = [handleGoTo10, handleGoTo11, handleGoTo12];
  const eventsVendedorPj = [handleGoTo20, handleGoTo21, handleGoTo22];
  const eventsImovel = [handleGoTo100, handleGoTo101, handleGoTo102];
  const eventsSociosPf = [handleGoTo60, handleGoTo61, handleGoTo62];
  const eventsSociosPj = [handleGoTo60, handleGoTo61];

  return (
    <FluxoAquisicaoSantander
      step={step}
      setStep={setStep}
      participante={participante}
      setParticipante={setParticipante}
      compradores={compradores}
      vendedores={vendedores}
      socios={socios}
      events={events}
      setEvents={setEvents}
      eventsComprador={eventsComprador}
      eventsCompradorDps={eventsCompradorDps}
      eventsCompradorPrincipal={eventsCompradorPrincipal}
      eventsCompradorPrincipalDps={eventsCompradorPrincipalDps}
      eventsVendedorPf={eventsVendedorPf}
      eventsVendedorPj={eventsVendedorPj}
      eventsSociosPf={eventsSociosPf}
      eventsSociosPj={eventsSociosPj}
      eventsImovel={eventsImovel}
      handleGoTo0={handleGoTo0}
      handleGoTo1={handleGoTo1}
      handleGoTo2={handleGoTo2}
      handleGoTo3={handleGoTo3}
      handleGoTo4={handleGoTo4}
      handleGoTo5={handleGoTo5}
      handleGoTo7={handleGoTo7}
      handleGoTo9={handleGoTo9}
      handleGoTo10={handleGoTo10}
      handleGoTo11={handleGoTo11}
      handleGoTo12={handleGoTo12}
      handleGoTo20={handleGoTo20}
      handleGoTo21={handleGoTo21}
      handleGoTo22={handleGoTo22}
      handleGoTo60={handleGoTo60}
      handleGoTo61={handleGoTo61}
      handleGoTo62={handleGoTo62}
      handleGoTo100={handleGoTo100}
      handleGoTo101={handleGoTo101}
      handleGoTo102={handleGoTo102}
      handleGoTo998={handleGoTo998}
      handleGoTo999={handleGoTo999}
      makeGoTo={makeGoTo}
    />
  );
};

export default FluxoAquisicaoSantanderConnected;
