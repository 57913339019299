import React, { useCallback } from "react";
import EscolhaParticipanteSantanderForm from "./EscolhaParticipanteSantanderForm";
import { actions } from "../../../../actions/operacao.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isAdicionandoParticipantePreenchimento,
  isAtualizandoPreenchimento,
  isEnviandoPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
  isRemovendoParticipantePreenchimento,
} from "../../../../selectors/operacao.selectors";
import {
  actions as routeActions,
  types as routes,
} from "../../../../actions/rotas.actions";
import { getEtapasAbertas } from "../../../../selectors/workflow.selectors";
import { toast } from "react-toastify";

const EscolhaParticipanteSantanderFormConnected = ({
  correntista,
  setCorrentista,
  setComprador,
  handleGoTo1,
  compradores,
}) => {
  const dispatch = useDispatch();
  const { operacao, dominios } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);
  const sending = useSelector(isEnviandoPreenchimentoOperacao);
  const adding = useSelector(isAdicionandoParticipantePreenchimento);
  const deleting = useSelector(isRemovendoParticipantePreenchimento);
  const refreshing = useSelector(isAtualizandoPreenchimento);
  const etapasAbertas = useSelector(getEtapasAbertas);

  const isReenvio = etapasAbertas.find((e) => e.padrao === "RP");

  const handleCorrentistaClick = useCallback(
    (e) => {
      setCorrentista(e.target.value === "true");
    },
    [setCorrentista]
  );

  const acessarParticipante = useCallback(
    (c) => {
      setComprador(c);
      handleGoTo1();
    },
    [setComprador, handleGoTo1]
  );
  const callback = useCallback(
    (data, comprador) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        acessarParticipante({
          ...c,
          fluxo: data.validacao[
            data.operacao.detalhe.correntista ? "correntista" : "completa"
          ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
        });
      }
    },
    [acessarParticipante]
  );
  const handleAdicionarParticipante = useCallback(() => {
    dispatch(
      actions.adicionarParticipantePreenchimento.request({
        id: operacao.id,
        tipoParticipante: "CO",
        tipoPessoa: "F",
      })
    );
  }, [dispatch, operacao]);
  const handleRemoverParticipante = useCallback(
    (e, participante) => {
      e.stopPropagation();
      dispatch(
        actions.removerParticipantePreenchimento.request({
          id: operacao.id,
          participante,
        })
      );
    },
    [dispatch, operacao]
  );

  const handleAcessarParticipante = useCallback(
    (e, c) => {
      e.stopPropagation();
      if (operacao.detalhe.correntista === correntista) {
        acessarParticipante(c);
      } else {
        dispatch(
          actions.gravarPreenchimento.request({
            callback: (data) => callback(data, c),
            instituicao: operacao.codigoInstituicao,
            passo: "fluxo",
            operacao: {
              id: operacao.id,
              detalhes: {
                correntista,
              },
            },
          })
        );
      }
    },
    [correntista, operacao, acessarParticipante, dispatch, callback]
  );

  const handleGoToSiblings = useCallback(() => {
    toast.success("Proposta enviada para o Crédito.");
    dispatch(
      routeActions.redirectTo(routes.DETALHES_CLIENTE, { id: operacao.cliente })
    );
  }, [dispatch, operacao]);

  const handleGoBack = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.DETALHES_OPERACAO, { id: operacao.id })
    );
  }, [dispatch, operacao]);

  const handleEnviarAnaliseCredito = useCallback((e, c) => {
      e.stopPropagation();
      dispatch(
          actions.gravarPreenchimento.request({
              instituicao: operacao.codigoInstituicao,
              passo: "fluxo",
              operacao: {
                  id: operacao.id,
                  detalhes: {
                      correntista,
                  },
              },
          })
      );

    dispatch(
      actions.enviarPreenchimento.request({
        id: operacao.id,
        callback: !!isReenvio ? handleGoBack : handleGoToSiblings,
      })
    );
  }, [correntista, dispatch, operacao, handleGoToSiblings, isReenvio, handleGoBack]);

  const possuiPendencias = compradores.reduce(
    (result, c) =>
      result ||
      c.fluxo.filter(
        (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
      ).length > 0,
    false
  );

  return (
    <EscolhaParticipanteSantanderForm
      compradores={compradores}
      correntista={correntista}
      handleCorrentistaClick={handleCorrentistaClick}
      handleAcessarParticipante={handleAcessarParticipante}
      handleEnviarAnaliseCredito={handleEnviarAnaliseCredito}
      possuiPendencias={possuiPendencias}
      submitting={submitting}
      adding={adding}
      handleAdicionarParticipante={handleAdicionarParticipante}
      deleting={deleting}
      sending={sending}
      handleRemoverParticipante={handleRemoverParticipante}
      refreshing={refreshing}
      dominios={dominios}
      handleGoBack={handleGoBack}
    />
  );
};

export default EscolhaParticipanteSantanderFormConnected;
