import React from "react";
import {
  Paper,
  TableContainer as MTableContainer,
  styled,
  TableBody,
  TableHead,
  TableRow,
  Grid,
  IconButton,
} from "@mui/material";
import { Button, Card, Title, Tooltip } from "../../components";
import { BodyTableRow, SizedTableCell, Table } from "../../components/tables";
import formatters from "../../utils/formatters";
import { Delete, Edit } from "@mui/icons-material";

const TableContainer = styled(MTableContainer)`
  box-shadow: none;
`;
const NormalText = styled("span")`
  font-weight: 300;
`;
const ActionButton = styled(IconButton)`
  background-color: ${({ theme, color }) => theme.colors[color]._75};
  color: ${({ theme, color }) => theme.colors[color]._400};
  border-radius: 0.5rem;
  margin-left: auto;
  transition: 0.1s;
  &:hover {
    transition: 0.1s;
    color: ${({ theme, color }) => theme.colors[color]._300};
    background-color: ${({ theme, color }) => theme.colors[color]._100};
  }
`;
const AddButton = styled(Button)`
  padding: 6px 0.75rem;
  display: inline-block;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary._500};
  border-radius: 0.75rem;
  cursor: pointer;
`;

const FiliaisTab = ({ data, handleDialog }) => {
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Card>
            <Grid
              container
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <Title as={"h3"} _margin_={"0 0 1rem 0"}>
                  Filiais
                </Title>
              </Grid>
              <Grid item>
                <AddButton onClick={() => handleDialog("create", null)}>
                  Criar filial
                </AddButton>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ margin: 0 }} stickyHeader>
                    <TableHead>
                      <TableRow>
                        <SizedTableCell style={{ minWidth: 150 }}>
                          Nome
                        </SizedTableCell>
                        <SizedTableCell width={100} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.times.map((time) => {
                        return (
                          <React.Fragment key={time.id}>
                            <BodyTableRow>
                              <SizedTableCell style={{ minWidth: 150 }}>
                                <NormalText>{time.nome}</NormalText>
                              </SizedTableCell>
                              <SizedTableCell width={100}>
                                <ActionButton
                                  color={"gray"}
                                  style={{ marginLeft: 3, marginRight: 3 }}
                                  onClick={() => handleDialog("update", time)}
                                >
                                  <Tooltip title={"Atualizar filial"}>
                                    <Edit />
                                  </Tooltip>
                                </ActionButton>
                                <ActionButton
                                  color={"error"}
                                  style={{ marginLeft: 3, marginRight: 3 }}
                                  onClick={() => handleDialog("delete", time)}
                                >
                                  <Tooltip title={"Remover filial"}>
                                    <Delete />
                                  </Tooltip>
                                </ActionButton>
                              </SizedTableCell>
                            </BodyTableRow>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FiliaisTab;
