import { Button, Card, Title } from "../../components";
import { Grid, styled } from "@mui/material";
import React, { useState } from "react";
import { Field } from "../../components/presentation";
import { ETAPAS, logos } from "../../constants/generic.constants";
import Switch from "../../components/inputs/Switch";
import Stepper from "../operacao/preenchimento/Stepper";
import theme, { transientOptions } from "../../styles/theme";
import { minutesToHoursOrDays } from "../../utils/basic";
import * as PropTypes from "prop-types";

const SituacaoOperacaoContainer = styled("span")`
  align-self: flex-end;
  padding: 0.3rem 0.5rem;
  display: inline-block;
  font-size: ${({ theme }) => theme.fonts.size.parceiro.situacao};
  font-weight: 700;
  color: ${({ theme, _situacao_, _color_ }) =>
    !!_color_
      ? _color_
      : _situacao_ === "T"
      ? theme.colors.success._600
      : _situacao_ === "C"
      ? theme.colors.error._600
      : theme.colors.secondary._600};
  background-color: ${({ theme, _situacao_, _color_ }) =>
    !!_color_
      ? `${_color_}20`
      : _situacao_ === "T"
      ? `${theme.colors.success._600}20`
      : _situacao_ === "C"
      ? `${theme.colors.error._600}20`
      : `${theme.colors.secondary._600}20`};
  border-radius: 6px;
`;
const RelacionadasContainer = styled("div")`
  margin: 0.5rem 0;
  border-top: solid 2px ${({ theme }) => theme.colors.gray._50};
  padding-top: 0.75rem;
`;
const RegraContainer = styled("div")`
  &:not(:first-of-type) {
    padding-top: 1rem;
  }
  &:not(:last-of-type) {
    padding-bottom: 1rem;
  }

  & + & {
    border-top: dashed 1px ${({ theme }) => theme.colors.gray._100};
  }
`;
const Logo = styled("img")`
  width: 64px;
  height: 64px;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray._100};
`;
const NomeInstituicao = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray._700};
`;
const NomeCondicao = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secondary._700};
`;
const DisclaimerContainer = styled("div", transientOptions)`
  border-radius: ${({ theme }) => theme.borderRadiuses.disclaimer};
  border: 1px dashed
    ${({ theme, _color_ = "secondary" }) => theme.colors[_color_]._700};
  background-color: ${({ theme, _color_ = "primary" }) =>
    theme.colors[_color_]._100};
  color: ${({ theme, _color_ = "secondary" }) => theme.colors[_color_]._600};
  padding: 1rem 1.5rem;
  margin: 0.75rem 0 0;
`;
const Disclaimer = styled("p", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 500;
  color: inherit;
  margin: 0;
`;
const ActionButton = styled(Button, transientOptions)`
  margin-bottom: 8px;

  &.MuiButton-text {
    background-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._75};
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._600};
  }

  &.MuiButton-text:hover {
    background-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._100};
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._600};
  }
`;
const Text = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.gray._400};
  text-align: ${({ _align_ = "left" }) => _align_};
  margin: ${({ _margin_ = "0" }) => _margin_};
`;

function MailIcon(props) {
  return null;
}

MailIcon.propTypes = { color: PropTypes.string };
const RegraSelecionada = ({
  index,
  idCliente,
  handleInit,
  handleOperation,
  handleNotInterested,
  initing,
  idRegra,
  ...data
}) => {
  const [correntista, setCorrentista] = useState(data.correntista);

  return (
    <RegraContainer>
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2} alignItems={"center"}>
          <Grid
            item
            container
            xs={12}
            md={4}
            lg={3}
            spacing={2}
            alignItems={"center"}
          >
            <Grid item>
              <Logo src={logos[data.codigoInstituicao]} />
            </Grid>
            <Grid item display={"flex"} flexDirection={"column"}>
              <NomeInstituicao>{data.nomeInstituicao}</NomeInstituicao>
              <NomeCondicao>{data.nomeCondicao}</NomeCondicao>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            lg={7}
            spacing={2}
            alignItems={"center"}
          >
            <Grid item display={"flex"} flexDirection={"column"}>
              {!data.habilitada && (
                <DisclaimerContainer _color_={"error"}>
                  <Disclaimer>
                    {data.cancelado
                      ? "Você optou por não receber propostas dessa instituição"
                      : "Você já possui uma proposta em andamento nesta instituição."}
                  </Disclaimer>
                </DisclaimerContainer>
              )}
              {!!data.habilitada && !!data.operacao && (
                <>
                  <Disclaimer>
                    Proposta <b>#{data.operacao.id}</b> na etapa&nbsp;&nbsp;
                    <SituacaoOperacaoContainer
                      _situacao_={data.operacao.tipoSituacao}
                      _color_={data.operacao.corBadge}
                    >
                      {data.operacao.situacao}
                    </SituacaoOperacaoContainer>
                  </Disclaimer>
                </>
              )}
              {!!data.fluxoSimplificado &&
                !!data.habilitada &&
                !data.operacao && (
                  <Switch
                    label={`É correntista ${data.nomeInstituicao}?`}
                    labelPlacement={"end"}
                    value={correntista}
                    onChange={() => setCorrentista(!correntista)}
                  />
                )}
              {!data.fluxoSimplificado &&
                !!data.habilitada &&
                !data.operacao && (
                  <DisclaimerContainer>
                    <Disclaimer>
                      Esta instituição não possui fluxo simplificado de
                      contratação
                    </Disclaimer>
                  </DisclaimerContainer>
                )}
              {!!data.aceitaApenasCorrentistas &&
                !data.operacao &&
                !!data.habilitada &&
                !correntista && (
                  <DisclaimerContainer _color_={"error"}>
                    <Disclaimer>
                      No momento, esta instituição não está aceitando clientes
                      que não sejam correntistas. Caso queira continuar o
                      processo com esta instituição, abra uma conta e depois
                      retorne aqui para continuar.
                    </Disclaimer>
                  </DisclaimerContainer>
                )}
              {!!data.habilitada &&
                !data.operacao &&
                (!data.aceitaApenasCorrentistas || correntista) && (
                  <DisclaimerContainer>
                    <Title
                      as={"small"}
                      _margin_={"0 0 1rem 0"}
                      _display_={"block"}
                    >
                      Etapas necessárias para envio para crédito
                    </Title>
                    <Stepper
                      current={-1}
                      backgroundColor={theme.colors.primary._100}
                      fluxo={
                        !!correntista
                          ? data.fluxo.correntista
                          : data.fluxo.completo
                      }
                    />
                    <Text
                      _margin_={"1rem 0 0 0"}
                      _display_={"block"}
                      _color_={"gray"}
                    >
                      Tempo estimado de resposta:{" "}
                      <b>{minutesToHoursOrDays(data.tempoResposta)}</b>
                    </Text>
                  </DisclaimerContainer>
                )}
            </Grid>
          </Grid>
          <Grid item container xs={12} md={2} spacing={2} alignItems={"center"}>
            <Grid item xs={12}>
              <>
                {!!data.habilitada &&
                  !data.operacao &&
                  (!data.aceitaApenasCorrentistas || correntista) &&
                  !data.cancelado && (
                    <ActionButton
                      _color_={"success"}
                      loading={initing || data.submitting}
                      fullWidth
                      variant={"text"}
                      onClick={() => handleInit(data.id, correntista)}
                    >
                      INICIAR
                    </ActionButton>
                  )}
                {!!data.habilitada && !!data.operacao && !data.cancelado && (
                  <ActionButton
                    fullWidth
                    variant={"text"}
                    onClick={() => handleOperation(data.operacao)}
                    loading={data.submitting}
                  >
                    ACESSAR
                  </ActionButton>
                )}
                {!!data.habilitada && !data.operacao && (
                  <ActionButton
                    fullWidth
                    variant={"text"}
                    onClick={() =>
                      handleNotInterested({
                        id: idRegra,
                        clienteId: idCliente,
                      })
                    }
                    loading={data.submitting}
                    _color_={"error"}
                  >
                    NÃO TEM INTERESSE
                  </ActionButton>
                )}
              </>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RegraContainer>
  );
};

const Lead = ({ cliente, data, ...props }) => {
  return (
    <>
      <Grid
        item
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={2}
      >
        <Grid item xs={12} md={10}>
          <Grid container spacing={2}>
            <Grid item container spacing={1}>
              <Grid item>
                <Title as={"h4"} _color_={"gray"} _display_={"inline"}>
                  Lead #{data.id}
                </Title>
              </Grid>
              <Grid item>
                <SituacaoOperacaoContainer
                  _situacao_={data.tipoSituacao}
                  _color_={data.corBadge}
                >
                  {data.situacao}
                </SituacaoOperacaoContainer>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <Field label={"Produto"} value={data.lead.produto} />
              </Grid>
              <Grid item>
                <Field
                  label={"Criado em"}
                  type={"timestamp"}
                  value={new Date(data.dataHoraCriacao)}
                />
              </Grid>
              {(!!data.dataHoraEncerramento || !!data.dataHoraCancelamento) && (
                <Grid item>
                  <Field
                    label={"Finalizado em"}
                    type={"timestamp"}
                    value={
                      new Date(
                        data.dataHoraEncerramento ?? data.dataHoraCancelamento
                      )
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2}>
          <ActionButton
            fullWidth
            variant={"text"}
            _color_={"gray"}
            onClick={() => props.handleOperation(data)}
            loading={props.submitting}
          >
            ACESSAR
          </ActionButton>
          {!!data.dataHoraEncerramento && (
            <ActionButton
              fullWidth
              variant={"text"}
              _color_={"gray"}
              onClick={() =>
                props.handleDuplicateLead({
                  ...cliente,
                  ...data.lead,
                  cliente: data.cliente,
                })
              }
              loading={props.submitting}
            >
              SIMULAR NOVAMENTE
            </ActionButton>
          )}
        </Grid>
      </Grid>

      {data.relacionadas.length > 0 && (
        <Grid item xs={12}>
          <RelacionadasContainer>
            <Title as={"h5"} _margin_={"0 0 1rem 0"}>
              Propostas pendentes&nbsp;/&nbsp;geradas
            </Title>
            {data.relacionadas
              .sort(
                (a, b) =>
                  ETAPAS.find((e) => b.operacao?.situacao === e.label)?.id -
                  ETAPAS.find((e) => a.operacao?.situacao === e.label)?.id
              )
              .map((regra, i) => {
                return (
                  <RegraSelecionada
                    key={regra.id}
                    {...regra}
                    {...props}
                    idRegra={regra.id}
                    idCliente={data.cliente}
                    index={i}
                  />
                );
              })}
          </RelacionadasContainer>
        </Grid>
      )}
    </>
  );
};

const PropostasTab = ({
  submitting,
  cliente,
  leads,
  handleOperation,
  handleInit,
  initing,
  handleDuplicateLead,
  handleNotInterested,
}) => {
  return (
    <Grid item container spacing={3}>
      {leads.map((data) => (
        <Grid item xs={12} key={data.id}>
          <Card>
            <Grid container spacing={2}>
              <Lead
                cliente={cliente}
                data={data}
                initing={initing}
                handleOperation={handleOperation}
                handleInit={handleInit}
                handleDuplicateLead={handleDuplicateLead}
                handleNotInterested={handleNotInterested}
                submitting={submitting}
              />
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default PropostasTab;
