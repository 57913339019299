import React, { useCallback } from "react";
import ComentariosCard from "./ComentariosCard";
import { useDispatch, useSelector } from "react-redux";
import { getComentarios } from "../../selectors/workflow.selectors";
import useCompleteForm from "../../hooks/useCompleteForm";
import validators from "../../utils/validators";
import { actions } from "../../actions/workflow.actions";

const ComentariosCardConnected = () => {
  const dispatch = useDispatch();
  const { loading, data, submitting } = useSelector(getComentarios);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: useCallback(
      () => ({
        comentario: validators.string({ required: true }),
      }),
      []
    ),
    initialValues: useCallback(
      () => ({
        comentario: "",
      }),
      []
    ),
    handleSubmitWithForm: useCallback(
      (form) => {
        return (values) => {
          dispatch(
            actions.gravarComentario.request({
              ...values,
              callback: form.reset,
            })
          );
        };
      },
      [dispatch]
    ),
  });

  return (
    <ComentariosCard
      loading={loading}
      comentarios={data}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
    />
  );
};

export default ComentariosCardConnected;
