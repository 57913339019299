import { Grid } from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack, Title } from "../../../../../components";
import {
  ControlledSwitch,
  ControlledTextField,
  CurrencyTextField,
  IntegerTextField,
} from "../../../../../components/inputs";
import Stepper from "../../../preenchimento/Stepper";
import { Delete } from "@mui/icons-material";
import PisPasepTextField from "../../../../../components/inputs/PisPasepTextField";

const DadosFgtsBradescoForm = ({
  fluxo,
  formProps,
  principal,
  handleSubmit,
  submitting,
  events,
  handleGoTo5,
  handleGoTo4,
  contasFgts,
  formPropsFgts,
  adicionarContaFgts,
  removerContaFgts,
  utilizaFgts,
  possuiImovelNoPais,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={principal ? handleGoTo5 : handleGoTo4}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper
              current={events.length - 1}
              events={events}
              fluxo={fluxo}
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item width={1}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Dados de FGTS</Title>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledSwitch
                      name={"utilizaFgts"}
                      label={"Utilizará FGTS como parte da entrada?"}
                      tooltip={
                        "Para utilizar o FGTS como parte de pagamento, você deverá preencher o formulário de Autorização de Conta Vinculada do FGTS e enviar os documentos necessários conforme checklist de contratação. Para maiores informações, consulte 'Manual do FGTS - Utilização na Moradia' da Caixa Econômica Federal."
                      }
                      formProps={formProps}
                    />
                  </Grid>
                  {utilizaFgts && (
                    <>
                      <Grid item xs={12} md={6}>
                        <ControlledTextField
                          label={"PIS / PASEP"}
                          name={"pisPasep"}
                          formProps={formProps}
                          inputProps={{ maxLenght: 14 }}
                          InputProps={{
                            inputComponent: PisPasepTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledSwitch
                          label="Possuo no mínimo 36 meses de trabalho sob regime do FGTS"
                          name={"possuiMinimoContribuicaoFgts"}
                          tooltip={
                            "Soma-se os períodos trabalhados, consecutivos ou não, na mesma ou em diferentes empresas."
                          }
                          formProps={formProps}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledSwitch
                          label="Transferi o saldo devedor de um financiamento do SFH sem a intervenção da instituição financiadora"
                          name={"transferiuSaldoDevedor"}
                          tooltip={
                            "Marque esta opção se em algum momento você já transferiu sem a interveniência da instituição financiadora, na qualidade de promitente vendedor, saldo devedor de financiamento firmado nas condições do SFH em qualquer parte do País."
                          }
                          formProps={formProps}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Title as={"h5"}>Contas de FGTS</Title>
                      </Grid>
                      {contasFgts.fields.map((fgts, index) => (
                        <Grid item xs={12} key={fgts.id}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <ControlledTextField
                                formProps={formPropsFgts}
                                name={`contasFgts.${index}.conta`}
                                validationKey={"contasFgts.conta"}
                                label={"Conta"}
                                InputProps={{
                                  inputComponent: IntegerTextField,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <ControlledTextField
                                formProps={formPropsFgts}
                                name={`contasFgts.${index}.empregador`}
                                validationKey={"contasFgts.empregador"}
                                label={"Código do Empregador"}
                                InputProps={{
                                  inputComponent: IntegerTextField,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <ControlledTextField
                                formProps={formPropsFgts}
                                name={`contasFgts.${index}.valorSaldo`}
                                validationKey={"contasFgts.valorSaldo"}
                                label={"Valor do Saldo"}
                                InputProps={{
                                  inputComponent: CurrencyTextField,
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={1}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Button
                                onClick={() => removerContaFgts(index)}
                                _color_="error"
                                style={{
                                  padding: 10,
                                  minWidth: 0,
                                  minHeight: 0,
                                  width: 40,
                                  height: 40,
                                  borderRadius: 10,
                                  marginTop: 30,
                                }}
                              >
                                <Delete />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                      <Grid item container justifyContent={"flex-end"} mt={1}>
                        <Grid item xs={12} md={4}>
                          <Button
                            variant={"outlined"}
                            onClick={() =>
                              adicionarContaFgts({
                                conta: "",
                                empregador: "",
                                valorSaldo: "",
                              })
                            }
                            fullWidth
                          >
                            ADICIONAR CONTA
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Title as={"h5"}>Sobre o Imóvel</Title>
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledSwitch
                          label="Localiza-se no município ou região onde exerço minha ocupação principal"
                          name={"imovelLocalizadoNoMunicipioDeOcupacao"}
                          formProps={formProps}
                          tooltip={
                            "Incluindo-se os municípios limítrofes e os integrantes da mesma região metropolitana."
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledSwitch
                          label="Localiza-se no município ou região onde resido há menos de 12 meses"
                          name={
                            "imovelLocalizadoNoMunicipioDeResidenciaMenosDeUmAno"
                          }
                          formProps={formProps}
                          tooltip={
                            "Incluindo-se os municípios limítrofes e os integrantes da mesma região metropolitana."
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledSwitch
                          label="Localiza-se no município ou região onde resido há, no mínimo, 12 meses"
                          name={
                            "imovelLocalizadoNoMunicipioDeResidenciaMaisDeUmAno"
                          }
                          formProps={formProps}
                          tooltip={
                            "Incluindo-se os municípios limítrofes e os integrantes da mesma região metropolitana."
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Title as={"h5"}>Dados de Propriedades</Title>
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledSwitch
                          label="Sou proprietário de imóvel localizado em qualquer parte do país"
                          name={"possuiImovelNoPais"}
                          tooltip={
                            "Inclui imóvel financiado, concluído, ou em construção em qualquer parte do país."
                          }
                          formProps={formProps}
                        />
                      </Grid>
                      {possuiImovelNoPais ? (
                        <>
                          <Grid item xs={12}>
                            <ControlledSwitch
                              label="Já possuo outro financiamento imobiliário ativo"
                              name={"possuiFinanciamentoAtivo"}
                              tooltip={
                                "Inclui financiamento para aquisição ou construção de imóvel residencial, que esteja ativo, e tenha sido concedido no âmbito do Sistema Financeiro Habitacional (SFH), localizado em qualquer parte do país."
                              }
                              formProps={formProps}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ControlledSwitch
                              label="Sou proprietário de imóvel localizado no município de ocupação"
                              name={"possuiImovelNoMunicipioDeOcupacao"}
                              formProps={formProps}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ControlledSwitch
                              label="Sou proprietário de imóvel localizado no município de residência"
                              name={"possuiImovelNoMunicipioDeResidencia"}
                              formProps={formProps}
                            />
                          </Grid>
                        </>
                      ) : null}
                    </>
                  )}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosFgtsBradescoForm;
