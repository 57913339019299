import CommonPage from "../CommonPage";
import { Button, Card, GoBack, Link } from "../../components";
import { Grid, Skeleton, styled } from "@mui/material";
import React from "react";
import DadosUsuarioTabConnected from "./DadosUsuarioTab.connected";
import { SITUACAO } from "../../constants/usuario.constants";
import formatters from "../../utils/formatters";
import { EmailRounded, WhatsApp, Link as LinkIcon } from "@mui/icons-material";

const SituacaoContainer = styled("span")`
  margin-left: 1rem;
  padding: 0.3rem 0.5rem;
  display: inline-block;
  font-size: ${({ theme }) => theme.fonts.size.parceiro.situacao};
  font-weight: 700;
  color: ${({ theme, _situacao_ }) =>
    _situacao_ === "A"
      ? theme.colors.success._600
      : _situacao_ === "C"
      ? theme.colors.error._600
      : theme.colors.secondary._600};
  background-color: ${({ theme, _situacao_ }) =>
    _situacao_ === "A"
      ? theme.colors.success._100
      : _situacao_ === "C"
      ? theme.colors.error._100
      : theme.colors.secondary._100};
  border-radius: 6px;
`;
const Text = styled("p")`
  margin: 2px;
`;
const SkeletonCard = styled(Skeleton)`
  width: 100%;
  height: 239px;
  border-radius: 1rem;
`;
const ImageContainer = styled("div")`
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadiuses.imageHolders};
  background-color: ${({ theme }) => theme.colors.primary._100};
  font-size: 68px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary._700};
`;
const NomeUsuario = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.parceiro.title};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray._700};
`;

const NomeParceiro = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.parceiro.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 700;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.secondary._600};
`;

const LinkTelefone = styled(Link)`
  font-size: ${({ theme }) => theme.fonts.size.operacao.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray._200};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary._300};
    font-weight: 600;
    text-decoration: underline;
  }
`;
const StyledIcon = styled(WhatsApp)`
  margin-right: 0.25rem;
  height: 24px;
  width: 24px;
`;
const Email = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.operacao.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray._200};
`;

const ButtonContainer = styled(Grid)`
  margin: 16px;
`;
const CpfCnpj = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.parceiro.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray._200};
`;

const DetalhesUsuarioPage = ({ loading, data, handleBack, handleShare }) => {
  const isLoading = !!loading || !data;

  return (
    <CommonPage selected={"usuarios"}>
      <Grid container spacing={3}>
        <ButtonContainer>
          <GoBack handleClick={handleBack} text={"Detalhes do usuário"} />
        </ButtonContainer>
      </Grid>
      {isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={3} alignItems={"center"}>
                <Grid item xs={true} display={"flex"} gap={"1.5rem"}>
                  <ImageContainer>
                    {data.nome.slice(0, 2).toUpperCase()}
                  </ImageContainer>
                  <Grid display={"flex"} flexDirection={"column"}>
                    <Grid display={"flex"} alignItems={"center"}>
                      <NomeUsuario>{data.nome}</NomeUsuario>
                      <SituacaoContainer _situacao_={data.situacao}>
                        {SITUACAO[data.situacao]}
                      </SituacaoContainer>
                    </Grid>
                    <CpfCnpj>
                      {formatters.strings.cpfCnpj(data.cpfCnpj)}
                    </CpfCnpj>
                    <Grid container columnSpacing={2}>
                      <Grid item display={"flex"} alignItems={"center"}>
                        <LinkTelefone>
                          <StyledIcon as={WhatsApp} /> {data.telefone}
                        </LinkTelefone>
                      </Grid>
                      <Grid item display={"flex"} alignItems={"center"}>
                        <Email>
                          <StyledIcon as={EmailRounded} />
                          {data.email}
                        </Email>
                      </Grid>
                    </Grid>
                    <NomeParceiro>{data?.nomeFantasia}</NomeParceiro>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  md={4}
                  lg={3}
                  display={"flex"}
                  justifyContent={"center"}
                  item
                >
                  {data?.calculadoraExterna ? (
                    <Button
                      size={"small"}
                      onClick={() => {
                        handleShare();
                      }}
                      variant="outlined"
                      loaderColor="brand"
                    >
                      <LinkIcon />
                      <Text>Calculadora</Text>
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <DadosUsuarioTabConnected />
        </Grid>
      )}
    </CommonPage>
  );
};

export default DetalhesUsuarioPage;
