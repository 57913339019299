import DetalhesOperacaoPage from "./DetalhesOperacaoPage";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetalhesOperacao,
  getDetalhesTab,
  getDialogosDisponiveis,
  getQuantidadeDocumentosPendentesTotal,
  isCarregandoDetalhesOperacao,
} from "../../selectors/operacao.selectors";
import {useCallback, useEffect, useState} from "react";
import { actions as workflowActions } from "../../actions/workflow.actions";
import { DETAIL_TABS } from "../../constants/generic.constants";

const DetalhesOperacaoPageConnected = () => {
  const dispatch = useDispatch();
  const loading = useSelector(isCarregandoDetalhesOperacao);
  const data = useSelector(getDetalhesOperacao);
  const _tab = useSelector(getDetalhesTab);
  const documentosPendentes = useSelector(
    getQuantidadeDocumentosPendentesTotal
  );

  const dialogosDisponiveis = useSelector(getDialogosDisponiveis);

  const [tab, setTab] = useState(_tab);

  useEffect(() => {
    if(data && data?.tipoProduto !== "L") setTab(DETAIL_TABS.DOCUMENTOS);
  }, [data?.tipoProduto]);

  const loadCommentaries = useCallback(() => {
    dispatch(
      workflowActions.carregarComentarios.request({
        id: data?.fluxoTrabalho?.id,
      })
    );
  }, [dispatch, data]);

  const handleTabChange = useCallback(
    (event, newValue) => {
      setTab(newValue);

      if (newValue === DETAIL_TABS.COMENTARIOS) {
        loadCommentaries();
      }
    },
    [setTab, loadCommentaries]
  );

  return (
    <DetalhesOperacaoPage
      tab={tab}
      handleTabChange={handleTabChange}
      loading={loading}
      data={data}
      dialogosDisponiveis={dialogosDisponiveis}
      documentosPendentes={documentosPendentes}
    />
  );
};

export default DetalhesOperacaoPageConnected;
