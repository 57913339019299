import { Card, Form, IconButton, Text, Title, Tooltip } from "../../components";
import { Grid, Skeleton, styled } from "@mui/material";
import React from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Comment, SendOutlined } from "@mui/icons-material";
import formatters from "../../utils/formatters";
import { ControlledTextField } from "../../components/inputs";

const CommentSection = styled(Timeline)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  padding: 0.5rem 1rem;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.colors.gray._50};
  border-radius: 1rem;

  & .MuiTimelineItem-root {
    min-height: max-content;
  }
  & .MuiTimelineItem-missingOppositeContent:before {
    flex: 0;
    padding: 0;
  }
  & .MuiTimelineDot-root {
    background-color: ${({ theme }) => theme.colors.gray._100};
    padding: 6px;
    box-shadow: none;
  }
  & .MuiTimelineConnector-root {
    background-color: transparent;
    border-right: 1px dashed ${({ theme }) => theme.colors.gray._200};
  }
  & .MuiTimelineContent-root {
  }
`;
const MessageIcon = styled(Comment)`
  color: ${({ theme, _tipo_ = "OP", _confidencial_ }) =>
    _confidencial_
      ? theme.colors.error._700
      : _tipo_ === "OP"
      ? theme.colors.secondary._700
      : _tipo_ === "SI"
      ? theme.colors.primary._50
      : _tipo_ === "PA"
      ? theme.colors.primary._500
      : _tipo_ === "CL"
      ? theme.colors.gray._700
      : theme.colors.info._700};
  font-size: 1.2rem;
`;
const DateTimeText = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.gray._300};
  margin: 0.25rem 0 0;
`;
const MessageReason = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.primary._700};
  margin: 0 0 0.25rem;
`;
const MessageText = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.gray._500};
  margin: 0.25rem 0;
  white-space: pre-wrap;
`;
const MessageAuthor = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.gray._200};
  margin: 0;
`;

const getTooltipComentario = ({ tipoUsuario }) => {
  switch (tipoUsuario) {
    case "OP":
      return "Comentário feito pela equipe de operações Taya";
    case "SI":
      return "Comentário feito plea plataforma Taya";
    case "PA":
      return "Comentário feito por um usuário parceiro";
    case "CL":
      return "Comentário feito por um usuário cliente";
    default:
      return "";
  }
};

const SkeletonComentary = () => {
  return (
    <Grid container spacing={2} mt={0.5}>
      <Grid item>
        <Skeleton variant={"circular"} width={36} height={36} />
      </Grid>
      <Grid item>
        <Grid container direction={"column"}>
          <Grid item>
            <Skeleton variant={"text"} width={140} height={20} />
          </Grid>
          <Grid item>
            <Skeleton variant={"text"} width={220} height={20} />
          </Grid>
          <Grid item>
            <Skeleton variant={"text"} width={320} height={40} />
          </Grid>
          <Grid item>
            <Skeleton variant={"text"} width={200} height={20} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ComentariosCard = ({
  comentarios,
  formProps,
  handleSubmit,
  loading,
  submitting,
}) => {
  return (
    <Card>
      <Title as={"h3"}>Comentários</Title>
      {!!loading && (
        <CommentSection>
          <SkeletonComentary />
          <SkeletonComentary />
          <SkeletonComentary />
        </CommentSection>
      )}
      {!loading && comentarios.length === 0 && (
        <Text>Nenhum comentário ainda</Text>
      )}
      {!loading && comentarios.length > 0 && (
        <CommentSection>
          {comentarios.map((comentario, index) => (
            <TimelineItem key={comentario.id}>
              <TimelineSeparator>
                <Tooltip arrow title={getTooltipComentario(comentario)}>
                  <TimelineDot>
                    <MessageIcon
                      _tipo_={comentario.tipoUsuario}
                      _confidencial_={comentario.confidencial}
                    />
                  </TimelineDot>
                </Tooltip>
                {index < comentarios.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <DateTimeText>
                  {formatters.dates.simple(
                    new Date(comentario.dataHoraCriacao),
                    "dd/MM/yyyy HH:mm:ss"
                  )}
                </DateTimeText>
                {!!comentario.descricao && (
                  <MessageReason>{comentario.descricao}</MessageReason>
                )}
                <MessageText>{comentario.texto}</MessageText>
                <MessageAuthor>
                  {comentario.nomeUsuario +
                    (!!comentario.emailUsuario
                      ? ` (${comentario.emailUsuario})`
                      : "")}
                </MessageAuthor>
              </TimelineContent>
            </TimelineItem>
          ))}
        </CommentSection>
      )}
      <Form onSubmit={handleSubmit}>
        <Grid container alignItems={"flex-start"} spacing={1}>
          <Grid item xs={true}>
            <ControlledTextField
              name={"comentario"}
              formProps={formProps}
              multiline
              minRows={2}
              maxRows={5}
            />
          </Grid>
          <Grid item>
            <Tooltip arrow title={"Gravar comentário"}>
              <IconButton loading={submitting} type={"submit"}>
                <SendOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Form>
    </Card>
  );
};

export default ComentariosCard;
