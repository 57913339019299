import CommonPage from "../CommonPage";
import { Badge, Grid, Skeleton, styled, Tab } from "@mui/material";
import React from "react";
import { DETAIL_TABS } from "../../constants/generic.constants";
import DadosLeadTabConnected from "./DadosLeadTab.connected";
import DocumentosTabConnected from "./DocumentosTab.connected";
import DadosOperacaoTabConnected from "./DadosOperacaoTab.connected";
import ComentariosOperacaoTab from "./ComentariosOperacaoTab";
import OperationHeaderConnected from "../../components/presentation/OperationHeader.connected";

const SkeletonCard = styled(Skeleton)`
  width: 100%;
  height: 239px;
  border-radius: 1rem;
`;
const StyledTab = styled(Tab)`
  font-size: ${({ theme }) => theme.fonts.size.parceiro.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 500;
  text-transform: none;
  padding: 0.75rem 0;
  margin-right: 1.5rem;
  overflow: visible;
  color: ${({ theme }) => theme.colors.gray._300};

  &.Mui-selected {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary._400};
  }
`;
const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    margin-right: -4px;
  }
`;

const DetalhesOperacaoPage = ({
  tab,
  handleTabChange,
  loading,
  data,
  dialogosDisponiveis,
  documentosPendentes,
}) => {
  const isLoading = !!loading || !data;

  return (
    <CommonPage selected={"clientes"}>
      {isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OperationHeaderConnected
              data={data}
              detalhes={data.tipoProduto !== "L"}
              timeline
              etapas
              tab={tab}
              handleTabChange={handleTabChange}
            >
              {!!dialogosDisponiveis["DADOS_LEAD"] && (
                <StyledTab value={"0"} label={"Dados Lead"} />
              )}
              {!!dialogosDisponiveis["DADOS_OPERACAO"] && (
                <StyledTab value={"0"} label={"Dados da Operação"} />
              )}
              {data.tipoProduto !== "L" && (
                <StyledTab
                  value={DETAIL_TABS.DOCUMENTOS}
                  label={
                    <StyledBadge
                      variant="dot"
                      badgeContent={documentosPendentes}
                      color="error"
                    >
                      Documentos
                    </StyledBadge>
                  }
                />
              )}
              <StyledTab
                value={DETAIL_TABS.COMENTARIOS}
                label={"Comentários"}
              />
            </OperationHeaderConnected>
          </Grid>
          {!!dialogosDisponiveis["DADOS_LEAD"] && tab === "0" && (
            <DadosLeadTabConnected />
          )}
          {!!dialogosDisponiveis["DADOS_OPERACAO"] && tab === "0" && (
            <DadosOperacaoTabConnected />
          )}
          {tab === DETAIL_TABS.DOCUMENTOS && data.tipoProduto !== "L" && (
            <DocumentosTabConnected />
          )}
          {tab === DETAIL_TABS.COMENTARIOS && <ComentariosOperacaoTab />}
        </Grid>
      )}
    </CommonPage>
  );
};

export default DetalhesOperacaoPage;
