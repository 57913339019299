import React, { useCallback } from "react";
import HubInter from "./HubInter";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isAdicionandoParticipantePreenchimento,
  isAtualizandoPreenchimento,
  isEnviandoPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
  isRemovendoParticipantePreenchimento,
} from "../../../../selectors/operacao.selectors";
import { actions } from "../../../../actions/operacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../../actions/rotas.actions";
import { toast } from "react-toastify";
import { getEtapasAbertas } from "../../../../selectors/workflow.selectors";

const HubInterConnected = ({
  setComprador,
  setStep,
  correntista,
  compradores,
}) => {
  const dispatch = useDispatch();
  const { operacao, dominios, validacao } = useSelector(
    getPreenchimentoOperacao
  );
  const submitting = useSelector(isGravandoPreenchimentoOperacao);
  const sending = useSelector(isEnviandoPreenchimentoOperacao);
  const adding = useSelector(isAdicionandoParticipantePreenchimento);
  const deleting = useSelector(isRemovendoParticipantePreenchimento);
  const refreshing = useSelector(isAtualizandoPreenchimento);
  const etapasAbertas = useSelector(getEtapasAbertas);

  const imovel = {
    ...operacao.imovel,
    fluxo: validacao.completa.imovel,
  };

  const isReenvio = etapasAbertas.find((e) => e.padrao === "RP");

  const handleAcessarParticipante = useCallback(
    (e, c) => {
      e.stopPropagation();
      setComprador(c);
      setStep(1);
    },
    [setComprador, setStep]
  );
  const handleAdicionarParticipante = useCallback(() => {
    dispatch(
      actions.adicionarParticipantePreenchimento.request({
        id: operacao.id,
        tipoParticipante: "CO",
        tipoPessoa: "F",
      })
    );
  }, [dispatch, operacao]);
  const handleRemoverParticipante = useCallback(
    (e, participante) => {
      e.stopPropagation();
      dispatch(
        actions.removerParticipantePreenchimento.request({
          id: operacao.id,
          participante,
        })
      );
    },
    [dispatch, operacao]
  );

  const possuiPendencias =
    compradores.reduce(
      (result, c) =>
        result ||
        c.fluxo.filter(
          (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
        ).length > 0,
      false
    ) ||
    imovel.fluxo.filter(
      (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
    ).length > 0 ||
    validacao[correntista ? "correntista" : "completa"].resumo.situacao ===
      "PARCIAL" ||
    validacao[correntista ? "correntista" : "completa"].resumo.situacao ===
      "PENDENTE";

  const handleGoToSiblings = useCallback(() => {
    toast.success("Proposta enviada para o Crédito.");
    dispatch(
      routeActions.redirectTo(routes.DETALHES_CLIENTE, { id: operacao.cliente })
    );
  }, [dispatch, operacao]);

  const handleAcessarImovel = useCallback(
    (e) => {
      e.stopPropagation();
      setStep(100);
    },
    [setStep]
  );

  const handleGoBack = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.DETALHES_OPERACAO, { id: operacao.id })
    );
  }, [dispatch, operacao]);

  const handleEnviarAnaliseCredito = useCallback(() => {
    dispatch(
      actions.enviarPreenchimento.request({
        id: operacao.id,
        callback: !!isReenvio ? handleGoBack : handleGoToSiblings,
      })
    );
  }, [dispatch, operacao, handleGoToSiblings, isReenvio, handleGoBack]);

  return (
    <HubInter
      compradores={compradores}
      handleAcessarParticipante={handleAcessarParticipante}
      handleEnviarAnaliseCredito={handleEnviarAnaliseCredito}
      possuiPendencias={possuiPendencias}
      submitting={submitting}
      mensagens={validacao[correntista ? "correntista" : "completa"].mensagens}
      adding={adding}
      handleAdicionarParticipante={handleAdicionarParticipante}
      deleting={deleting}
      handleRemoverParticipante={handleRemoverParticipante}
      handleAcessarImovel={handleAcessarImovel}
      refreshing={refreshing}
      sending={sending}
      dominios={dominios}
      imovel={imovel}
      handleGoBack={handleGoBack}
    />
  );
};

export default HubInterConnected;
