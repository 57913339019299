import React, { useCallback, useEffect, useState } from "react";
import OperationHeader from "./OperationHeader";
import {
  parseDateUsingFormat,
  removeNonDigitsFromString,
} from "../../utils/basic";
import { actions } from "../../actions/operacao.actions";
import { actions as usuariosActions } from "../../actions/usuarios.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import { useDispatch, useSelector } from "react-redux";
import { getUsuarioLogado } from "../../selectors/usuario.selectors";
import {
  getDialogAtribuirResponsavel,
  getDialogCompartilhamento,
} from "../../selectors/operacao.selectors";
import { toast } from "react-toastify";
import formatters from "../../utils/formatters";

const OperationHeaderConnected = (props) => {
  const dispatch = useDispatch();
  const usuario = useSelector(getUsuarioLogado);
  const { loading } = useSelector(getDialogCompartilhamento);
  const { data } = useSelector(getDialogAtribuirResponsavel);
  const [previsao, setPrevisao] = useState(
    props.data?.detalhe?.mesPrevisaoPagamento
      ? parseDateUsingFormat(
          props.data?.detalhe?.mesPrevisaoPagamento,
          "yyyy-MM-dd"
        )
      : null
  );

  const handleNameClick = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.DETALHES_CLIENTE, {
        id: props.data.cliente,
      })
    );
  }, [dispatch, props]);

  const compartilharOperacaoClick = useCallback(() => {
    if (!props.data?.codigoOperacaoExterno) {
      dispatch(actions.compartilharOperacao.request({ id: props.data.id }));
    } else {
      dispatch(
        actions.exibirDialogCompartilhamento(
          true,
          props.data.codigoOperacaoExterno
        )
      );
    }
  }, [dispatch, props]);

  const handleChangePrevisaoPagamento = useCallback(
    (v) => {
      setPrevisao(v);
      dispatch(
        actions.atualizarPrevisaoPagamento.request({
          id: props.data.id,
          previsao: formatters.dates.iso(v),
        })
      );
    },
    [dispatch, props, setPrevisao]
  );
  const handleChangeImovelDefinido = useCallback(() => {
    dispatch(
      actions.atualizarImovelDefinido.request({
        id: props.data.id,
        imovelDefinido: !props.data.detalhe.imovelDefinido,
      })
    );
  }, [dispatch, props]);

  const handleChangePermiteAutomacao = useCallback(() => {
    dispatch(
      actions.atualizarPermiteAutomacao.request({
        id: props.data.id,
        permiteAutomacao: !props.data.permiteAutomacao,
      })
    );
  }, [dispatch, props]);

  const handlePhoneClick = useCallback((phone, msg) => {
    const mensagem = encodeURI(
      msg ?? "Olá,\nSou da Taya e vou te ajudar neste processo."
    );

    window.open(
      `https://wa.me/${removeNonDigitsFromString(phone)}?text=${mensagem}`
    );
  }, []);

  const contexto = {
    id_operacao: props.data.id,
  };

  const handleAcaoCallback = useCallback(
    (p) => {
      dispatch(actions.refresh.request({ ...p, id: props.data.id }));
    },
    [dispatch, props]
  );

  const handleAtribuirResponsavel = () => {
    if (data == null || data[0].idParceiro !== props.data.idParceiro) {
      dispatch(
        usuariosActions.listarUsuariosPorParceiro.request(props.data.idParceiro)
      );
    }
    dispatch(actions.exibirDialogAtribuirResponsavel(true, props.data.id));
  };

  const [numeroProposta, setNumeroProposta] = useState({
    open: false,
  });
  const handleCloseNumeroProposta = useCallback(() => {
    setNumeroProposta((v) => ({
      ...v,
      open: false,
    }));
  }, []);
  const handleOpenNumeroProposta = useCallback((values) => {
    setNumeroProposta({
      open: true,
      ...values,
    });
  }, []);

  const handleCopyNumeroProposta = useCallback(async () => {
    try {
      await window.navigator.clipboard.writeText(
        props.data?.detalhe?.numeroProposta || ""
      );
      toast.success("Código da Proposta copiado com sucesso!");
    } catch (e) {
      toast.error("Erro ao copiar Código da Proposta!");
    }
  }, [props.data?.detalhe?.numeroProposta]);
  const handleCopyNumeroContrato = useCallback(async () => {
    try {
      await window.navigator.clipboard.writeText(
        props.data?.detalhe?.numeroContrato || ""
      );
      toast.success("Número do Contrato copiado com sucesso!");
    } catch (e) {
      toast.error("Erro ao copiar Número do Contrato!");
    }
  }, [props.data?.detalhe?.numeroContrato]);

  useEffect(() => {
    setPrevisao(
      props.data?.detalhe?.mesPrevisaoPagamento
        ? parseDateUsingFormat(
            props.data?.detalhe?.mesPrevisaoPagamento,
            "yyyy-MM-dd"
          )
        : null
    );
  }, [setPrevisao, props.data?.detalhe?.mesPrevisaoPagamento]);

  return (
    <OperationHeader
      handleNameClick={handleNameClick}
      handlePhoneClick={handlePhoneClick}
      contextoAcao={contexto}
      handleAcaoCallback={handleAcaoCallback}
      perfil={usuario?.perfil}
      compartilharOperacaoClick={compartilharOperacaoClick}
      handleAtribuirResponsavel={handleAtribuirResponsavel}
      loading={loading}
      usuario={usuario}
      numeroProposta={numeroProposta}
      handleCloseNumeroProposta={handleCloseNumeroProposta}
      handleOpenNumeroProposta={handleOpenNumeroProposta}
      handleCopyNumeroProposta={handleCopyNumeroProposta}
      handleCopyNumeroContrato={handleCopyNumeroContrato}
      handleChangePrevisaoPagamento={handleChangePrevisaoPagamento}
      handleChangeImovelDefinido={handleChangeImovelDefinido}
      handleChangePermiteAutomacao={handleChangePermiteAutomacao}
      previsao={previsao}
      setPrevisao={setPrevisao}
      {...props}
    />
  );
};

export default OperationHeaderConnected;
