import React, { useState } from "react";
import {
  ButtonGroup as MButtonGroup,
  ClickAwayListener,
  Grow,
  Button as MButton,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { styled } from "@mui/material";
import { HorizontalLoader } from "../loaders";
import { transientOptions } from "../../styles/theme";
import MenuItem from "../MenuItem";
import { ArrowDropDown } from "@mui/icons-material";

const StyledButton = styled(MButton, transientOptions)`
  &.MuiButton-contained {
    color: ${({ theme }) => theme.colors.gray._0};
    background-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._500};
    box-shadow: ${({ theme, _shadow_ = "none" }) =>
      theme._shadows.buttons[_shadow_]};
  }
  &.MuiButton-contained.Mui-disabled {
    color: ${({ theme }) => theme.colors.gray._200};
    background-color: ${({ theme }) => theme.colors.gray._100};
  }
  &.MuiButton-contained:hover {
    background-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._600};
  }
  &.MuiButton-outlined {
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._500};
    border-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._500};
    box-shadow: ${({ theme, _shadow_ = "none" }) =>
      theme._shadows.buttons[_shadow_]};
    height: ${({ size = "small" }) =>
      size === "small" ? "30.75px" : size === "medium" ? "36.5px" : "42.25px"};
  }
  &.MuiButton-outlined:hover {
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._600};
    border-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._600};
    background-color: rgba(0, 0, 0, 0.04);
  }
  &.MuiButton-text {
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._500};
  }
  &.MuiButton-text:hover {
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._600};
    background-color: rgba(4, 6, 15, 0.04);
  }
  &.MuiButtonGroup-grouped:not(:first-of-type) {
    padding: 0.25rem 0.25rem 0.25rem 0;
    min-width: 0;
  }

  border-radius: ${({ theme }) => theme.borderRadiuses.buttons};
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.family.buttons};

  &:focus {
    outline: 0;
  }
`;

const StyledButtonGroup = styled(MButtonGroup, transientOptions)`
  &.MuiButton-contained {
    color: ${({ theme }) => theme.colors.gray._0};
    background-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._500};
    box-shadow: ${({ theme, _shadow_ = "none" }) =>
      theme._shadows.buttons[_shadow_]};
  }
  &.MuiButton-contained.Mui-disabled {
    color: ${({ theme }) => theme.colors.gray._200};
    background-color: ${({ theme }) => theme.colors.gray._100};
  }
  &.MuiButton-contained:hover {
    background-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._600};
  }
  &.MuiButton-outlined {
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._500};
    border-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._500};
    box-shadow: ${({ theme, _shadow_ = "none" }) =>
      theme._shadows.buttons[_shadow_]};
  }
  &.MuiButton-outlined:hover {
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._600};
    border-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._600};
    background-color: rgba(0, 0, 0, 0.04);
  }
  &.MuiButton-text {
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._500};
  }
  &.MuiButton-text:hover {
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._600};
    background-color: rgba(4, 6, 15, 0.04);
  }

  border-radius: ${({ theme }) => theme.borderRadiuses.buttons};
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.family.buttons};

  &:focus {
    outline: 0;
  }
`;

const SplitButton = React.forwardRef(
  (
    {
      loading,
      disabled,
      loaderColor = "white",
      loaderSize = 24,
      variant = "contained",
      options = [],
      size,
      onClick,
      ...otherProps
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const anchorRef = React.useRef(null);

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };

    const handleMenuItemClick = (index) => {
      setSelectedIndex(index);
      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    return (
      <>
        <StyledButtonGroup
          {...otherProps}
          ref={anchorRef}
          disabled={disabled || !!loading}
          variant={variant}
          size={size}
        >
          <StyledButton
            onClick={() => onClick(options[selectedIndex].autofill)}
            size={size}
          >
            {!!loading ? (
              <HorizontalLoader color={loaderColor} size={loaderSize} />
            ) : (
              options[selectedIndex].text
            )}
          </StyledButton>
          <StyledButton
            ref={anchorRef}
            variant={variant}
            size={size}
            onClick={handleToggle}
          >
            <ArrowDropDown />
          </StyledButton>
        </StyledButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          anchorEl={anchorRef.current}
          open={open}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={index}
                        selected={index === selectedIndex}
                        onClick={() => handleMenuItemClick(index)}
                      >
                        {option.text}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }
);

export default SplitButton;
