import React from "react";
import DadosBasicosItauFormConnected from "./DadosBasicosItauForm.connected";
import EscolhaParticipanteItauFormConnected from "./EscolhaParticipanteItauForm.connected";
import DadosProfissionaisItauFormConnected from "./DadosProfissionaisItauForm.connected";
import DadosEstadoCivilItauFormConnected from "./DadosEstadoCivilItauForm.connected";

const FluxoAquisicaoItau = (props) => {
  return (
    <>
      {props.step === 0 && <EscolhaParticipanteItauFormConnected {...props} />}
      {props.step === 1 && <DadosBasicosItauFormConnected {...props} />}
      {props.step === 2 && <DadosProfissionaisItauFormConnected {...props} />}
      {props.step === 3 && <DadosEstadoCivilItauFormConnected {...props} />}
    </>
  );
};

export default FluxoAquisicaoItau;
