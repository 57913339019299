import {
  Drawer,
  Grid,
  IconButton,
  keyframes,
  Skeleton,
  styled,
} from "@mui/material";
import React from "react";
import { Button, Card, MenuItem, Title, Tooltip } from "../../components";
import { TIPO_PRAZO } from "./ParametrosSimulacaoForm";
import formatters from "../../utils/formatters";
import { transientOptions } from "../../styles/theme";
import {
  ArrowBack,
  Autorenew,
  Block,
  CheckBox,
  CheckBoxOutlineBlank,
  FileDownload,
  HelpOutline,
  WhatsApp,
} from "@mui/icons-material";
import { TextField } from "../../components/inputs";
import useResponsive from "../../hooks/useResponsive";
import EnviarViaWhatsAppDialogDialogConnected from "../workflow/EnviarViaWhatsAppDialog.connected";
import BaseButton from "../../components/buttons/BaseButton";
import { logos } from "../../constants/generic.constants";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;
const Logo = styled("img")`
  width: 64px;
  height: 64px;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray._100};
`;
const StyledDrawer = styled(Drawer, transientOptions)`
  & .MuiDrawer-paper {
    overflow-y: visible;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  ${({ theme, _isMenuOpen_ }) => `${theme.breakpoints.up("sm")} {
  	& .MuiDrawer-paper {
			left: ${
        _isMenuOpen_
          ? theme.sizes.lateralMenu
          : theme.sizes.lateralMenuCollapsed
      };
		}
	`}
`;
const GoBackButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.gray._300};
  & .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.fonts.size.large};
  }

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    & .MuiSvgIcon-root {
			font-size: ${theme.fonts.size.xlarge};
		}
  }`}
`;
const TitleText = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.gray._300};
  font-weight: 700;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    font-size: ${theme.fonts.size.large};
  }`}
`;
const FieldName = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray._200};
  margin-bottom: ${({ _small_ }) => (_small_ ? "4px" : "0")};
  margin-right: 0.25rem;
`;
const FieldValue = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme, _small_ }) =>
    _small_ ? theme.fonts.size.default : theme.fonts.size.large};
  font-weight: 700;
`;
const Description = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray._400};
  margin-top: 0.5rem;
  white-space: pre-wrap;
`;
const NomeInstituicao = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray._700};
`;
const NomeCondicao = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secondary._700};
`;
const OpcaoContainer = styled("div")`
  padding: 1rem 0;

  & + & {
    border-top: dashed 1px ${({ theme }) => theme.colors.gray._100};
  }
`;

const ResultFieldName = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray._200};
  margin-bottom: ${({ _small_ }) => (_small_ ? "4px" : "0")};
`;
const Disclaimer = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray._400};
  margin: 0;
`;
const ResultFieldValue = styled("div", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme, _altered_ }) =>
    _altered_ ? theme.colors.info._600 : theme.colors.gray._700};
  font-weight: 700;
  margin-top: 3px;
  white-space: pre-wrap;
`;
const ResultHelpButton = styled(IconButton, transientOptions)`
  padding: 0;
  color: ${({ theme, _color_ = "info" }) =>
    _color_ === "gray" ? theme.colors.gray._200 : theme.colors[_color_]._600};
  margin-top: ${({ _color_ = "info" }) =>
    _color_ === "gray" ? "-3px" : "3px"};
  & .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.fonts.size.default};
  }
`;
const ActionContainer = styled(IconButton, transientOptions)`
  width: 64px;
  height: 64px;
  border-radius: 1rem;
  background-color: ${({ theme, _selected_ }) =>
    _selected_ ? theme.colors.primary._500 : theme.colors.gray._50};
  color: ${({ theme, _selected_ }) =>
    _selected_ ? theme.colors.primary._50 : theme.colors.gray._400};

  &:hover {
    background-color: ${({ theme, _selected_ }) =>
      _selected_ ? theme.colors.primary._500 : theme.colors.gray._50};
    color: ${({ theme, _selected_ }) =>
      _selected_ ? theme.colors.primary._50 : theme.colors.gray._400};
  }
`;
const SelectButton = styled(BaseButton)`
  & div {
    margin-left: 0.5rem;
    margin-top: 2px;
  }
`;
const RotatingIcon = styled(Autorenew)`
  animation: ${rotate} 2s linear infinite;
`;

const DESCRICOES = {
  tr: {
    titulo: "Taxa + TR",
    descricao: (
      <Description>
        Taxa de juros fixa +TR : são taxas que não irá variar ao longo do tempo,
        ela será a mesma desde a sua contratação e a Taxa Referencial (TR) é que
        irá atualizar o saldo devedor mensalmente pela TR. Nesta linha de
        crédito encontramos taxas a partir de 10,49% ao ano + TR até 12,000 %+
        ao ano + TR dependendo a instuição
      </Description>
    ),
  },
  poupanca: {
    titulo: "Taxa + Poupança",
    tooltip:
      "Crédito Imobiliário com juros da poupança:\n" +
      "Nesta modalidade a sua taxa acompanha o rendimento da poupança, assim você economiza quando ela está baixa e conta com um teto caso ela suba.\n" +
      "A taxa é composta pela soma de uma parte fixa e outra variável. A parte fixa depende do seu tipo de conta e do relacionamentodo cliente com a istuição. \n" +
      "Já a parte variável corresponde ao rendimento da poupança, ou seja, depende da variação da Selic. A regra é que quando a Selic está menor que 8,50% ao ano a poupança equivale a 70% do valor da Selic, agora quando ela passa desse valor, o rendimento da poupança é travado em 6,17% ao ano, que quando somado com a sua parte fixa resulta no teto da taxa.\n" +
      "O valor da taxa Selic é decidido a cada 45 dias pelo Comitê de Política Monetária do Banco Central (COPOM).\n" +
      "Ou seja, a taxa final é a soma da parte fixa definida na sua contratação com o rendimento da poupança. Nesta linha o seu saldo devedor também será atualizado pela Taxa Referencial (TR).",
    descricao: (
      <Description>
        Taxa + Poupança: trata-se de uma linha de crédito que acompanha o
        rendimento da poupança, assim a taxa será a soma do rendimento da
        poupança mais Taxa de Juros a partir de 5,72% ao ano. Hoje, com essa
        linha de crédito, você conta com taxas a partir de 11,89% ao ano e
        poderá pagar menos juros quando o rendimento da poupança está baixo e
        conta com um teto caso o rendimento suba. Isso significa que se o
        rendimento da poupança subir, ele chegará no máximo a 6,17% ao ano
        somado a taxa fixa de 5,72%% ao ano. Isso garante uma taxa máxima de
        11,89% a.a. Importante o saldo devedor do cliente tambem será atualizado
        mensalmente pela Taxa Referencial (TR).
      </Description>
    ),
  },
  ipca: {
    titulo: "Taxa + IPCA",
    descricao: (
      <Description>
        Taxa Pós-fixada + IPCA: No modelo de contrato com a taxa pós-fixada, as
        parcelas passam pela manutenção mensal da correção monetária, de acordo
        com o índice de inflação adotado no contrato. O Índice de Preços ao
        Consumidor Amplo (IPCA) é usado para medir a inflação no Brasil. A base
        usada para chegar ao valor desse índice são cálculos feitos pelo
        Instituto Brasileiro de Geografia e Estatística (IBGE).
      </Description>
    ),
  },
  pref: {
    titulo: "Taxa Fixa",
    descricao: (
      <Description>
        Taxa Fixa: No modelo de contrato com a taxa pré-fixada, as parcelas são
        fixas e sem os efeitos de manutenção da correção monetária, já que uma
        taxa de juros única e fixa é determinada no contrato.
      </Description>
    ),
  },
  cdi: {
    titulo: "Taxa + CDI",
    descricao: (
      <Description>
        Taxa de juros + CDI : 100% do CDI, isso quer dizer que ele somar Juros +
        CDO. Para saber a taxa DI de dias ou períodos anteriores, é possível
        usar a Calculadora do Cidadão do Banco Central. Para isso, basta
        preencher o período para ter acesso ao valor percentual correspondente.
      </Description>
    ),
  },
};

const formatCarencia = (c) => {
  if (c === 0) {
    return "Sem carência";
  }
  if (c === 1) {
    return "1 mês";
  }
  return `${c} meses`;
};

const Field = ({ name, value, tooltip, small = false }) => {
  return (
    <Grid display={"flex"} flexDirection={"column"}>
      <Grid container>
        <Grid item>
          <FieldName _small_={small}>{name}</FieldName>
        </Grid>
        {tooltip && (
          <Grid item>
            <Tooltip arrow title={tooltip}>
              <ResultHelpButton _color_={"gray"}>
                <HelpOutline />
              </ResultHelpButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <FieldValue _small_={small}>{value}</FieldValue>
    </Grid>
  );
};

const Result = ({
  name,
  value,
  tooltip,
  listaModificacoes = [],
  modificacoes = [],
}) => {
  const altered = listaModificacoes.find((m) => modificacoes.indexOf(m) >= 0);

  return (
    <Grid container direction={"column"}>
      <Grid item container spacing={0.5} alignItems={"center"}>
        <Grid item>
          <ResultFieldName>{name}</ResultFieldName>
        </Grid>
        {tooltip && (
          <Grid item>
            <Tooltip arrow title={tooltip}>
              <ResultHelpButton _color_={"gray"}>
                <HelpOutline />
              </ResultHelpButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <ResultFieldValue _altered_={altered}>{value}</ResultFieldValue>
        </Grid>
        {altered && (
          <Grid item>
            <Tooltip
              arrow
              title={
                "Valor foi alterado de acordo com a política de crédito da instituição"
              }
            >
              <ResultHelpButton>
                <HelpOutline />
              </ResultHelpButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const SkeletonResult = () => {
  return (
    <Grid container direction={"column"}>
      <Grid item>
        <Skeleton variant={"rectangular"} width={120} height={14} />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item mt={0.5}>
          <Skeleton variant={"rectangular"} width={180} height={20} mt />
        </Grid>
      </Grid>
    </Grid>
  );
};

const Opcao = ({
  produto,
  condicao,
  handleDownload,
  handleRecalculate,
  downloading,
  recalculating,
  handleWhatsApp,
  currentSize,
  addToSelecionados,
  removeFromSelecionados,
  selecionados,
  ...data
}) => {
  const isSelecionado = selecionados.find((i) => i.id === data.id);
  const isInstituicaoSelecionada = selecionados.find(
    (i) => i.codigoInstituicao === data.codigoInstituicao
  );

  return (
    <OpcaoContainer>
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2} alignItems={"center"}>
          <Grid item container xs={12} md={6} spacing={2} alignItems={"center"}>
            <Grid item>
              <Logo src={logos[data.codigoInstituicao]} />
            </Grid>
            <Grid item display={"flex"} flexDirection={"column"}>
              <NomeInstituicao>{data.nomeInstituicao}</NomeInstituicao>
              <NomeCondicao>{data.nomeCondicao}</NomeCondicao>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            spacing={2}
            alignItems={"center"}
            justifyContent={
              ["xs", "sm"].indexOf(currentSize) >= 0 ? "flex-start" : "flex-end"
            }
          >
            <Grid item>
              <Tooltip arrow title={"Enviar via WhatsApp"}>
                <ActionContainer onClick={() => handleWhatsApp(data)}>
                  <WhatsApp />
                </ActionContainer>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip arrow title={"Baixar extrato da simulação"}>
                {!!downloading.find((d) => d === data.id) ? (
                  <ActionContainer onClick={() => handleDownload(data)}>
                    <RotatingIcon />
                  </ActionContainer>
                ) : (
                  <ActionContainer onClick={() => handleDownload(data)}>
                    <FileDownload />
                  </ActionContainer>
                )}
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={true} container spacing={2}>
          <Grid item xs={6}>
            <Result
              name={
                produto.tipo === "H"
                  ? "Valor do Empréstimo"
                  : "Valor do Financiamento"
              }
              listaModificacoes={data.entrada.modificacoes}
              modificacoes={[
                "valorFinanciamento",
                "valorFinanciamentoLtv",
                "valorFinanciamentoRenda",
              ]}
              value={`R$ ${formatters.numbers.currency(
                data.entrada.valorFinanciamento
              )}`}
            />
          </Grid>

          {data.entrada.financiaCustas && (
            <Grid item xs={6}>
              <Result
                name={`Despesas financiadas`}
                value={`R$ ${formatters.numbers.currency(
                  data.entrada.valorCustas
                )}`}
              />
            </Grid>
          )}
          {data.entrada.calculaIof && (
            <Grid item xs={6}>
              <Result
                name={`Valor do IOF`}
                value={`R$ ${formatters.numbers.currency(
                  data.entrada.valorIof
                )}`}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Result
              name={"Tarifa de Avaliação"}
              value={`R$ ${formatters.numbers.currency(
                data.entrada.valorTarifaEmissao
              )}`}
            />
          </Grid>
          <Grid item xs={6}>
            <Result
              name={"Tarifa de Emissão do Contrato"}
              value={`R$ ${formatters.numbers.currency(
                data.entrada.valorTarifaRegistro
              )}`}
            />
          </Grid>
          <Grid item xs={6}>
            <Result
              name={
                produto.tipo === "H"
                  ? "Valor do Total Empréstimo"
                  : "Valor do Total Financiamento"
              }
              listaModificacoes={data.entrada.modificacoes}
              modificacoes={[
                "valorFinanciamento",
                "valorFinanciamentoLtv",
                "valorFinanciamentoRenda",
              ]}
              value={`R$ ${formatters.numbers.currency(
                data.entrada.valorTotal
              )}`}
            />
          </Grid>
          {produto.tipo === "I" && (
            <Grid item xs={6}>
              <Result
                name={"Valor da Entrada"}
                tooltip={
                  "A entrada deve ser de, no mínimo, 10% a 30% do valor do imóvel para imóveis residenciais (sujeito à análise de crédito). Você pode somar seu dinheiro e seu fundo de garantia (FGTS) como entrada. Para usar o FGTS, o financiamento deve estar enquadrado nas regras da Caixa Econômica Federal."
                }
                value={`R$ ${formatters.numbers.currency(
                  data.entrada.valorImovel - data.entrada.valorFinanciamento
                )}`}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Result
              name={`Prazo`}
              listaModificacoes={data.entrada.modificacoes}
              modificacoes={["prazoDesejado"]}
              value={`${data.entrada.prazoDesejado} ${
                TIPO_PRAZO[condicao.tipoPrazo]
              }`}
            />
          </Grid>
          {condicao.permiteFinanciamentoIof && (
            <Grid item xs={6}>
              <Result
                name={`Financiar IOF?`}
                listaModificacoes={data.entrada.modificacoes}
                modificacoes={["financiaIof"]}
                value={data.entrada.financiaIof ? "Sim" : "Não"}
              />
            </Grid>
          )}
          {condicao.permiteFinanciamentoTarifaEmissao && (
            <Grid item xs={6}>
              <Result
                name={`Financiar tarifa de Avaliação?`}
                listaModificacoes={data.entrada.modificacoes}
                modificacoes={["financiaTarifaEmissao"]}
                value={data.entrada.financiaTarifaEmissao ? "Sim" : "Não"}
              />
            </Grid>
          )}

          {condicao.permiteFinanciamentoTarifaRegistro && (
            <Grid item xs={6}>
              <Result
                name={`Financiar Tarifa de Emissão do Contrato?`}
                listaModificacoes={data.entrada.modificacoes}
                modificacoes={["financiaTarifaRegistro"]}
                value={data.entrada.financiaTarifaRegistro ? "Sim" : "Não"}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={true} container spacing={2}>
          <Grid item xs={12}>
            <Grid container direction={"column"} maxWidth={200}>
              <Grid item container spacing={0.5} alignItems={"center"}>
                <Grid item>
                  <ResultFieldName>Tipo de Amortização</ResultFieldName>
                </Grid>
                <Grid item>
                  <Tooltip
                    arrow
                    title={
                      "Tabela PRICE: Onde você paga o mesmo valor de parcela durante todo período de duração do contrato. SAC (Sistema de Amortização Constante): Onde o valor a ser abatido do saldo devedor é constante e os juros são calculados sob o saldo devedor atualizado de cada mês, que tende a diminuir mais rápido."
                    }
                  >
                    <ResultHelpButton _color_={"gray"}>
                      <HelpOutline />
                    </ResultHelpButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  label={null}
                  size={"small"}
                  select
                  onChange={(e) =>
                    handleRecalculate(data, { tipoAmortizacao: e.target.value })
                  }
                  disabled={data.tiposAmortizacao.length === 1}
                  margin={"none"}
                  value={data.entrada.tipoAmortizacao}
                >
                  {data.tiposAmortizacao.map((t) => (
                    <MenuItem key={t} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction={"column"} maxWidth={250}>
              <Grid item container spacing={0.5} alignItems={"center"}>
                <Grid item>
                  <ResultFieldName>Seguradora</ResultFieldName>
                </Grid>
                <Grid item>
                  <Tooltip
                    arrow
                    title={
                      "No financiamento de um imóvel é obrigatória a contratação de um seguro com duas coberturas: Uma que quita o saldo proporcional à participação do comprador no financiamento, caso ele venha a falecer ou ficar incapacitado de exercer a sua profissão, MIP (Morte e Invalidez Permanente). E outra que cobre eventuais danos estruturais que possam ocorrer ao imóvel no período que estiver financiado, exceto problemas naturais da sua construção, DFI (Danos Físicos do Imóvel). Na contratação do seu financiamento você pode escolher as seguradoras disponiveis, ou ainda sugerir uma terceira atuante no mercado."
                    }
                  >
                    <ResultHelpButton _color_={"gray"}>
                      <HelpOutline />
                    </ResultHelpButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  label={null}
                  size={"small"}
                  select
                  onChange={(e) =>
                    handleRecalculate(data, { seguradora: e.target.value })
                  }
                  disabled={data.seguradoras.length === 1}
                  margin={"none"}
                  value={data.entrada.seguradora}
                >
                  {data.seguradoras.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {s.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction={"column"} maxWidth={250}>
              <Grid item container spacing={0.5} alignItems={"center"}>
                <Grid item>
                  <ResultFieldName>Oferta</ResultFieldName>
                </Grid>
                {data.ofertas.length > 1 &&
                  data.codigoInstituicao === "santander" && (
                    <Grid item>
                      <Tooltip
                        arrow
                        title={
                          "Crédito Imobiliário com taxa de juros Bonificada\n" +
                          "Entenda como você pode ser elegível a taxa de juros Bonificado na hora de adquirir seu imóvel.\n" +
                          "O que é taxa de juros bonificada?  \n" +
                          "\n" +
                          "Trata-se de um benefício concedido pelo Santander, que concede a taxa de juros reduzida para o cálculo das doze (12) primeiras prestações do financiamento imobiliário, na modalidade Parcelas Atualizáveis, e poderá ser prorrogada a cada período de seis (6) meses de acordo com o cumprimento das condições de relacionamento. As condições variam de acordo com o perfil de renda do cliente.\n" +
                          "\n" +
                          "Veja as condições:\n" +
                          "Para profissional Assalariado\n" +
                          "\n" +
                          " 1. Receber o Salário (sem portabilidade para outra instituição) ou Portabilidade de Salário para conta corrente mantida no Santander; e2. Manter-se adimplente com o pagamento mensal das prestações do seu financiamento imobiliário, na data de vencimento, por meio de débito automático em sua conta corrente mantida no Santander; e\n" +
                          "3.Possuir, no mínimo, 1 (um) dos seguintes produtos ou serviços contratados com o Santander:\n" +
                          "\n" +
                          "a) Ser titular de Cartão de Crédito Santander e realizar uma nova compra de qualquer valor na função crédito em cada fatura mensal; ou\n" +
                          "b) Seguro de Vida; ou\n" +
                          "c) Seguro Residencial; ou  \n" +
                          "d) Seguro de Acidentes Pessoais.\n" +
                          "\n" +
                          "Para trabalhador Autônomo ou Profissional Liberal\n" +
                          "\n" +
                          "1.⁠ ⁠Manter-se adimplente com o pagamento mensal das prestações do seu financiamento imobiliário, na data de vencimento, por meio de débito automático em sua conta corrente mantida no Santander; e\n" +
                          "2.⁠ ⁠Ser titular de Cartão de Crédito Santander e realizar uma nova compra de qualquer valor na função crédito em cada fatura mensal; e\n" +
                          "3.⁠ ⁠Possuir, no mínimo, 1 (um) dos seguintes produtos ou serviços contratados com o Santander:\n" +
                          "\n" +
                          "a) Seguro de Vida; ou\n" +
                          "b) Seguro Residencial; ou\n" +
                          "c) Seguro de Acidentes Pessoais; ou\n" +
                          "d) Título de Capitalização.\n"
                        }
                      >
                        <ResultHelpButton _color_={"gray"}>
                          <HelpOutline />
                        </ResultHelpButton>
                      </Tooltip>
                    </Grid>
                  )}
              </Grid>
              <Grid item>
                <TextField
                  label={null}
                  size={"small"}
                  select
                  onChange={(e) =>
                    handleRecalculate(data, { oferta: e.target.value })
                  }
                  disabled={data.ofertas.length === 1}
                  margin={"none"}
                  value={data.entrada.oferta}
                >
                  {data.ofertas.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {s.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction={"column"} maxWidth={250}>
              <Grid item>
                <ResultFieldName>Carência</ResultFieldName>
              </Grid>
              <Grid item>
                <TextField
                  label={null}
                  size={"small"}
                  select
                  onChange={(e) =>
                    handleRecalculate(data, { carencia: e.target.value })
                  }
                  disabled={data.carencias.length === 1}
                  margin={"none"}
                  value={data.entrada.carencia}
                >
                  {data.carencias.map((c) => (
                    <MenuItem key={c} value={c}>
                      {formatCarencia(c)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={true} container spacing={2}>
          <Grid item xs={6} md={3} lg={6}>
            {!!recalculating.find((d) => d === data.id) ? (
              <SkeletonResult />
            ) : (
              <Result
                name={"Primeira Parcela"}
                tooltip={
                  "O valor da parcela é meramente indicativo e será atualizado na data de vencimento da prestação conforme previsto em contrato."
                }
                value={`R$ ${formatters.numbers.currency(
                  data.primeiraParcela
                )}`}
              />
            )}
          </Grid>
          <Grid item xs={6} md={3} lg={6}>
            {!!recalculating.find((d) => d === data.id) ? (
              <SkeletonResult />
            ) : (
              <Result
                name={"Última Parcela"}
                value={`R$ ${formatters.numbers.currency(data.ultimaParcela)}`}
              />
            )}
          </Grid>
          <Grid item xs={6} md={3} lg={6}>
            {!!recalculating.find((d) => d === data.id) ? (
              <SkeletonResult />
            ) : (
              <Result
                name={"Taxa de Juros Efetiva"}
                value={`${formatters.numbers.currency(
                  data.entrada.taxaJurosAnual
                )} % a.a.\n${formatters.numbers.currency(
                  data.entrada.taxaJurosMensal
                )} % a.m.`}
              />
            )}
          </Grid>
          <Grid item xs={6} md={3} lg={6}>
            {!!recalculating.find((d) => d === data.id) ? (
              <SkeletonResult />
            ) : (
              <Result
                name={"CET (Custo Efetivo Total)"}
                tooltip={
                  "O custo Efetivo Total é o custo total do financiamento, considerando juros, seguros, tarifas e demais despesas. No CET a (se houver a TR ela não é considerada no calculo), nos termos da Resolução CMN 3517. O valor é representado por um percentual anual e está expresso em todos os simuladores e no contrato de financiamento e empréstimos"
                }
                value={`${formatters.numbers.currency(data.cetAnual)} % a.a.`}
              />
            )}
          </Grid>
          <Grid item xs={6} md={3} lg={6}>
            {!!recalculating.find((d) => d === data.id) ? (
              <SkeletonResult />
            ) : (
              <Result
                name={"CESH"}
                tooltip={
                  "É uma taxa obrigatória que corresponde à cobertura de Morte e Invalidez Permanente (MIP) e Danos Físicos ao Imóvel (DFI). Antes da contratação do seguro habitacional, a seguradora deve informar o valor do Custo Efetivo do Seguro Habitacional (CESH) ao interessado."
                }
                value={`${formatters.numbers.currency(data.cesh)} %`}
              />
            )}
          </Grid>
          <Grid item xs={6} md={3} lg={6}>
            {!!recalculating.find((d) => d === data.id) ? (
              <SkeletonResult />
            ) : (
              <Result
                name={"Renda Mínima"}
                value={`R$ ${formatters.numbers.currency(data.rendaMinima)}`}
              />
            )}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          lg={"auto"}
          justifyContent={"center"}
          alignItems={"center"}
          container
          spacing={2}
        >
          <Grid item>
            <Tooltip
              arrow
              title={
                !isSelecionado && isInstituicaoSelecionada
                  ? "Só é possível selecionar 1 condição comercial por instituição financeira"
                  : ""
              }
            >
              <span>
                <SelectButton
                  onClick={() =>
                    isSelecionado
                      ? removeFromSelecionados(data)
                      : !isInstituicaoSelecionada
                      ? addToSelecionados(data)
                      : null
                  }
                  disabled={!isSelecionado && !!isInstituicaoSelecionada}
                >
                  {!isSelecionado && isInstituicaoSelecionada ? (
                    <Block />
                  ) : isSelecionado ? (
                    <CheckBox />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                  <div>SELECIONAR</div>
                </SelectButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </OpcaoContainer>
  );
};

const Grupo = ({ tipo, opcoes, ...other }) => {
  return (
    <Grid item xs={12}>
      <Card>
        <Grid container spacing={0}>
          <Grid item>
            <Title as={"h3"} _margin_={"0 0.25rem 0 0"}>
              {DESCRICOES[tipo].titulo}
            </Title>
          </Grid>
          {DESCRICOES[tipo].tooltip && (
            <Grid item>
              <Tooltip arrow title={DESCRICOES[tipo].tooltip}>
                <ResultHelpButton _color_={"secondary"}>
                  <HelpOutline />
                </ResultHelpButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        {DESCRICOES[tipo].descricao}
        {opcoes.map((opcao) => {
          return <Opcao key={opcao.id} {...other} {...opcao} />;
        })}
      </Card>
    </Grid>
  );
};

const ResultadoSimulacaoSection = ({
  data,
  isMenuOpen,
  produto,
  condicao,
  handleGoBack,
  handleNext,
  handleValidarRegras,
  dadosWhatsApp,
  handleCloseWhatsApp,
  validating,
  ...other
}) => {
  const currentSize = useResponsive();

  return (
    <>
      <Grid
        container
        spacing={3}
        direction={"column"}
        mb={other.selecionados.length > 0 ? 8 : 0}
      >
        <Grid item container alignItems={"center"}>
          <Grid item mr={1}>
            <GoBackButton onClick={handleGoBack}>
              <ArrowBack />
            </GoBackButton>
          </Grid>
          <Grid item flex={1}>
            <TitleText>Encontramos a menor parcela para você</TitleText>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <Card>
              <Title as={"h3"} _margin_={"0 0 1rem 0"}>
                Valores solicitados
              </Title>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={true}>
                  <Field
                    name={"Valor do Imóvel"}
                    value={`R$ ${formatters.numbers.currency(
                      data.entrada.valorImovel
                    )}`}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={true}>
                  <Field
                    name={
                      produto.tipo === "H"
                        ? "Valor do Empréstimo"
                        : "Valor do Financiamento"
                    }
                    value={`R$ ${formatters.numbers.currency(
                      data.entrada.valorFinanciamento
                    )}`}
                  />
                </Grid>
                {produto.tipo === "I" && (
                  <Grid item xs={12} md={4} lg={true}>
                    <Field
                      name={"Valor da Entrada"}
                      tooltip={
                        "A entrada deve ser de, no mínimo, 10% a 30% do valor do imóvel para imóveis residenciais (sujeito à análise de crédito). Você pode somar seu dinheiro e seu fundo de garantia (FGTS) como entrada. Para usar o FGTS, o financiamento deve estar enquadrado nas regras da Caixa Econômica Federal."
                      }
                      value={`R$ ${formatters.numbers.currency(
                        (condicao.permiteFinanciamentoCustas &&
                        data.entrada.financiaCustas
                          ? 0.95
                          : 1) *
                          data.entrada.valorImovel -
                          data.entrada.valorFinanciamento
                      )}`}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={4} lg={true}>
                  <Field
                    name={`Prazo`}
                    value={`${data.entrada.prazoDesejado} ${
                      TIPO_PRAZO[condicao.tipoPrazo]
                    }`}
                  />
                </Grid>
                {condicao.permiteFinanciamentoCustas && (
                  <Grid item xs={12} md={4} lg={true}>
                    <Field
                      name={`Financiar despesas?`}
                      value={data.entrada.financiaCustas ? "Sim" : "Não"}
                    />
                  </Grid>
                )}
                {condicao.permiteFinanciamentoIof && (
                  <Grid item xs={12} md={4} lg={true}>
                    <Field
                      name={`Financiar IOF?`}
                      value={data.entrada.financiaIof ? "Sim" : "Não"}
                    />
                  </Grid>
                )}
                {condicao.permiteFinanciamentoTarifaEmissao && (
                  <Grid item xs={12} md={4} lg={true}>
                    <Field
                      name={`Financiar Tarifa de Avaliação?`}
                      value={data.entrada.financiaTarifaEmissao ? "Sim" : "Não"}
                    />
                  </Grid>
                )}
                {condicao.permiteFinanciamentoTarifaRegistro && (
                  <Grid item xs={12} md={4} lg={true}>
                    <Field
                      name={`Financiar Tarifa de Emissão do Contrato?`}
                      value={
                        data.entrada.financiaTarifaRegistro ? "Sim" : "Não"
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          {data.grupos.map((grupo) => {
            return (
              <Grupo
                key={grupo.tipo}
                produto={produto}
                condicao={condicao}
                currentSize={currentSize}
                {...other}
                {...grupo}
              />
            );
          })}
        </Grid>
        <Grid item container spacing={3}>
          <Grid item>
            <Disclaimer>
              Avise ao cliente que, ao realizar a simulação, as instituições
              também poderão contatá-lo por meio de cartas, e-mails, WhatsApp,
              SMS e telefone sobre o processo de contratação de crédito. Os
              resultados exibidos referem-se a simulação. As taxas de juros são
              apenas referenciais, podendo variar conforme perfil de
              crédito/aprovação do cliente. O valor da parcela apresentado
              sofrerá alterações de acordo com o valor da avaliação do
              imóvel/bem em garantia. O valor do seguro DFI também está sujeito
              a alteração, pois ele incide sobre o valor de avaliação do
              imóvel/bem em garantia. As condições exibidas poderão ser
              alteradas ou descontinuadas a qualquer momento, sem aviso prévio.
              Em caso de a tarifa de avaliação não ser incorporada ao
              financiamento, ela será debitada integralmente da conta informada
              pelo cliente.
            </Disclaimer>
          </Grid>
        </Grid>
        <StyledDrawer
          _isMenuOpen_={isMenuOpen}
          variant={"persistent"}
          anchor={"bottom"}
          open={other.selecionados.length > 0}
        >
          {other.selecionados.length > 0 && (
            <Grid
              container
              my={2}
              columnSpacing={3}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                <Button onClick={handleValidarRegras} loading={validating}>
                  REVISAR CONDIÇÕES SELECIONADAS
                </Button>
              </Grid>
            </Grid>
          )}
        </StyledDrawer>
      </Grid>
      <EnviarViaWhatsAppDialogDialogConnected
        dados={dadosWhatsApp}
        handleClose={handleCloseWhatsApp}
      />
    </>
  );
};

export default ResultadoSimulacaoSection;
