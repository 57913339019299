import CommonPage from "../CommonPage";
import { Card, Title } from "../../components";
import {
  Grid,
  InputAdornment,
  MenuItem as MMenuItem,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  BodyTableRow,
  SizedTableCell,
  Table,
  TablePagination,
} from "../../components/tables";
import formatters from "../../utils/formatters";
import { SITUACAO } from "../../constants/parceiro.constants";
import { TextField } from "../../components/inputs";
import { Search } from "@mui/icons-material";
import React from "react";
import { HorizontalLoader } from "../../components/loaders";

const CardHeader = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
const SituacaoParceiroContainer = styled("span")`
  padding: 0.5rem 0.75rem;
  display: inline-block;
  font-weight: 700;
  color: ${({ theme, _situacao_ }) =>
    _situacao_ === "A"
      ? theme.colors.success._600
      : _situacao_ === "C"
      ? theme.colors.error._600
      : _situacao_ === "S"
      ? theme.colors.gray._600
      : theme.colors.secondary._600};
  background-color: ${({ theme, _situacao_ }) =>
    _situacao_ === "A"
      ? theme.colors.success._100
      : _situacao_ === "C"
      ? theme.colors.error._100
      : _situacao_ === "S"
      ? theme.colors.gray._100
      : theme.colors.secondary._100};
  border-radius: 6px;
`;
const ActionButton = styled("span")`
  padding: 0.5rem 0.75rem;
  display: inline-block;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray._300};
  background-color: ${({ theme }) => theme.colors.gray._100};
  border-radius: 6px;
  cursor: pointer;
`;
const MenuItem = styled(MMenuItem)`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
`;

const ListaParceirosPage = ({
  loading,
  filters,
  setFilters,
  filtered,
  page,
  total,
  handlePaginationClick,
  handleClick,
}) => {
  return (
    <CommonPage selected={"parceiro"}>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader>
              <Title as={"h2"}>Parceiros</Title>
              <Grid display={"flex"} alignItems={"center"} gap={"0.5rem"}>
                <Grid item>
                  <TextField
                    label={null}
                    size={"small"}
                    select
                    margin={"none"}
                    value={filters.situacao}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        situacao: e.target.value,
                        dirty: true,
                      })
                    }
                  >
                    <MenuItem value={"X"}>Todas as situações</MenuItem>
                    <MenuItem value={"A"}>{SITUACAO.A}</MenuItem>
                    <MenuItem value={"P"}>{SITUACAO.P}</MenuItem>
                    <MenuItem value={"S"}>{SITUACAO.S}</MenuItem>
                    <MenuItem value={"C"}>{SITUACAO.C}</MenuItem>
                  </TextField>
                </Grid>
                <Grid item>
                  <TextField
                    label={null}
                    size={"small"}
                    placeholder={"Pesquisar"}
                    margin={"none"}
                    value={filters.pesquisar}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        pesquisar: e.target.value,
                        dirty: true,
                      })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardHeader>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <SizedTableCell width={"150px"}>CNPJ</SizedTableCell>
                    <TableCell>Nome Fantasia</TableCell>
                    <SizedTableCell width={"130px"}>
                      Data de Cadastro
                    </SizedTableCell>
                    <SizedTableCell width={"140px"}>
                      Última Atualização
                    </SizedTableCell>
                    <SizedTableCell width={"240px"}>Situação</SizedTableCell>
                    <SizedTableCell width={"100px"}>Detalhes</SizedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!loading && (
                    <BodyTableRow index={0}>
                      <td>
                        <HorizontalLoader color={"brand"} size={40} />
                      </td>
                    </BodyTableRow>
                  )}
                  {!loading &&
                    filtered.map((parceiro, index) => {
                      return (
                        <BodyTableRow key={parceiro.id} index={index}>
                          <SizedTableCell width={"150px"}>
                            {formatters.strings.cnpj(parceiro.cnpj)}
                          </SizedTableCell>
                          <TableCell>{parceiro.nomeFantasia}</TableCell>
                          <SizedTableCell width={"130px"}>
                            {formatters.dates.simple(
                              new Date(parceiro.dataHoraCriacao),
                              "dd/MM/yyyy"
                            )}
                          </SizedTableCell>
                          <SizedTableCell width={"140px"}>
                            {parceiro.situacao === "P" &&
                            !!parceiro.fluxoTrabalho.dataHoraUltimaMovimentacao
                              ? formatters.dates.simple(
                                  new Date(
                                    parceiro.fluxoTrabalho.dataHoraUltimaMovimentacao
                                  ),
                                  "dd/MM/yyyy"
                                )
                              : parceiro.situacao === "C" &&
                                !!parceiro.dataHoraCancelamento
                              ? formatters.dates.simple(
                                  new Date(parceiro.dataHoraCancelamento),
                                  "dd/MM/yyyy"
                                )
                              : parceiro.situacao === "A" &&
                                !!parceiro.dataHoraFiliacao
                              ? formatters.dates.simple(
                                  new Date(parceiro.dataHoraFiliacao),
                                  "dd/MM/yyyy"
                                )
                              : "-"}
                          </SizedTableCell>
                          <SizedTableCell width={"240px"}>
                            <SituacaoParceiroContainer
                              _situacao_={parceiro.situacao}
                            >
                              {SITUACAO[parceiro.situacao]}
                            </SituacaoParceiroContainer>
                          </SizedTableCell>
                          <SizedTableCell width={"100px"}>
                            <ActionButton onClick={() => handleClick(parceiro)}>
                              Acessar
                            </ActionButton>
                          </SizedTableCell>
                        </BodyTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              loading={loading}
              page={page}
              rowsPerPage={10}
              handlePaginationClick={handlePaginationClick}
              total={total.length}
            />
          </Card>
        </Grid>
      </Grid>
    </CommonPage>
  );
};

export default ListaParceirosPage;
