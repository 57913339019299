import { Grid } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  MenuItem,
  Title,
} from "../../../../../components";
import { ControlledTextField } from "../../../../../components/inputs";
import { estados } from "../../../../../constants/generic.constants";
import Stepper from "../../../preenchimento/Stepper";

const DadosEnderecoImovelInterForm = ({
  fluxo,
  formProps,
  handleSubmit,
  submitting,
  events,
  makeGoTo,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={makeGoTo(0)}
          text={"Preencha as informações do imóvel"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={0} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item width={1}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Localização do Imóvel</Title>
                  </Grid>
                  <Grid item xs={9}>
                    <ControlledTextField
                      label="Município"
                      name={"endereco.municipio"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ControlledTextField
                      label="UF"
                      name={"endereco.uf"}
                      select
                      formProps={formProps}
                    >
                      {estados.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </ControlledTextField>
                  </Grid>
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosEnderecoImovelInterForm;
