import { Grid, Skeleton, styled } from "@mui/material";
import { AttachMoney, House, Savings } from "@mui/icons-material";
import React from "react";
import BaseButton from "../../components/buttons/BaseButton";

const icones = {
  H: AttachMoney,
  I: House,
  C: Savings,
};

const SkeletonInstrucao = styled(Skeleton)`
  font-size: ${({ theme }) => theme.fonts.size.large};
`;
const Instrucao = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.large};
  color: ${({ theme }) => theme.colors.gray._500};
`;
const SkeletonProduto = styled(Skeleton)`
  border-radius: 1rem;
  margin: 0 0.5rem 2rem;
  width: 157px;
  height: 202px;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    width: 258px;
    height: 205px;
    margin: 0 1.5rem 2rem;
  }`}
`;
const Produto = styled("div")`
  padding: 1rem 2rem;
  background-color: ${({ theme }) => theme.colors.fixed.white};
  display: flex;
  border-radius: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme, _selected_ }) =>
    _selected_ ? theme.colors.secondary._400 : theme.colors.gray._200};
  font-weight: ${({ _selected_ }) => (_selected_ ? 700 : 400)};
  cursor: pointer;
  margin: 0.5rem;
  flex: 1;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, _selected_ }) =>
    _selected_ ? theme.colors.secondary._400 : theme.colors.gray._50};
  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary._600};
    color: ${({ theme }) => theme.colors.secondary._600};
    font-weight: 700;
  }

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    padding: 2rem 3rem;
    margin: 0 1.5rem;
  }`}
`;
const NomeProduto = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.default};
  text-transform: uppercase;
  max-width: 160px;
  text-align: center;
  ${({ theme }) => `${theme.breakpoints.up("md")} {
    width: 160px;
  }`}
`;
const StyledIcon = styled("span")`
  font-size: 4rem;
  margin-bottom: 1rem;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    font-size: 5rem;
  }`}
`;
const Grupo = styled("div")`
  padding: 2rem 3rem;
  background-color: ${({ theme }) => theme.colors.fixed.white};
  display: flex;
  text-align: center;
  border-radius: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme, _selected_ }) =>
    _selected_ ? theme.colors.secondary._400 : theme.colors.gray._200};
  font-weight: ${({ _selected_ }) => (_selected_ ? 700 : 400)};
  cursor: pointer;
  margin: 0 1.5rem;
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.default};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, _selected_ }) =>
    _selected_ ? theme.colors.secondary._400 : theme.colors.gray._50};

  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary._600};
    color: ${({ theme }) => theme.colors.secondary._600};
    font-weight: 700;
  }
`;

const SelecionarProdutoSection = ({
  produtos,
  loading,
  handleNext,
  selectedProduct,
  handleClickProduto,
  selectedGroup,
  setSelectedGroup,
}) => {
  return (
    <>
      {!!loading && (
        <Grid
          container
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          rowSpacing={4}
        >
          <SkeletonInstrucao variant={"text"} width={300} />
          <Grid item display={"flex"} flexDirection={"row"}>
            <SkeletonProduto variant={"rectangular"} />
            <SkeletonProduto variant={"rectangular"} />
          </Grid>
        </Grid>
      )}
      {!loading && (
        <Grid
          container
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={4}
        >
          <Instrucao>Selecione o produto desejado:</Instrucao>
          <Grid item display={"flex"} flexDirection={"row"} flexWrap={"wrap"} rowGap={2}>
            {produtos.map((p) => (
              <Produto
                key={p.id}
                _selected_={selectedProduct?.id === p.id}
                onClick={() => handleClickProduto(p)}
              >
                <StyledIcon as={icones[p.tipo]} />
                <NomeProduto>{p.nome}</NomeProduto>
              </Produto>
            ))}
          </Grid>
          {!!selectedProduct && selectedProduct.regras.length > 1 && (
            <Grid item display={"flex"} flexDirection={"row"}>
              {selectedProduct.regras.map((g) => (
                <Grupo
                  key={g.id}
                  _selected_={selectedGroup?.id === g.id}
                  onClick={() => setSelectedGroup(g)}
                >
                  {g.tipoPessoa === "J" ? "PJ" : "PF"}
                </Grupo>
              ))}
            </Grid>
          )}
          <Grid item width={"100%"}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <BaseButton
                  disabled={!selectedGroup}
                  onClick={() => handleNext(selectedGroup)}
                  fullWidth
                >
                  PRÓXIMA ETAPA
                </BaseButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SelecionarProdutoSection;
