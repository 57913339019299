import React from "react";
import DadosBasicosInterFormConnected from "./DadosBasicosInterForm.connected";
import HubInterConnected from "./HubInter.connected";
import DadosProfissionaisInterFormConnected from "./DadosProfissionaisInterForm.connected";
import DadosEstadoCivilInterFormConnected from "./DadosEstadoCivilInterForm.connected";
import DadosEnderecoInterFormConnected from "./DadosEnderecoInterForm.connected";
import DadosEnderecoImovelInterFormConnected from "./imovel/DadosEnderecoImovelInterForm.connected";

const FluxoAquisicaoInter = (props) => {
  return (
    <>
      {props.step === 0 && <HubInterConnected {...props} />}
      {props.step === 1 && <DadosBasicosInterFormConnected {...props} />}
      {props.step === 2 && <DadosEnderecoInterFormConnected {...props} />}
      {props.step === 3 && <DadosProfissionaisInterFormConnected {...props} />}
      {props.step === 4 && <DadosEstadoCivilInterFormConnected {...props} />}
      {props.step === 100 && (
        <DadosEnderecoImovelInterFormConnected {...props} />
      )}
    </>
  );
};

export default FluxoAquisicaoInter;
