import React from "react";
import CommonPage from "../CommonPage";
import { Button, Skeleton, Text, Title, GoBack } from "../../components";
import {
  BodyTableRow,
  SizedTableCell as MSizedTableCell,
  Table as MTable,
} from "../../components/tables";
import {
  Grid,
  styled,
  TableBody,
  TableContainer as MTableContainer,
  TableHead,
} from "@mui/material";
import formatters from "../../utils/formatters";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import InformarMotivoAcaoDialogConnected from "../workflow/InformarMotivoAcaoDialog.connected";
import { transientOptions } from "../../styles/theme";

const SkeletonCard = styled(Skeleton)`
  width: 100%;
  height: 239px;
  border-radius: 1rem;
`;

const Table = styled(MTable)`
  margin: 0;
`;

const SizedTableCell = styled(MSizedTableCell)`
  margin: 0;
`;

const TableContainer = styled(MTableContainer)`
  background-color: white;
  padding: 20px;
  width: auto;
  border-radius: 10px;
`;

const DisclaimerContainer = styled("div", transientOptions)`
  border-radius: ${({ theme }) => theme.borderRadiuses.disclaimer};
  border: 1px dashed
    ${({ theme, _color_ = "secondary" }) => theme.colors[_color_]._700};
  background-color: ${({ theme, _color_ = "primary" }) =>
    theme.colors[_color_]._100};
  color: ${({ theme, _color_ = "secondary" }) => theme.colors[_color_]._600};
  padding: 1rem 1.5rem;
  margin: 0.75rem 0 0;
`;
const Disclaimer = styled("p", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 500;
  color: inherit;
  margin: 0;
`;
const AprovacaoMenorPage = ({
  data,
  loading,
  acting,
  handleAceitarCondicoes,
  handleAnaliseManual,
  handleBack,
  dadosMotivo,
  handleCloseMotivoAcao,
}) => {
  return (
    <CommonPage selected={"clientes"}>
      {loading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
        </Grid>
      )}
      {!loading && (
        <>
          <Grid container spacing={3}>
            <Grid item container justifyContent={"center"} spacing={3}>
              <Grid
                item
                container
                spacing={3}
                direction={"column"}
                maxWidth={704}
              >
                <Grid item container>
                  <GoBack
                    handleClick={handleBack}
                    text={
                      "Crédito solicitado analisado automaticamente, porém com um valor menor do que o solicitado"
                    }
                  />
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} display={"flex"} flexDirection={"column"}>
                    <Text>
                      Confira agora as condições atuais e as novas condições
                      propostas pela instituição:
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <BodyTableRow>
                            <SizedTableCell align="center"></SizedTableCell>
                            <SizedTableCell>Valores solicitados</SizedTableCell>
                            <SizedTableCell>Valores propostos</SizedTableCell>
                          </BodyTableRow>
                        </TableHead>
                        <TableBody>
                          <BodyTableRow>
                            <SizedTableCell style={{ minWidth: 140 }}>
                              Valor Total do Financiamento
                            </SizedTableCell>
                            <SizedTableCell
                              style={{ minWidth: 140, fontWeight: 500 }}
                            >
                              R${" "}
                              {formatters.numbers.currency(
                                data.detalhes.valorFinanciamento
                              )}
                            </SizedTableCell>
                            <SizedTableCell
                              style={{
                                minWidth: 140,
                                fontWeight: 500,
                                alignItems: "center",
                              }}
                            >
                              R${" "}
                              {formatters.numbers.currency(
                                data.aprovacaoMenor.valorFinanciamento
                              )}
                              {data.detalhes.valorFinanciamento >
                                data.aprovacaoMenor.valorFinanciamento && (
                                <ArrowDownwardIcon
                                  color={"primary"}
                                  fontSize="6px"
                                  sx={{ marginLeft: 0.7 }}
                                />
                              )}
                            </SizedTableCell>
                          </BodyTableRow>
                          <BodyTableRow>
                            <SizedTableCell style={{ minWidth: 140 }}>
                              Valor Líquido do Financiamento
                            </SizedTableCell>
                            <SizedTableCell
                              style={{ minWidth: 140, fontWeight: 500 }}
                            >
                              R${" "}
                              {formatters.numbers.currency(
                                data.detalhes.valorBase
                              )}
                            </SizedTableCell>
                            <SizedTableCell
                              style={{
                                minWidth: 140,
                                fontWeight: 500,
                                alignItems: "center",
                              }}
                            >
                              R${" "}
                              {formatters.numbers.currency(
                                data.aprovacaoMenor.valorBase
                              )}
                              {data.detalhes.valorBase >
                                data.aprovacaoMenor.valorBase && (
                                <ArrowDownwardIcon
                                  color={"primary"}
                                  fontSize="6px"
                                  sx={{ marginLeft: 0.7 }}
                                />
                              )}
                            </SizedTableCell>
                          </BodyTableRow>
                          <BodyTableRow>
                            <SizedTableCell style={{ minWidth: 140 }}>
                              1° Parcela
                            </SizedTableCell>
                            <SizedTableCell
                              style={{ minWidth: 140, fontWeight: 500 }}
                            >
                              R${" "}
                              {formatters.numbers.currency(
                                data.detalhes.valorPrimeiraParcela
                              )}
                            </SizedTableCell>
                            <SizedTableCell
                              style={{
                                minWidth: 140,
                                fontWeight: 500,
                                alignItems: "center",
                              }}
                            >
                              R${" "}
                              {formatters.numbers.currency(
                                data.aprovacaoMenor.valorPrimeiraParcela
                              )}
                              {data.detalhes.valorPrimeiraParcela >
                                data.aprovacaoMenor.valorPrimeiraParcela && (
                                <ArrowDownwardIcon
                                  color={"primary"}
                                  fontSize="6px"
                                  sx={{ marginLeft: 0.7 }}
                                />
                              )}
                            </SizedTableCell>
                          </BodyTableRow>
                          <BodyTableRow>
                            <SizedTableCell style={{ minWidth: 140 }}>
                              Prazo da Operação
                            </SizedTableCell>
                            <SizedTableCell
                              style={{ minWidth: 140, fontWeight: 500 }}
                            >
                              {data.detalhes.prazoDesejado} meses
                            </SizedTableCell>
                            <SizedTableCell
                              style={{
                                minWidth: 140,
                                fontWeight: 500,
                                alignItems: "center",
                              }}
                            >
                              {data.aprovacaoMenor.prazoDesejado} meses
                            </SizedTableCell>
                          </BodyTableRow>
                          <BodyTableRow>
                            <SizedTableCell style={{ minWidth: 140 }}>
                              Taxa de Juros
                            </SizedTableCell>
                            <SizedTableCell
                              style={{ minWidth: 140, fontWeight: 500 }}
                            >
                              {formatters.numbers.nFixed(
                                data.detalhes.taxaSolicitada,
                                2
                              )}
                              % a.a.
                            </SizedTableCell>
                            <SizedTableCell
                              style={{
                                minWidth: 140,
                                fontWeight: 500,
                                alignItems: "center",
                              }}
                            >
                              {formatters.numbers.currency(
                                data.aprovacaoMenor.taxaSolicitada
                              )}
                              % a.a.
                            </SizedTableCell>
                          </BodyTableRow>
                        </TableBody>
                      </Table>
                      {data.aprovacaoMenor.condicionado && (
                        <Grid
                          container
                          display={"flex"}
                          justifyContent={"center"}
                          marginTop={1}
                        >
                          <Grid item xs={12} justifyContent={"center"}>
                            <DisclaimerContainer sx={{ textAlign: "center" }}>
                              <Disclaimer>
                                Os valores informados acima ainda estão sujeitos
                                à comprovação de renda.
                              </Disclaimer>
                            </DisclaimerContainer>
                          </Grid>
                        </Grid>
                      )}
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12}>
                    <Text sx={{ textAlign: "center" }}>
                      Neste caso, você tem duas opções:
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Title
                      as={"p"}
                      _color_={"gray"}
                      sx={{ textAlign: "center" }}
                    >
                      1- Aceitar os novos valores propostos pela instituição
                    </Title>
                  </Grid>
                  <Grid item xs={12} mb={1}>
                    <Grid container justifyContent={"center"}>
                      <Grid item>
                        <Button
                          onClick={handleAceitarCondicoes}
                          loading={acting}
                          sx={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                        >
                          Aceitar condições
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Title
                      as={"p"}
                      _color_={"gray"}
                      sx={{ textAlign: "center" }}
                    >
                      2- Enviar os documentos de renda para solicitar a
                      aprovação do valor original do financiamento. <br />
                    </Title>
                  </Grid>
                  <Grid item xs={12} mb={1}>
                    <Grid container justifyContent={"center"}>
                      <Grid item>
                        <Button
                          _color_="gray"
                          variant="outlined"
                          onClick={handleAnaliseManual}
                          loading={acting}
                          sx={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                        >
                          Enviar para análise manual
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <InformarMotivoAcaoDialogConnected
        dados={dadosMotivo}
        handleClose={handleCloseMotivoAcao}
      />
    </CommonPage>
  );
};

export default AprovacaoMenorPage;
