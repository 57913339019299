import basicFlow from "./asyncHandler";
import { put, select } from "redux-saga/effects";
import { actions } from "../actions/clientes.actions";
import { authenticatedRequest, destinies } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../actions/rotas.actions";
import { getRouteArguments } from "../selectors/routes.selectors";
import { getUsuarioLogado } from "../selectors/usuario.selectors";

const carregarResumo = basicFlow({
  actionGenerator: actions.carregarResumo,
  transform: (values) => {
    return {
      ...values,
      pesquisa: btoa(values.pesquisa ?? ""),
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: "/resumo",
      isResourceService: true,
      destiny: destinies.CLIENTE,
      queryParams: values,
      method: "get",
    });
  },
});

const listarClientes = basicFlow({
  actionGenerator: actions.listarClientes,
  transform: (values) => {
    return {
      ...values,
      pesquisa: btoa(values.pesquisa ?? ""),
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: "/clientes",
      isResourceService: true,
      destiny: destinies.CLIENTE,
      queryParams: values,
      method: "get",
    });
  },
});

const obterCliente = basicFlow({
  actionGenerator: actions.obterCliente,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/cliente/${id}`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
});

const obterInstituicoesFinanceiras = basicFlow({
  actionGenerator: actions.obterInstituicoesFinanceiras,
  api: () => {
    return authenticatedRequest({
      url: "/dominios/instituicoes-financeiras",
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
});

const obterParceiros = basicFlow({
  actionGenerator: actions.obterParceiros,
  api: () => {
    return authenticatedRequest({
      url: "/dominios/parceiros",
      destiny: destinies.PARCEIRO,
      method: "get",
    });
  },
});

const obterProdutos = basicFlow({
  actionGenerator: actions.obterProdutos,
  api: () => {
    return authenticatedRequest({
      url: "/dominios/produtos",
      destiny: destinies.WORKFLOW,
      method: "get",
    });
  },
});

function* listaClientesRouteWatcher() {
  yield routeWatcher([routes.CLIENTES], function* () {
    const usuario = yield select(getUsuarioLogado);

    if (!!usuario) {
      yield put(actions.obterParceiros.request());
      yield put(actions.obterProdutos.request());
      yield put(actions.obterInstituicoesFinanceiras.request());
    }

    yield put(actions.carregarResumo.request({}));
    yield put(actions.listarClientes.request({ pagina: 0, etapa: "total" }));
  });
}
function* detalhesClienteRouteWatcher() {
  yield routeWatcher([routes.DETALHES_CLIENTE], function* () {
    const payload = yield select(getRouteArguments);
    yield put(actions.obterCliente.request(payload));
  });
}

export const sagas = [
  listarClientes.watcher(),
  carregarResumo.watcher(),
  obterCliente.watcher(),
  obterParceiros.watcher(),
  obterInstituicoesFinanceiras.watcher(),
  obterProdutos.watcher(),
  listaClientesRouteWatcher(),
  detalhesClienteRouteWatcher(),
];
