import { createSelector } from "reselect";

export const getParceiro = (state) => state.parceiro;
export const isCriandoParceiro = createSelector(
  getParceiro,
  (state) => state.criacao.submitting
);
export const isValidandoParceiro = createSelector(
  getParceiro,
  (state) => state.criacao.loading
);

export const isCarregandoParceiros = createSelector(
  getParceiro,
  (state) => state.lista.loading
);
export const getParceiros = createSelector(
  getParceiro,
  (state) => state.lista.data
);
export const isCarregandoDetalhesParceiro = createSelector(
  getParceiro,
  (state) => state.detalhes.loading
);
export const getDetalhesParceiro = createSelector(
  getParceiro,
  (state) => state.detalhes.data
);
export const getDialogFilial = createSelector(
  getParceiro,
  (state) => state.dialogFilial
);
export const getDocumentos = createSelector(
  getDetalhesParceiro,
  (state) => state?.checklists
);
export const getQuantidadeDocumentosPendentesTotal = createSelector(
  getDocumentos,
  (state) => getQuantidadeDocumentosPendentes(state ?? [])
);
export const getQuantidadeDocumentosPendentes = (checklists) => {
  if (!checklists) return 0;
  return checklists.reduce((total, checklist) => {
    return (
      total +
      checklist.grupos.reduce((t1, g) => {
        return (
          t1 +
          g.itens.reduce((t2, i) => {
            return t2 + i.documentos.filter((d) => d.situacao === "PE").length;
          }, 0)
        );
      }, 0)
    );
  }, 0);
};
export const isSubindoArquivos = createSelector(
  getParceiro,
  (state) => state.detalhes.uploading
);
export const isCarregandoDocumentos = createSelector(
  getParceiro,
  (state) => state.detalhes.loadingDocumentos
);
export const isBaixandoArquivos = createSelector(
  getParceiro,
  (state) => state.detalhes.downloading
);
export const isBaixandoTemplates = createSelector(
  getParceiro,
  (state) => state.detalhes.templating
);
export const isRemovendoArquivos = createSelector(
  getParceiro,
  (state) => state.detalhes.deleting
);
export const isAtualizandoDetalhesParceiro = createSelector(
  getParceiro,
  (state) => state.detalhes.submitting
);
export const isValidandoIdentificador = createSelector(
  getParceiro,
  (state) => state.detalhes.validating
);
export const getEtapasAbertas = createSelector(getDetalhesParceiro, (state) => {
  if (!state?.fluxoTrabalho) {
    return [];
  }
  if (state.fluxoTrabalho.situacao !== "A") {
    return [];
  }
  const etapas = state.fluxoTrabalho.etapas || [];

  return etapas.filter((e) => e.situacao === "E");
});

export const getDialogosWorkflow = createSelector(
  getDetalhesParceiro,
  (state) => {
    if (!state?.fluxoTrabalho || !state?.fluxoTrabalho?.dialogos) {
      return [];
    }
    if (state.fluxoTrabalho.situacao === "A") {
      return [];
    }
    return state.fluxoTrabalho.dialogos;
  }
);

export const getDialogosDisponiveis = (state) => {
  const resumo = {};

  const dialogosWorkflow = getDialogosWorkflow(state);
  const etapasAbertas = getEtapasAbertas(state);

  for (const etapa of etapasAbertas) {
    if (!!etapa.dialogosEtapa) {
      for (const dialogo of etapa.dialogosEtapa) {
        const substituir =
          !resumo[dialogo.padrao] ||
          ordemEdicao[resumo[dialogo.padrao]] < ordemEdicao[dialogo.tipoEdicao];

        if (!!substituir) {
          resumo[dialogo.padrao] = dialogo.tipoEdicao;
        }
      }
    }
  }
  for (const dialogo of dialogosWorkflow) {
    const substituir =
      !resumo[dialogo.padrao] ||
      ordemEdicao[resumo[dialogo.padrao]] < ordemEdicao[dialogo.tipoEdicao];

    if (!!substituir) {
      resumo[dialogo.padrao] = dialogo.tipoEdicao;
    }
  }

  return resumo;
};

const ordemEdicao = {
  C: 0,
  E: 1,
  O: 2,
};

export const isCarregandoDominioBancos = createSelector(
  getParceiro,
  (state) => state.dominios.bancos.loading
);
export const getDominioBancos = createSelector(
  getParceiro,
  (state) => state.dominios.bancos.data
);
export const getDominioParceiros = createSelector(
  getParceiro,
  (state) => state.dominios.parceiros.data
);
export const getDominioPromotores = createSelector(
  getParceiro,
  (state) => state.dominios.promotores.data
);
export const getDialogComissionamentoParceiro = createSelector(
  getParceiro,
  (state) => state.dialogComissionamentoParceiro
);