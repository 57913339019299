import React, { useCallback, useEffect } from "react";
import DadosFgtsDefaultForm from "./DadosFgtsDefaultForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import { removeNonDigitsFromString } from "../../../../../utils/basic";
import { useFieldArray } from "react-hook-form";

const DadosFgtsDefaultFormConnected = ({
  handleGoTo0,
  events,
  participante: comprador,
  setParticipante,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacao } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      handleGoTo0();
    },
    [handleGoTo0, setParticipante, comprador]
  );

  const initialValuesFgts = () => ({
    contasFgts:
      comprador.contasFgts.length > 0
        ? comprador?.contasFgts
        : [{ conta: "", empregador: "", valorSaldo: "" }],
  });

  const [formPropsFgts] = useCompleteForm({
    rules: () => ({
      contasFgts: {
        conta: validators.string({ required: true }),
        empregador: validators.string({ required: true }),
        valorSaldo: validators.number({ required: true }),
      },
    }),
    initialValues: initialValuesFgts,
  });

  const contasFgts = useFieldArray({
    control: formPropsFgts.control,
    name: "contasFgts",
  });

  const adicionarContaFgts = useCallback(
    (data) => {
      contasFgts.append(data);
    },
    [contasFgts]
  );
  const removerContaFgts = useCallback(
    (index) => {
      if (contasFgts.fields.length > 1) {
        contasFgts.remove(index);
      }
    },
    [contasFgts]
  );

  const [formProps, submitForm] = useCompleteForm({
    rules: () => ({
      pisPasep: validators.number({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        imovelLocalizadoNoMunicipioDeOcupacao: false,
        imovelLocalizadoNoMunicipioDeResidenciaMenosDeUmAno: false,
        imovelLocalizadoNoMunicipioDeResidenciaMaisDeUmAno: false,
        pisPasep: "",
        possuiMinimoContribuicaoFgts: false,
        possuiImovelNoPais: false,
        possuiFinanciamentoAtivo: null,
        possuiImovelNoMunicipioDeOcupacao: null,
        possuiImovelNoMunicipioDeResidencia: null,
        utilizaFgts: false,
        ...comprador,
      }),
      [comprador]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-fgts",
            participante: {
              id: comprador?.id,
              utilizaFgts: values.utilizaFgts,
              contasFgts: values.utilizaFgts
                ? formPropsFgts.getValues().contasFgts
                : [],
              imovelLocalizadoNoMunicipioDeOcupacao:
                values.imovelLocalizadoNoMunicipioDeOcupacao,
              imovelLocalizadoNoMunicipioDeResidenciaMenosDeUmAno:
                values.imovelLocalizadoNoMunicipioDeResidenciaMenosDeUmAno,
              imovelLocalizadoNoMunicipioDeResidenciaMaisDeUmAno:
                values.imovelLocalizadoNoMunicipioDeResidenciaMaisDeUmAno,
              possuiImovelNoPais: values.possuiImovelNoPais,
              possuiFinanciamentoAtivo: values.possuiImovelNoPais
                ? values.possuiFinanciamentoAtivo
                : null,
              possuiImovelNoMunicipioDeOcupacao: values.possuiImovelNoPais
                ? values.possuiImovelNoMunicipioDeOcupacao
                : null,
              possuiImovelNoMunicipioDeResidencia: values.possuiImovelNoPais
                ? values.possuiImovelNoMunicipioDeResidencia
                : null,
              pisPasep: removeNonDigitsFromString(values.pisPasep),
              possuiMinimoContribuicaoFgts: values.possuiMinimoContribuicaoFgts,
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, comprador, operacao, callback, formPropsFgts]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      imovelLocalizadoNoMunicipioDeOcupacao: false,
      imovelLocalizadoNoMunicipioDeResidenciaMenosDeUmAno: false,
      imovelLocalizadoNoMunicipioDeResidenciaMaisDeUmAno: false,
      possuiImovelNoPais: false,
      possuiFinanciamentoAtivo: null,
      possuiImovelNoMunicipioDeOcupacao: null,
      possuiImovelNoMunicipioDeResidencia: null,
      pisPasep: null,
      possuiMinimoContribuicaoFgts: false,
      utilizaFgts: false,
      ...comprador,
    });
  }, [comprador, reset]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isFgtsValid = await formPropsFgts.trigger();
    const isMainFormValid = await formProps.trigger();

    if (isFgtsValid && isMainFormValid) {
      submitForm();
    }
  };

  const utilizaFgts = formProps.watch("utilizaFgts");
  const possuiImovelNoPais = formProps.watch("possuiImovelNoPais");

  useEffect(() => {
    if (
      formProps.getValues().possuiFinanciamentoAtivo ||
      formProps.getValues().possuiImovelNoMunicipioDeOcupacao ||
      formProps.getValues().possuiImovelNoMunicipioDeResidencia
    ) {
      return;
    }
    if (possuiImovelNoPais) {
      formProps.setValue("possuiFinanciamentoAtivo", false);
      formProps.setValue("possuiImovelNoMunicipioDeOcupacao", false);
      formProps.setValue("possuiImovelNoMunicipioDeResidencia", false);
    } else {
      formProps.setValue("possuiFinanciamentoAtivo", null);
      formProps.setValue("possuiImovelNoMunicipioDeOcupacao", null);
      formProps.setValue("possuiImovelNoMunicipioDeResidencia", null);
    }
  }, [possuiImovelNoPais, formProps]);

  return (
    <DadosFgtsDefaultForm
      {...otherProps}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      principal={comprador.principal}
      fluxo={comprador.fluxo}
      events={events}
      contasFgts={contasFgts}
      formPropsFgts={formPropsFgts}
      adicionarContaFgts={adicionarContaFgts}
      removerContaFgts={removerContaFgts}
      utilizaFgts={utilizaFgts}
      possuiImovelNoPais={possuiImovelNoPais}
    />
  );
};

export default DadosFgtsDefaultFormConnected;
