import { Grid, styled } from "@mui/material";
import logo from "../../assets/logo.png";
import { Title } from "../../components";

const Container = styled("div")`
  width: calc(100% + 3rem);
  background-color: ${({ theme }) => theme.colors.fixed.white};
  margin-left: -1.5rem;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.secondary._50};
`;
const SmallLogo = styled("img")`
  height: 40px;
  width: auto;
`;
const Disclaimer = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray._700};
`;
const Link = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 400;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray._700};

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = ({ handleTermos, handlePoliticaClick }) => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid
          item
          container
          direction={"column"}
          xs={12}
          md={6}
          lg={4}
          spacing={2}
        >
          <Grid item>
            <SmallLogo src={logo} />
          </Grid>
          <Grid item>
            <Disclaimer>
              O feliz encontro da simplicidade com a alta tecnologia.
            </Disclaimer>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction={"column"}
          xs={12}
          md={6}
          lg={8}
          spacing={1}
        >
          <Grid item>
            <Title as={"h4"} _margin_={"0 0 0.5rem 0"}>
              Institucional
            </Title>
          </Grid>
          <Grid item>
            <Link onClick={handleTermos}>Termos de Uso</Link>
          </Grid>
          <Grid item>
            <Link onClick={handlePoliticaClick}>Política de Privacidade</Link>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
