import React, { useCallback, useEffect } from "react";
import ValidadeCreditoDialog from "./ValidadeCreditoDialog";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../actions/documentos.actions";
import useCompleteForm from "../../hooks/useCompleteForm";
import validators from "../../utils/validators";
import {
  getDominiosOperacao,
  getValidadeCredito,
} from "../../selectors/documentos.selectors";
import { transformFromDate } from "../../utils/basic";

const ValidadeCreditoDialogConnected = () => {
  const dispatch = useDispatch();
  const { show, submitting, data } = useSelector(getValidadeCredito);
  const { loading, data: dominios } = useSelector(getDominiosOperacao);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirValidadeCredito(false, null));
  }, [dispatch]);

  const initialValues = useCallback(
    () => ({
      valorImovel: "",
      valorFinanciamento: "",
      valorCustas: "",
      valorIof: "",
      valorPrimeiraParcela: "",
      valorTarifaEmissao: "",
      valorTarifaRegistro: "",
      valorBase: "",
      valorEntrada: "",
      taxaJuros: "",
      prazoDesejado: "",
      tipoRegra: "",
      tipoAmortizacao: "",
      financiaCustas: false,
      financiaIof: false,
      financiaTarifaEmissao: false,
      financiaTarifaRegistro: false,
      ...data?.operacao?.detalhe,
      validadeCredito: transformFromDate(
        data?.operacao?.detalhe?.validadeCredito
      ),
    }),
    [data]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: useCallback(
      () => ({
        validadeCredito: validators.date({ required: true }),
        taxaJuros: validators.number({ required: true }),
        prazoDesejado: validators.number({ required: true }),
        valorImovel: validators.number({ required: true }),
        valorPrimeiraParcela: validators.number({ required: true }),
        valorFinanciamento: validators.number({ required: true }),
        valorTarifaEmissao: validators.number({ required: true }),
        valorTarifaRegistro: validators.number({ required: true }),
        tipoRegra: validators.string({ required: true }),
        tipoAmortizacao: validators.string({ required: true }),
      }),
      []
    ),
    initialValues,
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarValidadeCredito.request({
            ...data,
            ...values,
            callback: handleClose,
          })
        );
      },
      [dispatch, data, handleClose]
    ),
  });

  const { watch, setValue, reset } = formProps;

  useEffect(() => {
    reset({
      ...data?.operacao?.detalhe,
      validadeCredito: transformFromDate(
        data?.operacao?.detalhe?.validadeCredito
      ),
    });
  }, [data, reset]);

  const valorImovel = watch("valorImovel");
  const valorFinanciamento = watch("valorFinanciamento");
  const valorCustas = watch("valorCustas");
  const valorIof = watch("valorIof");
  const valorTarifaEmissao = watch("valorTarifaEmissao");
  const valorTarifaRegistro = watch("valorTarifaRegistro");
  const financiaCustas = watch("financiaCustas");
  const financiaIof = watch("financiaIof");
  const financiaTarifaEmissao = watch("financiaTarifaEmissao");
  const financiaTarifaRegistro = watch("financiaTarifaRegistro");

  useEffect(() => {
    if (!financiaCustas && valorCustas > 0) {
      setValue("valorCustas", 0);
    }
    const valorBase =
      valorFinanciamento -
      (financiaCustas ? valorCustas : 0) -
      (data?.operacao?.detalhe?.calculaIof && financiaIof ? valorIof : 0) -
      (financiaTarifaEmissao ? valorTarifaEmissao : 0) -
      (financiaTarifaRegistro ? valorTarifaRegistro : 0);
    setValue("valorBase", valorBase);
    setValue("valorEntrada", valorImovel - valorBase);
  }, [
    data?.operacao?.detalhe,
    setValue,
    financiaCustas,
    financiaIof,
    financiaTarifaEmissao,
    financiaTarifaRegistro,
    valorImovel,
    valorFinanciamento,
    valorCustas,
    valorIof,
    valorTarifaEmissao,
    valorTarifaRegistro,
  ]);

  return (
    <ValidadeCreditoDialog
      open={show}
      tipoProduto={data?.operacao?.tipoProduto}
      detalhe={data?.operacao?.detalhe}
      financiaCustas={financiaCustas}
      financiaIof={financiaIof}
      financiaTarifaEmissao={financiaTarifaEmissao}
      financiaTarifaRegistro={financiaTarifaRegistro}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      handleClose={handleClose}
      loading={loading}
      dominios={dominios}
    />
  );
};

export default ValidadeCreditoDialogConnected;
